import { Input } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

export const ManualTransferForm: React.FC = () => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferForm",
	})
	return (
		<div className="w-full md:w-[500px]" data-cy="manual-transfer">
			<div className="flex flex-col gap-3">
				<Input
					data-cy="project-reference-input"
					label={t("projectReference")}
					name="projectReference"
				/>
				<Input
					data-cy="company-name-input"
					label={t("companyName")}
					name="companyName"
				/>
				<Input
					data-cy="street-input"
					label={t("street")}
					name="street"
				/>
				<Input data-cy="zip-input" label={t("zip")} name="zipCode" />
				<Input data-cy="city-input" label={t("city")} name="city" />
				<Input
					data-cy="country-input"
					label={t("country")}
					name="country"
				/>
			</div>
		</div>
	)
}
