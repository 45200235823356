import { Comment } from "../comments/types"
import { Measurement, ProofView } from "../types"

export enum TransactionType {
	Deposit = "Deposit",
	Withdrawal = "Withdrawal",
	BufferRelease = "BufferRelease",
	BufferAdjustment = "BufferAdjustment",
	BalanceAdjustment = "BalanceAdjustment",
	BufferDeposit = "BufferDeposit",
}

export interface BufferAdjustmentFuelData {
	fossil: number
	biogenic: number
	total: number
}

export interface BufferAdjustmentData {
	labReportUrl: string
	from: BufferAdjustmentFuelData
	to: BufferAdjustmentFuelData
}

export interface YearlyTransactionItem {
	timestamp: string
	transactionType: TransactionType
	proof?: ProofView
	carbonSavings: number
	balanceAdjustedValue?: number
	commentDetails?: Comment
	bufferAdjustmentData?: BufferAdjustmentData
}

export interface YearlySavingsChartData {
	x: string
	y: number
}

export interface TransactionOverviewData {
	bankBalance: number
	totalDeposits: Measurement
	totalWithdraws: Measurement
	currentBuffer: number
	isAssured: boolean
	transactions: YearlyTransactionItem[]
	transactionOverviewChartData: YearlySavingsChartData[]
}

export enum CarbonSourceType {
	All,
	Biogenic,
	Fossil,
}

export interface BalanceAdjustmentData {
	carbonSavings: Measurement
	action: string
	details: string
}

export interface YearlySavingsReport {
	totalDepositsFromEtsReport: Measurement
	totalDeposits: Measurement
	productionEtsReportUrl: string
	storageEtsReportUrl: string
	adjustmentRows: BalanceAdjustmentData[]
	bufferDepositsRow: BalanceAdjustmentData
}

export interface AdjustmentComment {
	avatarUrl?: string
	name?: string
	date: string
	content: string
}

export interface UpdateBalanceInput {
	timestamp: Date
	transactionType: TransactionType
	carbonSavings: number
	commentDetails: AdjustmentComment
}

export interface AssuredYearlyBalanceResponse {
	transactionId: string
	releasedBuffer: number
}

export interface TransactionOverviewSearchParams {
	year: number
	plantName: string
	carbonSourceType: CarbonSourceType
}

export interface ReportDataSearchParams {
	year: number
	plantName?: string
}

export interface AssureYearlyBalancePayload {
	year: number
	plantName?: string
	serialNumber: string
}
