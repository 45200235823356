import { transferApi, TransferCertificate } from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	certificate?: TransferCertificate
	isLoading: boolean
	error: string | null
}
type Actions = {
	fetchMicrositeData: (id: string) => Promise<void>
}
export const useMicrositeStore = create<State & Actions>()(
	immer(set => ({
		tranaction: undefined,
		isLoading: true,
		error: null,
		fetchMicrositeData: async (id: string) => {
			try {
				set(state => {
					state.isLoading = true
				})
				const response = await transferApi.getTransferCertificate(id)
				set(state => {
					state.certificate = response
					state.isLoading = false
				})
			} catch (error) {
				set(state => {
					state.error = "Failed to fetch transaction details"
					state.isLoading = false
				})
				spawnErrorToast("Failed to fetch transactions details")
			}
		},
	})),
)
