import { ContractShortView } from "@carbonbank/api"
import { useContractsStore } from "@carbonbank/store"
import { formatNumberWithUnit, UnitType } from "@carbonbank/utils"
import { nameof } from "@carbonbank/utils/nameof"
import {
	Button,
	ProgressBar,
	icon,
	Pill,
	useSortableTable,
	Table,
	useMediaQuery,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getContractDetailsUrl } from "../../contractsRoutes"

interface ContractListProps {
	displayedContracts?: ContractShortView[]
}

export const ContractTable: React.FC<ContractListProps> = ({
	displayedContracts,
}) => {
	const isMobile = useMediaQuery({ breakpoint: "md", upTo: true })
	const { selectContractHandler } = useContractsStore(state => ({
		selectContractHandler: state.selectContractHandler,
		selectedContractNumber: state.selectedContractNumber,
	}))
	const { t } = useTranslation("contracts", {
		keyPrefix: "contracts.components.listContractsTable",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common",
	})
	const navigate = useNavigate()

	const handleRowClick = (id: string) => {
		const url = getContractDetailsUrl(id)

		selectContractHandler(id)
		navigate(url)
	}

	const tableConfig = useSortableTable<ContractShortView>(
		[
			{
				id: "contractNumber",
				label: t("contractNumber"),
			},
			{
				id: "product",
				label: t("product"),
			},
			{
				id: "accumulatedQuantity",
				label: t("quantity"),
				render: ({ accumulatedQuantity, targetQuantity }) => (
					<ProgressBar
						size="medium"
						currentValue={accumulatedQuantity}
						maxValue={targetQuantity}
						valueLabel={`${accumulatedQuantity}/${targetQuantity} t`}
					/>
				),
				valueClassName: "text-left",
				headerClassName: "text-left",
			},
			{
				id: "allocatedCarbonSavingsKgCO2",
				label: t("allocatedCarbonSavings"),
				render: ({ allocatedCarbonSavingsKgCO2 }) =>
					formatNumberWithUnit(
						allocatedCarbonSavingsKgCO2,
						UnitType.KgCO2,
					),
				valueClassName: "md:hidden lg:block",
				headerClassName: "md:hidden lg:block",
			},
			{
				id: "allSavingsTransferred",
				label: t("allSavingsTransferred"),
				render: ({ allSavingsTransferred, contractNumber }) => {
					return (
						<div className="flex justify-between">
							<div className="flex justify-between items-center max-md:mt-1 w-full">
								{allSavingsTransferred ? (
									<Pill
										variant="shade"
										type="positive"
										text={tCommon("yes")}
										shape="rounded"
										className="text-positive"
									></Pill>
								) : (
									<Pill
										variant="shade"
										type="negative"
										text={tCommon("no")}
										shape="rounded"
										className="text-negative"
									></Pill>
								)}
								{!isMobile && (
									<Button
										variant="tertiary"
										icon={icon.mdiChevronRight}
										onClick={() =>
											handleRowClick(contractNumber)
										}
										className="h-full text-foreground_faded"
									/>
								)}
							</div>
							{isMobile && (
								<Button
									variant="tertiary"
									icon={icon.mdiChevronRight}
									onClick={() =>
										handleRowClick(contractNumber)
									}
									className="h-[50px] text-foreground_faded"
								/>
							)}
						</div>
					)
				},
			},
		],
		[isMobile],
		{
			prefix: "savings-list-table-row",
			postfixFieldName: nameof<ContractShortView>("contractNumber"),
		},
		{
			enableLocalSorting: true,
		},
	)

	return (
		<Table
			data={displayedContracts}
			config={tableConfig}
			data-cy="contracts-list-table"
		/>
	)
}
