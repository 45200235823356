import { PermissionType } from "@carbonbank/api"
import { PageLayout } from "@carbonbank/components"
import { useAuthStore } from "@carbonbank/store"
import { NavigationTabsProps } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

import {
	getAssureSavingsUrl,
	getOverviewUrl,
	getProductAllocationUrl,
} from "./carbonSavingsRoutes"

export const Layout = () => {
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.productNames",
	})
	const { t } = useTranslation("cs", {
		keyPrefix: "layout",
	})

	const [userPermissions] = useAuthStore(state => [state.userPermissions])

	const getNavTabs = (): NavigationTabsProps[] => {
		const navTabs: NavigationTabsProps[] = []

		if (!userPermissions) {
			return navTabs
		}

		const addTab = (condition: boolean, label: string, url: string) => {
			if (condition) {
				navTabs.push({ label, url })
			}
		}

		addTab(
			userPermissions.some(
				permission =>
					permission.permissionType ===
					PermissionType.ViewTransactions,
			),
			t("overview"),
			getOverviewUrl(),
		)
		addTab(
			userPermissions.some(
				permission =>
					permission.permissionType === PermissionType.ViewSavings,
			),
			t("assureSavings"),
			getAssureSavingsUrl(),
		)
		addTab(
			userPermissions.some(
				permission =>
					permission.permissionType ===
					PermissionType.ViewTransactions,
			),
			t("productAllocation"),
			getProductAllocationUrl(),
		)

		return navTabs
	}

	return (
		<PageLayout productName={tCommon("mrv")} navigation={getNavTabs()}>
			<Outlet />
		</PageLayout>
	)
}
