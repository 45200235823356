export const formatDate = (dateArg: string, withTime = false) => {
	const date = new Date(dateArg)
	const day = date.getDate().toString().padStart(2, "0")
	const month = (date.getMonth() + 1).toString().padStart(2, "0")
	const year = date.getFullYear()
	const time = date.getHours().toString().padStart(2, "0")
	if (withTime) return `${day}.${month}.${year}, ${time}`
	return `${day}.${month}.${year}`
}

export const formatTimestamp = (timestampArg: string) => {
	const timestamp = new Date(timestampArg)
	const day = timestamp.getDate().toString().padStart(2, "0")
	const month = (timestamp.getMonth() + 1).toString().padStart(2, "0")
	const year = timestamp.getFullYear()
	const hour = timestamp.getHours().toString().padStart(2, "0")
	const minutes = timestamp.getMinutes().toString().padStart(2, "0")

	return `${day}.${month}.${year}, ${hour}:${minutes}`
}

export const formatTimestampAsFullDate = (timestampArg: string) => {
	const timestamp = new Date(timestampArg)
	const options = {
		year: "numeric" as const,
		month: "long" as const,
		day: "numeric" as const,
	}

	return timestamp.toLocaleDateString("en-US", options)
}

export const convertFormattedTimestampToDate = (formattedTimestamp: string) => {
	const dateParts = formattedTimestamp.split(/[\s,.:]+/)

	const convertedDate = new Date(
		parseInt(dateParts[2]),
		parseInt(dateParts[1]) - 1,
		parseInt(dateParts[0]),
		parseInt(dateParts[3]),
		parseInt(dateParts[4]),
	)

	return convertedDate
}
