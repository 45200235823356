import { Transfer, TransferSearchParams, transferApi } from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	certificates?: Transfer[]
}
type Actions = {
	fetchCertificates: (params: TransferSearchParams) => Promise<void>
}

export const useCertificateStore = create<State & Actions>()(
	immer(set => ({
		certificates: undefined,
		fetchCertificates: async (params: TransferSearchParams) => {
			try {
				set(state => {
					state.certificates = undefined
				})
				const response = await transferApi.getTransfers(params)
				return set(state => {
					state.certificates = response
				})
			} catch (error) {
				spawnErrorToast(
					i18next.t("contracts:certificates.failedToFetch"),
				)
			}
		},
	})),
)
