import { useContractsStore } from "@carbonbank/store"
import { CardHeader, LabelRegular, Toggle } from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { TransferFormInputs } from "../../types"
import { AutoTransferForm } from "./AutoTransferForm"
import { ContactSwitcher } from "./ContactSwitcher"
import { ContactsList } from "./ContactsList"
import { ManualTransferForm } from "./ManualTransferForm"

export const CarbonTransferReceiver: React.FC = () => {
	const contacts = useContractsStore(state => state.contacts)

	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferForm",
	})
	const { values, setFieldValue } = useFormikContext<TransferFormInputs>()
	const togglePreviousContact = () => {
		setFieldValue("isPreviousContact", !values.isPreviousContact)
		setFieldValue("isManualCertificate", false)
	}
	useEffect(() => {
		setFieldValue("companyName", "")
		setFieldValue("street", "")
		setFieldValue("zipCode", "")
		setFieldValue("city", "")
		setFieldValue("country", "")
		setFieldValue("email", "")
		setFieldValue("confirmEmail", "")
	}, [values.isManualCertificate, values.isPreviousContact])

	return (
		<div className="mt-12">
			<CardHeader>{t("receiver")}</CardHeader>
			<div className="flex flex-col gap-6">
				{contacts && contacts.length > 0 && (
					<>
						<ContactSwitcher
							isPreviousContact={values.isPreviousContact}
							setIsPreviousContact={togglePreviousContact}
						/>
						{values.isPreviousContact && <ContactsList />}
					</>
				)}
				{(!values.isPreviousContact || contacts?.length === 0) && (
					<>
						<Toggle
							name="isManualCertificate"
							data-cy="auto-transfer-toggle"
							label={t("certificateCreationOption")}
						/>
						<LabelRegular className="text-foreground_faded">
							{t("receiverEmailInstruction")}
						</LabelRegular>
						{values.isManualCertificate ? (
							<ManualTransferForm />
						) : (
							<AutoTransferForm />
						)}
					</>
				)}
			</div>
		</div>
	)
}
