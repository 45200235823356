﻿import { SectionItem } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { NodeProps } from "@react-pdf/renderer"
import React from "react"

export const DateOfIssue = (
	props: {
		dateOfIssue: string
	} & NodeProps,
) => {
	return <SectionItem title="Date of issue" value={props.dateOfIssue} />
}
