import axios, { AxiosRequestConfig } from "axios"

import {
	statusCheckInterceptor,
	tokenInterceptor,
	correlationIdInterceptor,
} from "../interceptors"

const carbonBankUrl = import.meta.env.VITE_SUSTAINABILITY_CARBONBANK_API

const axiosConfig: AxiosRequestConfig = {
	baseURL: carbonBankUrl,
	headers: {
		"Content-Type": "application/json",
	},
}

export const carbonBankWithAuthClient = axios.create(axiosConfig)

carbonBankWithAuthClient.interceptors.request.use(tokenInterceptor)
carbonBankWithAuthClient.interceptors.request.use(correlationIdInterceptor)

carbonBankWithAuthClient.interceptors.response.use(
	response => response,
	statusCheckInterceptor,
)
