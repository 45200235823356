﻿import {
	CertificateSectionHeader,
	SectionItem,
} from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const ProductInformationSection = (
	props: {
		declaredCementProduct: string
		originCarbonCapture: string
		bundledProduct: string
		productionOrigin: string
		deliveredCementQuantity: number
		deliveredCementQuantityUom: string
		reference: string
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content={"Product information"} />
			<View
				style={{
					...styles.flex_row,
					...styles.gap_10,
					...styles.justify_space_between,
				}}
			>
				<View style={{ ...styles.gap_2 }}>
					<Text
						style={{
							...styles.text_bold_big,
							...styles.text_color_grey,
						}}
					>
						Declared Product
					</Text>
					<SectionItem
						title="Declared cement product"
						value={props.declaredCementProduct}
					/>
					<SectionItem
						title="Origin of Carbon Captured"
						value={props.originCarbonCapture}
					/>
				</View>
				<View style={{ ...styles.gap_2 }}>
					<Text
						style={{
							...styles.text_bold_big,
							...styles.text_color_grey,
						}}
					>
						Delivered Product
					</Text>
					<SectionItem
						title="Bundled with delivered cement product"
						value={props.bundledProduct}
					/>
					<SectionItem
						title="Origin of Carbon Captured"
						value={props.originCarbonCapture}
					/>
					<SectionItem
						title="Delivered cement quantity"
						value={`${props.deliveredCementQuantity} ${props.deliveredCementQuantityUom}`}
					/>
					<SectionItem
						title="[EPD or Self Declaration]"
						value={props.reference}
					/>
				</View>
			</View>
		</View>
	)
}
