import { Transactions } from "@carbonbank/assets"
import { LoadingPage } from "@carbonbank/components"
import { useAuthStore, useTransactionStore } from "@carbonbank/store"
import { Grid } from "@sustainability/fundamental"
import React, { useEffect } from "react"

import {
	TransactionHeader,
	TransactionStats,
	ShowTransactionsCard,
	AccountLoginCard,
} from "../components"

export const TransactionsOverview: React.FC = () => {
	const [totalCarbonCaptured, fetchTotalCarbonCaptured] = useTransactionStore(
		state => [state.totalCarbonCaptured, state.fetchTotalCarbonCaptured],
	)

	const loginState = useAuthStore(state => state.loginState)

	useEffect(() => {
		fetchTotalCarbonCaptured()
	}, [fetchTotalCarbonCaptured])

	if (!totalCarbonCaptured) {
		return <LoadingPage />
	}

	return (
		<div
			className="flex flex-col gap-6 justify-center items-center mt-8"
			data-cy="transatcion-overview"
		>
			<TransactionHeader />
			<TransactionStats />
			<img
				src={Transactions}
				alt="transaction-overview"
				className="h-[195px] w-[240px]"
				data-cy="transatcion-overview-image"
			/>
			<Grid
				size={1}
				lgSize={loginState.loggedIn ? 1 : 2}
				className={
					loginState.loggedIn
						? "w-full lg:w-[340px]"
						: "w-full lg:w-[680px]"
				}
			>
				<ShowTransactionsCard />
				{!loginState.loggedIn && <AccountLoginCard />}
			</Grid>
		</div>
	)
}
