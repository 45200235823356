export enum CommentStatus {
	None = "None",
	New = "New",
	Flagged = "Flagged",
	Resolved = "Resolved",
	InValidation = "InValidation",
	Rejected = "Rejected",
	Assured = "Assured",
}

export enum CommentType {
	None = "",
	CarbonSavings = "carbon_savings",
	ProductAllocation = "product_allocation",
	YearlySavings = "yearly_savings",
}

export interface GetCommentsRequest {
	relatedEntityId: string
	type: CommentType
}

export interface Comment {
	id: number
	relatedEntityId: string
	username: string
	createdAt: string
	content: string
	status: CommentStatus
	type: CommentType
}

export interface CreateCommentRequest {
	relatedEntityId: string
	username: string
	content: string
	status: CommentStatus
	type: CommentType
}

export interface CommentData {
	id: number
	username?: string
	createdAt: string
	status: string
	type: string
	content: string
}
