import { ScenarioVariant } from "@carbonbank/store"
import { ParseKeys } from "i18next"

// eslint-disable-next-line @typescript-eslint/ban-types
type ToastMessageKeys = ParseKeys<"cs", {}, "components.assureModal">

export const scenarioToastMessageKeyMap: Record<
	ScenarioVariant,
	ToastMessageKeys
> = {
	assure: "toast.assure",
	reject: "toast.reject",
	requestInformation: "toast.requestInformation",
}
