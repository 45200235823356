import { carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	InvoiceSearchParams,
	InvoiceCertificate,
	ToggleInvoiceFlagData,
	InvoiceAllocationData,
} from "./types"

export const invoiceApi = {
	getInvoiceList: (
		params: InvoiceSearchParams,
	): Promise<InvoiceAllocationData> =>
		carbonBankWithAuthClient
			.post("/invoices/product-allocations", params)
			.then(response => response.data),
	getInvoiceCertificate: (id: string): Promise<InvoiceCertificate> =>
		carbonBankWithAuthClient
			.get(`/invoices/${id}/certificate`)
			.then(response => response.data),
	toggleInvoiceFlag: (flagData: ToggleInvoiceFlagData): Promise<void> =>
		carbonBankWithAuthClient.post(
			generateGetUrl("/invoices/toggleflagged", { ...flagData }),
		),
}
