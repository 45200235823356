import { Alert, Textarea } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const FormFieldsRejectFlow: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureModal.rejectFlow",
	})

	return (
		<>
			<Textarea
				name="comment"
				data-cy="comment"
				label={t("explanationLabel")}
			/>
			<Alert variant="error" className="mt-24px">
				{t("rejectAlertContent")}
			</Alert>
		</>
	)
}
