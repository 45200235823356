import { useCarbonTransferStore } from "@carbonbank/store"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	Alert,
	Loading,
	Label,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"

export const FormValidationModal: React.FC = () => {
	const formikContext = useFormikContext()

	const [openValidationModal, setValidationModal, isLoading] =
		useCarbonTransferStore(state => [
			state.openValidationModal,
			state.setValidationModal,
			state.isLoading,
		])
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.formValidationModal",
	})
	const { t: tSummary } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferSummary",
	})
	const { t: tTransferForm } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferForm",
	})

	return (
		<Modal
			open={openValidationModal}
			onClose={() => setValidationModal()}
			data-cy="validation-modal"
		>
			<ModalTitle>{t("title")}</ModalTitle>
			<ModalContent>
				<div className="flex flex-col gap-6">
					<TextRegular>{t("text")}</TextRegular>
					<Alert variant="warning">{t("alert")}</Alert>
					<div className="flex flex-col gap-y-3">
						<Label title={tSummary("companyName")}>
							{formikContext.getFieldProps("companyName").value}
						</Label>
						<Label title={tTransferForm("street")}>
							{formikContext.getFieldProps("street").value}
						</Label>
						<Label title={tTransferForm("zip")}>
							{formikContext.getFieldProps("zipCode").value}
						</Label>
						<Label title={tTransferForm("city")}>
							{formikContext.getFieldProps("city").value}
						</Label>
						<Label title={tTransferForm("country")}>
							{formikContext.getFieldProps("country").value}
						</Label>
					</div>
				</div>
			</ModalContent>
			<ModalFooter>
				<div className="flex gap-6">
					<Button
						type="button"
						variant="tertiary"
						onClick={() => {
							setValidationModal()
						}}
					>
						{t("back")}
					</Button>
					<Button
						type="submit"
						data-cy="everything-correct"
						className="min-w-[227px]"
					>
						{isLoading ? (
							<Loading variant="primary" size="xsmall" />
						) : (
							t("everythingCorrect")
						)}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
