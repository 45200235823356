﻿import { Font } from "@react-pdf/renderer"

import LexendRegular from "./Lexend-Regular.ttf"
import LexendSemiBold from "./Lexend-SemiBold.ttf"

export const loadLexend = () => {
	Font.register({
		family: "Lexend",
		src: LexendRegular,
		fontWeight: 400,
	})
	Font.register({
		family: "Lexend",
		src: LexendSemiBold,
		fontWeight: 700,
	})
}
