import { formatNumber } from "@carbonbank/utils"
import {
	ModalContent,
	TextRegular,
	CardSubHeader,
	Kpi,
	Input,
	Button,
	ModalFooter,
	Alert,
	icon,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface StepConfirmProps {
	bufferBalance: number
	onCancel: () => void
	onConfirm: () => void
}

export const StepConfirm: React.FC<StepConfirmProps> = ({
	bufferBalance,
	onCancel,
	onConfirm,
}: StepConfirmProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureBalanceModal.formShared",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const balanceUnit = tUnits("kgCO2")

	return (
		<>
			<ModalContent data-cy="step-confirm">
				<div className="flex flex-col gap-y-6">
					<TextRegular
						data-cy="confirm-description"
						className="text-foreground_faded"
					>
						{t("descriptionConfirm")}
					</TextRegular>
					<Alert data-cy="confirm-warning" variant="warning">
						{t("warningConfirm")}
					</Alert>
					<div>
						<CardSubHeader data-cy="buffer-title">
							{t("bufferTitle")}
						</CardSubHeader>
						<Kpi
							data-cy="buffer-balance"
							size="small"
							label={t("bufferBalanceLabel")}
							value={formatNumber(bufferBalance)}
							unit={balanceUnit}
							valueClassName={
								bufferBalance < 0
									? "text-negative"
									: "text-positive"
							}
						/>
					</div>
					<div>
						<CardSubHeader data-cy="documentation-title">
							{t("assuranceDocumentationTitle")}
						</CardSubHeader>
						<Input
							label={t("serialNumberLabel")}
							name="serialNumber"
							data-cy="serial-number"
							autoComplete="off"
							readOnly
						/>
					</div>
				</div>
			</ModalContent>
			<ModalFooter>
				<Button
					data-cy="cancel"
					type="button"
					variant="secondary"
					onClick={onCancel}
					icon={icon.mdiArrowLeft}
				>
					{tCommon("back")}
				</Button>
				<Button
					data-cy="submit"
					type="button"
					variant="primary"
					onClick={onConfirm}
				>
					{t("assureYearlyBalanceLabel")}
				</Button>
			</ModalFooter>
		</>
	)
}
