import { CycleStatus, CommentStatus } from "@carbonbank/api"
import { ModuleKeys } from "@carbonbank/pages/common"
import { ScenarioVariant } from "@carbonbank/store"
import { ButtonVariant } from "@sustainability/fundamental"
import * as Yup from "yup"

import { FormFieldsAssureFlow } from "./FormFieldsAssureFlow"
import { FormFieldsRejectFlow } from "./FormFieldsRejectFlow"
import { FormFieldsRequestInfoFlow } from "./FormFieldsRequestInfoFlow"
import {
	getAssureValidationSchema,
	getRejectValidationSchema,
	getRequestInformationValidationSchema,
} from "./validationSchema"

type ScenarioButtonKeys = ModuleKeys<"common.texts">

export const scenarioToButtonPropsMap: Record<
	ScenarioVariant,
	[ScenarioButtonKeys, ButtonVariant]
> = {
	assure: ["assure", "primary"],
	reject: ["reject", "negative"],
	requestInformation: ["requestInformation", "primary"],
}

export const formFieldsByScenario: Record<ScenarioVariant, React.FC> = {
	assure: FormFieldsAssureFlow,
	reject: FormFieldsRejectFlow,
	requestInformation: FormFieldsRequestInfoFlow,
}

export const valdiationSchemaByScenario: Record<
	ScenarioVariant,
	() => Yup.Schema
> = {
	assure: getAssureValidationSchema,
	reject: getRejectValidationSchema,
	requestInformation: getRequestInformationValidationSchema,
}

export const useValidationSchema = (scenario: ScenarioVariant) =>
	valdiationSchemaByScenario[scenario]()

export const cycleStatusByScenario: Record<ScenarioVariant, CycleStatus> = {
	assure: CycleStatus.AwaitingAssurance,
	reject: CycleStatus.Rejected,
	requestInformation: CycleStatus.RequireMoreInformation,
}

export const commentStatusByScenario: Record<ScenarioVariant, CommentStatus> = {
	assure: CommentStatus.Assured,
	reject: CommentStatus.Rejected,
	requestInformation: CommentStatus.InValidation,
}
