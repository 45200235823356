import {
	CarbonSourceType,
	PermissionType,
	TransactionType,
} from "@carbonbank/api"
import { ContainerBox, LoadingPage } from "@carbonbank/components"
import {
	useAuthStore,
	useFilterStore,
	useYearlySavingsStore,
} from "@carbonbank/store"
import { FilterForm, PageHeader, useModal } from "@sustainability/fundamental"
import { useFilter } from "@sustainability/fundamental-hooks"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { AssureBalanceDoneModal } from "../components"
import {
	ActionButtons,
	AdjustBalanceFormData,
	AdjustBalanceModal,
	BankBalanceCard,
	TransactionsCard,
} from "./components"
import { dnvYearlyAssuranceFiltersConfig } from "./dnvYearlyAssurance.config"

interface DnvYearlyAssuranceFilterProps {
	year: number
	siteName: string
	carbonSourceType: CarbonSourceType
}

export const DnvYearlyAssurance: React.FC = () => {
	const [decodedToken, userPermissions] = useAuthStore(state => [
		state.decodedToken,
		state.userPermissions,
	])

	const [siteFilterData, fetchSiteFilterData] = useFilterStore(state => [
		state.siteFilterData,
		state.fetchSiteFilterData,
	])

	const initialFilter: DnvYearlyAssuranceFilterProps = useMemo(
		() => ({
			year: new Date().getFullYear(),
			siteName: "",
			carbonSourceType: CarbonSourceType.All,
		}),
		[],
	)
	const refetchData = useCallback(async (filter?: typeof initialFilter) => {
		setIsLoading(true)

		if (!filter) {
			return
		}

		await fetchTransactionsData(
			filter.year,
			filter.siteName,
			filter.carbonSourceType,
		)

		setIsLoading(false)
	}, [])

	const [filter, setFilter] = useFilter<DnvYearlyAssuranceFilterProps>(
		initialFilter,
		refetchData,
	)

	const [
		fetchTransactionsData,
		updateBalance,
		savingsChartData,
		savingsStats,
		transactionData,
	] = useYearlySavingsStore(s => [
		s.fetchTransactionsData,
		s.updateBalance,
		s.savingsChartData,
		s.savingsStats,
		s.transactionsData,
	])

	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.page",
	})
	const [isLoading, setIsLoading] = useState(true)

	const {
		isOpen: isAdjustBalanceModalOpen,
		closeModal: closeAdjustBalanceModal,
		openModal: openAdjustBalanceModal,
	} = useModal()
	const {
		isOpen: isYearlyBalanceModalOpen,
		closeModal: closeYearlyBalanceModal,
		openModal: openYearlyBalanceModal,
	} = useModal()

	useEffect(() => {
		fetchSiteFilterData()
	}, [])

	useEffect(() => {
		const shouldSetFilter =
			filter &&
			!filter.siteName &&
			siteFilterData &&
			siteFilterData.length > 0 &&
			siteFilterData[0].siteName

		if (shouldSetFilter) {
			setFilter({
				siteName: siteFilterData[0].siteName!,
				year: filter.year,
				carbonSourceType: filter.carbonSourceType,
			})
		}
	}, [siteFilterData])

	const handleAdjustBalance = async ({
		changedAmount,
		comment,
	}: AdjustBalanceFormData) => {
		const currentTimestamp = new Date()

		await updateBalance({
			timestamp: currentTimestamp,
			transactionType: TransactionType.BalanceAdjustment,
			carbonSavings: changedAmount,
			commentDetails: {
				name: decodedToken?.name,
				date: new Date().toISOString(),
				content: comment,
			},
		})
		closeAdjustBalanceModal()
	}

	const isUserHasManagePermission = userPermissions?.some(
		x => x.permissionType === PermissionType.ManageTransactions,
	)

	if (
		!filter ||
		!savingsChartData ||
		!savingsStats ||
		!transactionData ||
		!siteFilterData ||
		isLoading
	) {
		return <LoadingPage title={t("title")} />
	}

	return (
		<ContainerBox withBottomMargin data-cy="carbonsaving-page-content">
			<PageHeader
				data-cy="page-title"
				aside={
					isUserHasManagePermission && (
						<ActionButtons
							isYearAssured={savingsStats.isAssured}
							onYearAssureModalOpen={openYearlyBalanceModal}
							onOpenAdjustmentBalanceModal={
								openAdjustBalanceModal
							}
							year={filter.year}
							location={filter.siteName}
						/>
					)
				}
				wrapAside
			>
				{t("title")}
			</PageHeader>
			<FilterForm
				initialValues={{ ...filter }}
				data-cy="filter-form"
				config={dnvYearlyAssuranceFiltersConfig(siteFilterData, t)}
				onChange={data => {
					const newFilterSatate =
						data as unknown as DnvYearlyAssuranceFilterProps
					setFilter(newFilterSatate)
				}}
			/>
			<div className="flex flex-col gap-y-6">
				<BankBalanceCard
					dataYear={filter.year}
					chartData={savingsChartData}
					savingsStats={savingsStats}
					carbonSourceType={filter.carbonSourceType}
				/>
				<TransactionsCard
					dataYear={filter.year}
					data={transactionData}
					carbonSourceType={filter.carbonSourceType}
				/>
			</div>

			<AdjustBalanceModal
				isOpen={isAdjustBalanceModalOpen}
				remainingBuffer={savingsStats.currentBuffer}
				onClose={closeAdjustBalanceModal}
				onSubmit={handleAdjustBalance}
			/>

			{savingsStats.isAssured && (
				<AssureBalanceDoneModal
					isOpen={isYearlyBalanceModalOpen}
					onClose={closeYearlyBalanceModal}
					year={filter.year}
					transactionId={"238218377821789371"} // TODO: Must be changed to data field when BE will be ready
					bufferBalance={savingsStats.currentBuffer}
				/>
			)}
		</ContainerBox>
	)
}
