import { RouteObject } from "react-router-dom"

import { Forbidden } from "./Forbidden"
import { Layout } from "./Layout"
import { NotFound } from "./NotFound"

const baseUrl = "/"

export const commonRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
		children: [
			{
				path: "*",
				element: <NotFound />,
			},
			{
				path: "forbidden",
				element: <Forbidden />,
			},
		],
	},
]

export const getLandingUrl = () => baseUrl
