import { CustomerFilterData, filterApi, SiteFilterData } from "@carbonbank/api"
import { customersApi } from "@carbonbank/api/customers"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { AllocationFilterData } from "./types"

type State = {
	siteFilterData?: SiteFilterData[]
	customerFilterData?: CustomerFilterData[]
	allocationFilterData?: AllocationFilterData
}

type Actions = {
	fetchSiteFilterData: () => Promise<void>
	fetchCustomerFilterData: () => Promise<void>
	fetchAllocationFilterData: () => Promise<void>
}

export const useFilterStore = create<State & Actions>()(
	immer(set => ({
		siteFilterData: undefined,
		customerFilterData: undefined,
		allocationFilterData: undefined,

		fetchSiteFilterData: async () => {
			try {
				const response = await filterApi.fetchSiteFilterData()

				set(state => {
					state.siteFilterData = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch location filter data")
			}
		},
		fetchCustomerFilterData: async () => {
			try {
				const response = await filterApi.fetchCustomerFilterData()

				set(state => {
					state.customerFilterData = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch customer filter data")
			}
		},
		fetchAllocationFilterData: async () => {
			try {
				const [siteResponse, productResponse, customerResponse] =
					await Promise.all([
						filterApi.fetchSiteFilterData(),
						filterApi.fetchProductFilterData(),
						customersApi.getCustomers(),
					])

				set(state => {
					state.allocationFilterData = {
						siteFilterData: siteResponse,
						productFilterData: productResponse,
						customerFilterData: customerResponse.customers,
					}
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch filter data")
			}
		},
	})),
)
