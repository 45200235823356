import { YearlySavingsReport } from "@carbonbank/api"
import { formatNumber } from "@carbonbank/utils"
import {
	DashboardCard,
	CardHeader,
	TableBase,
	TableHeader,
	TableHeaderCell,
	TableRow,
	TableCell,
	TextMedium,
	TextSemiBold,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface BufferBalanceCardProps {
	year: string
	report: YearlySavingsReport
}

export const BufferBalanceCard: React.FC<BufferBalanceCardProps> = ({
	year,
	report: {
		totalDeposits,
		adjustmentRows,
		bufferDepositsRow,
		totalDepositsFromEtsReport,
	},
}: BufferBalanceCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssuranceReport.components.bufferBalanceCard",
	})

	return (
		<DashboardCard data-cy="buffer-balance">
			<CardHeader data-cy="title">{t("title")}</CardHeader>
			<TableBase>
				<TableHeader>
					<TableHeaderCell>{t("headerAction")}</TableHeaderCell>
					<TableHeaderCell className="text-right">
						{t("headerSavings")}
					</TableHeaderCell>
					<TableHeaderCell>{t("headerDetails")}</TableHeaderCell>
				</TableHeader>
				<TableRow
					data-cy="total-deposits"
					foregroundBorder={adjustmentRows.length === 0}
				>
					<TableCell>{t("actionTotalDeposits")}</TableCell>
					<TableCell className="text-right">
						<TextMedium
							className={
								totalDeposits.amount > 0
									? "text-positive"
									: "text-negative"
							}
						>
							{formatNumber(totalDeposits.amount)}
						</TextMedium>
					</TableCell>
					<TableCell>{t("detailsTotalDeposits", { year })}</TableCell>
				</TableRow>
				{adjustmentRows.map(({ carbonSavings, details }, i, a) => (
					<TableRow
						key={i}
						data-cy={`deposit-${i}`}
						foregroundBorder={i === a.length - 1}
					>
						<TableCell>{t("actionManualAdjustment")}</TableCell>
						<TableCell className="text-right">
							<TextMedium className="text-negative">
								{formatNumber(-carbonSavings.amount)}
							</TextMedium>
						</TableCell>
						<TableCell>{details}</TableCell>
					</TableRow>
				))}

				{/* Current buffer */}
				<TableRow data-cy="current-buffer">
					<TableCell>
						<TextSemiBold>{t("actionCurrentBuffer")}</TextSemiBold>
					</TableCell>
					<TableCell className="text-right">
						<TextMedium
							className={
								bufferDepositsRow.carbonSavings.amount > 0
									? "text-positive"
									: "text-negative"
							}
						>
							{formatNumber(
								bufferDepositsRow.carbonSavings.amount,
							)}
						</TextMedium>
					</TableCell>
					<TableCell>{t("detailsCurrentBuffer", { year })}</TableCell>
				</TableRow>
				{totalDepositsFromEtsReport && (
					<>
						<TableRow data-cy="ets-report-delta" foregroundBorder>
							<TableCell>{t("actionReportDelta")}</TableCell>
							<TableCell className="text-right">
								<TextMedium
									className={
										totalDepositsFromEtsReport.amount >
										totalDeposits.amount
											? "text-positive"
											: "text-negative"
									}
								>
									{formatNumber(
										totalDepositsFromEtsReport.amount -
											totalDeposits.amount,
									)}
								</TextMedium>
							</TableCell>
							<TableCell></TableCell>
						</TableRow>

						{/* Remaining buffer to be released */}
						<TableRow data-cy="buffer-to-be-released">
							<TableCell>
								<TextSemiBold>
									{t("actionBufferToRelease")}
								</TextSemiBold>
							</TableCell>
							<TableCell className="text-right">
								<TextMedium
									className={
										bufferDepositsRow.carbonSavings.amount +
											totalDepositsFromEtsReport.amount >
										totalDeposits.amount
											? "text-positive"
											: "text-negative"
									}
								>
									{formatNumber(
										bufferDepositsRow.carbonSavings.amount +
											totalDepositsFromEtsReport.amount -
											totalDeposits.amount,
									)}
								</TextMedium>
							</TableCell>
							<TableCell>{t("detailsBufferToRelease")}</TableCell>
						</TableRow>
					</>
				)}
			</TableBase>
			<div
				data-cy="description"
				className="mt-24px text-foreground_faded"
			>
				{t("description")}
			</div>
		</DashboardCard>
	)
}
