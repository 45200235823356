import { Textarea } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const FormFieldsRequestInfoFlow: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureModal.requestInfoFlow",
	})

	return (
		<Textarea
			name="comment"
			data-cy="comment"
			label={t("explanationLabel")}
		/>
	)
}
