﻿import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const OwnerAddress = (
	props: {
		street: string
		postalCode: string
		city: string
		country: string
		customerName: string
	} & NodeProps,
) => {
	return (
		<View>
			<Text style={styles.text_regular}>Owner</Text>
			<Text style={styles.text_regular_bold}>{props.customerName}</Text>
			<Text style={styles.text_regular_bold}>{props.street}</Text>
			<Text style={styles.text_regular_bold}>{props.postalCode}</Text>
			<Text style={styles.text_regular_bold}>{props.city}</Text>
			<Text style={styles.text_regular_bold}>{props.country}</Text>
		</View>
	)
}
