import { useContractsStore } from "@carbonbank/store"
import { CardHeader, DashboardCard } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { DeliveriesTable } from "./DeliveriesTable"

export const Deliveries = () => {
	const contract = useContractsStore(state => state.contract)
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.DeliveriesTable",
	})
	return (
		<DashboardCard className="mb-5" data-cy="contract-details-deliveries">
			<CardHeader>{t("title")}</CardHeader>
			<DeliveriesTable data={contract?.invoices} />
		</DashboardCard>
	)
}
