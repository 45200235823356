import {
	Button,
	H4,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextLong,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface AccountabilityModalProps {
	openModal: boolean
	setOpenModal: (open: boolean) => void
	contentKey: "" | "accountability" | "traceability"
}
export const InformationModal: React.FC<AccountabilityModalProps> = ({
	openModal,
	setOpenModal,
	contentKey,
}) => {
	const { t: tAccountability } = useTranslation("transactions", {
		keyPrefix: "transactionDetails.components.accoutabilityModal",
	})
	const { t: tTraceability } = useTranslation("transactions", {
		keyPrefix: "transactionDetails.components.traceabilityModal",
	})

	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	return (
		<Modal
			open={openModal}
			onClose={() => setOpenModal(false)}
			data-cy={`${contentKey}-modal`}
			className="w-[800px]"
		>
			{contentKey === "accountability" && (
				<>
					<ModalTitle data-cy="info-title">
						{tAccountability("mainTitle")}
					</ModalTitle>
					<ModalContent data-cy="info-content">
						<div className="flex flex-col">
							<TextLong>
								{tAccountability("firstParagraph")}
							</TextLong>
							<br />
							<TextLong>
								{tAccountability("secondParagraph")}
							</TextLong>
							<TextLong>
								{tAccountability("thirdParagraph")}
							</TextLong>
							<br />
							<TextLong>
								{tAccountability("fourthParagraph")}
							</TextLong>
							<br />
							<TextLong>
								{tAccountability("fifthParagraph")}
							</TextLong>
							<br />
							<H4>{tAccountability("subTitle")}</H4>
							<br />
							<TextLong>
								{tAccountability("firstSubParagraph")}
								<span className="font-bold">
									{tAccountability("firstSubParagraphBold")}
								</span>
							</TextLong>
							<br />
						</div>
						<div className="flex gap-6">
							<TextLong>
								<span className="font-bold">
									{tAccountability("secondSubParagraphBold")}
								</span>
								{tAccountability("secondSubParagraph")}
							</TextLong>
							<div className="border p-2 shadow-md rounded-md">
								<TextLong>
									{tAccountability("firstSubParagraph")}
									<span className="font-bold">
										{tAccountability("thirdSubParagraph")}
									</span>
								</TextLong>
							</div>
						</div>
					</ModalContent>
				</>
			)}

			{contentKey === "traceability" && (
				<>
					<ModalTitle>{tTraceability("title")}</ModalTitle>
					<ModalContent>
						<TextLong>{tTraceability("description")}</TextLong>
					</ModalContent>
				</>
			)}
			<ModalFooter>
				<div className="flex gap-6">
					<Button
						variant="primary"
						onClick={() => setOpenModal(false)}
						data-cy="close-button"
					>
						{tCommon("close")}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
