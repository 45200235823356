import axios, { AxiosRequestConfig } from "axios"

import {
	statusCheckInterceptor,
	correlationIdInterceptor,
} from "../interceptors"

export const carbonBankUrl = import.meta.env.VITE_SUSTAINABILITY_CARBONBANK_API

const axiosConfig: AxiosRequestConfig = {
	baseURL: carbonBankUrl,
	headers: {
		"Content-Type": "application/json",
	},
}

export const carbonBankClient = axios.create(axiosConfig)

carbonBankClient.interceptors.request.use(correlationIdInterceptor)
carbonBankClient.interceptors.response.use(
	response => response,
	statusCheckInterceptor,
)
