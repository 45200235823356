import { Contract } from "@carbonbank/api"
import {
	DashboardCard,
	Button,
	icon,
	Label,
	CardHeader,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface BundledDeliveredProductProps {
	data?: Contract
}
export const BundledDeliveredProduct: React.FC<
	BundledDeliveredProductProps
> = ({ data }: BundledDeliveredProductProps) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.bundledDeliveredProduct",
	})

	return (
		<DashboardCard className="self-stretch">
			<CardHeader
				aside={
					<Button variant="tertiary" icon={icon.mdiOpenInNew}>
						{t("showEpd")}
					</Button>
				}
				wrapAside={false}
				className="!text-wrap"
			>
				{t("title")}
			</CardHeader>
			<div className="flex flex-col gap-y-6">
				<Label title={t("bundledDeliveredProduct")}>
					{data?.bundledProduct}
				</Label>
				<Label title={t("productOrigin")}>
					{data?.originBundeledProduct}
				</Label>
			</div>
		</DashboardCard>
	)
}
