import { correlationScope } from "@carbonbank/infrastructure/analytics"
import { FormikSubmitCallback } from "@carbonbank/types"
import { formatNumberWithUnit, UnitType } from "@carbonbank/utils"
import {
	useAnalytics,
	useCorrelationScope,
} from "@sustainability/analytics-frontend"
import {
	Alert,
	Button,
	Input,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	Textarea,
	Label,
	Toggle,
	spawnSuccessToast,
} from "@sustainability/fundamental"
import { Form, Formik } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useValidationSchema } from "./validationSchema"

export interface AdjustBalanceFormData {
	changedAmount: number
	comment: string
	confirmed: boolean
}

interface AdjustBalanceModalProps {
	isOpen: boolean
	remainingBuffer: number
	onClose: () => void
	onSubmit: (data: AdjustBalanceFormData) => void
}

export const AdjustBalanceModal: React.FC<AdjustBalanceModalProps> = ({
	isOpen,
	remainingBuffer,
	onClose,
	onSubmit,
}: AdjustBalanceModalProps) => {
	const [, initScope, concludeScope] = useCorrelationScope(
		false,
		correlationScope.set,
	)
	const { trackEvent } = useAnalytics()
	useEffect(() => {
		if (isOpen) {
			const correlationId = initScope()
			trackEvent("AdjustBalanceModalOpened", {
				correlationId,
			})
		} else {
			concludeScope()
		}
	}, [isOpen])

	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.components.adjustBalanceModal",
	})

	const validationSchema = useValidationSchema()

	const formValues: AdjustBalanceFormData = {
		changedAmount: 0,
		comment: "",
		confirmed: false,
	}

	const handleSubmit: FormikSubmitCallback<AdjustBalanceFormData> = (
		values,
		{ setSubmitting, resetForm },
	) => {
		onSubmit(values)

		setSubmitting(false)
		resetForm()
		spawnSuccessToast(t("successToast"), { "data-cy": "toast-success" })
		trackEvent("AdjustBalanceModalClosed")
	}

	return (
		<Modal data-cy="adjust-balance-modal" open={isOpen} onClose={onClose}>
			<ModalTitle data-cy="title">{t("title")}</ModalTitle>
			<Formik
				initialValues={formValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting, isValid, dirty }) => (
					<Form data-cy="form">
						<ModalContent>
							<div className="flex flex-col gap-y-6">
								<div>{t("description")}</div>
								<div className="flex gap-x-3">
									<Input
										data-cy="field-changedAmount"
										name="changedAmount"
										label={t("changedAmountLabel")}
										className="w-3/5"
									/>
									<Label
										className="mt-2"
										data-cy="remaining-buffer"
										title={t("remainingBufferLabel")}
									>
										{formatNumberWithUnit(
											remainingBuffer,
											UnitType.KgCO2,
										)}
									</Label>
								</div>
								<Textarea
									data-cy="field-comment"
									name="comment"
									label={t("commentLabel")}
									hint={t("commentHint")}
								/>
								<Alert variant="error" data-cy="alert">
									{t("alert")}
								</Alert>
								<label className="flex gap-x-3">
									<Toggle
										data-cy="field-confirmed"
										name="confirmed"
										label={t("confirmLabel")}
									/>
								</label>
							</div>
						</ModalContent>
						<ModalFooter>
							<Button
								data-cy="action-close"
								variant="secondary"
								type="reset"
								onClick={onClose}
							>
								{t("cancel")}
							</Button>
							<Button
								data-cy="action-submit"
								variant="primary"
								type="submit"
								disabled={isSubmitting || !isValid || !dirty}
							>
								{t("adjustBalance")}
							</Button>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
