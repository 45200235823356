import { SiteFilterData } from "@carbonbank/api"
import { FilterConfig } from "@sustainability/fundamental"
import { TFunction } from "i18next"

const mapInputToValue = (input: SiteFilterData[]) =>
	input.map(value => ({ value: value.siteName!, label: value.siteName! }))

export const carbonSavingsFilterConfig = (
	input: SiteFilterData[],
	t: TFunction<"cs", "carbonSavings.page">,
): FilterConfig[] => [
	{
		label: t("location"),
		type: "select",
		values: mapInputToValue(input),
		name: "siteName",
	},
]
