import { CardSubHeader, Input } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const FormFieldsAssureFlow: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureModal.assureFlow",
	})

	return (
		<>
			<CardSubHeader>{t("title")}</CardSubHeader>
			<Input
				label={t("serialNumberLabel")}
				name="serialNumber"
				data-cy="serial-number"
				autoComplete="off"
			/>
		</>
	)
}
