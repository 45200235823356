import { RouteObject } from "react-router-dom"

import { Layout } from "./Layout"
import { CustomerPage } from "./customerPage"

const baseUrl = "/customer/:id"

export const customerRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <CustomerPage />,
			},
		],
	},
]

export const getCustomerUrl = () => baseUrl
