import {
	Contact,
	Contract,
	ContractSearchParams,
	ContractShortView,
	contractsApi,
	transferApi,
} from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	contracts?: ContractShortView[]
	selectedContractNumber: string | null
	contract?: Contract
	contacts?: Contact[]
	error: string | null
	isLoading: boolean
}
type Actions = {
	fetchContracts: (params: ContractSearchParams) => Promise<void>
	fetchClosedContracts: (params: ContractSearchParams) => Promise<void>
	fetchContractDetails: (id: string) => Promise<void>
	selectContractHandler: (id: string) => void
	fetchContacts: (contractNumber: string) => Promise<void>
}
export const useContractsStore = create<State & Actions>()(
	immer(set => ({
		contracts: undefined,
		contractDetails: undefined,
		contacts: [],
		selectedContractNumber: null,
		isLoading: true,
		error: null,
		fetchContracts: async params => {
			try {
				if (!params.customerNumber) {
					return
				}

				set(state => {
					state.contracts = undefined
					state.isLoading = true
					state.error = null
				})
				const response = await contractsApi.getContracts(params)
				set(state => {
					state.contracts = response
				})
			} catch (error) {
				console.error("Failed to fetch contracts:", error)
			}
		},
		fetchClosedContracts: async params => {
			try {
				const response = await contractsApi.getContracts(params)
				set(state => {
					state.contracts = response
					state.isLoading = false
				})
			} catch (error) {
				set(state => {
					state.error = "Failed to fetch contracts"
					state.isLoading = false
				})
				spawnErrorToast("Failed to fetch contracts")
			}
		},
		fetchContractDetails: async (id: string) => {
			try {
				set(state => {
					state.isLoading = true
					state.error = null
				})
				const response = await contractsApi.getContractById(id)
				set(state => {
					state.contract = response
					state.isLoading = false
				})
			} catch (error) {
				set(state => {
					state.error = "Failed to fetch contract details"
					state.isLoading = false
				})
				spawnErrorToast("Failed to fetch contract details")
			}
		},
		fetchContacts: async (contractNumber: string) => {
			try {
				const response =
					await transferApi.getPreviousContacts(contractNumber)
				set(state => {
					state.contacts = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch contacts")
			}
		},
		selectContractHandler: (id: string) => {
			set(state => {
				state.selectedContractNumber = id
			})
		},
	})),
)
