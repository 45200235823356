let _correlationId: string | undefined = undefined

export const correlationScope = {
	get() {
		return _correlationId
	},
	set(id: string | undefined) {
		_correlationId = id
	},
}
