import { SucessTransfer } from "@carbonbank/assets"
import { useCarbonTransferStore, useContractsStore } from "@carbonbank/store"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getContractsUrl } from "../../contractsRoutes"
import { TransferFormInputs } from "../types"

export const SuccessModal: React.FC = () => {
	const { openSuccessModal, setSuccessModal } = useCarbonTransferStore()
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferSuccessModal",
	})
	const contract = useContractsStore(state => state.contract)
	const navigate = useNavigate()
	const { values } = useFormikContext<TransferFormInputs>()
	const isPartialTransfer =
		values?.cementAmount &&
		contract?.accumulatedQuantity &&
		values.cementAmount < contract.accumulatedQuantity
	return (
		<Modal
			open={openSuccessModal}
			onClose={() => setSuccessModal()}
			data-cy="transfer-success-modal"
		>
			<ModalTitle>{t("title")}</ModalTitle>
			<ModalContent>
				<div className="flex flex-col justify-center items-center gap-6">
					<img
						src={SucessTransfer}
						alt="dnvassurance"
						className="w-56 h-60"
					/>
					{isPartialTransfer ? (
						<TextRegular>{t("epdTransferText")}</TextRegular>
					) : values?.cementAmount &&
					  contract?.accumulatedQuantity ? (
						<TextRegular>{t("autoTransfertext")}</TextRegular>
					) : null}
				</div>
			</ModalContent>
			<ModalFooter>
				<div className="flex gap-6">
					<Button
						data-cy="close-success-modal"
						onClick={() => {
							setSuccessModal()
							navigate(getContractsUrl())
						}}
					>
						{t("close")}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
