export const getQueryStringByObject = (
	queryObject:
		| string
		| string[][]
		| Record<string, string>
		| URLSearchParams
		| undefined,
): string => {
	const queryParams = new URLSearchParams(queryObject)
	return queryParams.toString()
}

export const getObjectByQueryString = (queryString: string): unknown => {
	const queryParams = new URLSearchParams(queryString)
	return Object.fromEntries(queryParams)
}
