﻿import { EmissionsResponse, EmissionStepResponse } from "@carbonbank/api"
import {
	CementManufacturingImage,
	ConcreteProductImage,
	DnvAssuranceTransactionImage,
	FinalOwnerImage,
	PermanentStorageImage,
	ReceivingTerminalImage,
	TemporaryStorageImage,
	TransportPreparationImage,
	TransportViaShipImage,
} from "@carbonbank/assets"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { formatTimestampAsFullDate } from "@carbonbank/utils"
import { Image, NodeProps, Text, View } from "@react-pdf/renderer"

export const TransferCyclesSection = ({
	emissions,
}: {
	emissions: EmissionsResponse[]
} & NodeProps) => {
	const firstCycle = emissions[0]
	const allSteps = emissions.flatMap(c => c.steps)
	return (
		<>
			{firstCycle.steps.map(step => {
				const stepInfo = getStepInfo(
					allSteps.filter(s => s.name === step.name),
				)
				return (
					<View
						wrap={false}
						key={`${step.name}-${stepInfo.id}`}
						style={{
							...styles.flex_row,
							...styles.justify_space_between,
							...styles.gap_20,
						}}
					>
						<View
							style={{
								...styles.border_underline,
								...styles.width_92px,
								...styles.height_86px,
							}}
						>
							<Image src={stepInfo.image} />
						</View>
						<View
							style={{
								...styles.border_underline,
								...styles.gap_10,
								...styles.flex_1,
							}}
						>
							<View
								style={{
									...styles.flex_row,
									...styles.gap_10,
								}}
							>
								<Text
									style={{
										...styles.text_regular_bold,
										...styles.text_color_grey,
									}}
								>
									Step {stepInfo.id}
								</Text>
								<Text
									style={{
										...styles.text_regular_bold,
									}}
								>
									{stepInfo.title}
								</Text>
							</View>
							<Text style={{ ...styles.text_regular }}>
								{stepInfo.description}
							</Text>
							<View
								style={{
									...styles.flex_row,
									...styles.gap_10,
								}}
							>
								<Text style={{ ...styles.text_regular }}>
									Date
								</Text>
								<Text
									style={{
										...styles.text_regular_bold,
									}}
								>
									{formatDuration(stepInfo.duration)}
								</Text>
							</View>
						</View>
					</View>
				)
			})}
		</>
	)
}

const formatDuration = ([startDate, endDate]: [string, string]) => {
	return startDate === endDate
		? startDate ?? "@step.startDate"
		: `${formatTimestampAsFullDate(startDate)} - ${formatTimestampAsFullDate(endDate)}`
}

const getDurationForStep = (
	steps: EmissionStepResponse[],
): [string, string] => {
	const stepsByEarliestStartDate = steps.sort(
		(a, b) => Date.parse(a.startDate) - Date.parse(b.startDate),
	)
	const stepsSortedByLatestEndDate = steps.sort(
		(a, b) => Date.parse(b.startDate) - Date.parse(a.startDate),
	)
	const startDate = stepsByEarliestStartDate[0].startDate
	const endDate = stepsSortedByLatestEndDate[0].endDate
	return [
		formatTimestampAsFullDate(startDate),
		formatTimestampAsFullDate(endDate),
	]
}

const getStepInfo = (steps: EmissionStepResponse[]) => {
	const stepName = steps[0].name
	switch (stepName) {
		case "co2_capture_produced":
			return {
				id: 1,
				title: "Cement manufacturing and carbon capture",
				description:
					"The carbon is captured during the clinker process via amine-based technology",
				image: CementManufacturingImage,
				duration: getDurationForStep(steps),
			}
		case "co2_capture_stored":
			return {
				id: 2,
				title: "Temporary storage on the plant",
				description:
					"Liquefaction of captured carbon and intermediate onsite storage",
				image: TemporaryStorageImage,
				duration: getDurationForStep(steps),
			}
		case "co2_capture_delivered":
			return {
				id: 3,
				title: "Preparation for transport",
				description:
					"The carbon is pumped from the plant’s silos to the ship, operated by Northern Lights",
				image: TransportPreparationImage,
				duration: getDurationForStep(steps),
			}
		case "co2_storage_received":
			return {
				id: 4,
				title: "Carbon transport via ship",
				description:
					"Purpose-built ships transport the liquid carbon at -26°C to Øygården for permanent storage.",
				image: TransportViaShipImage,
				duration: getDurationForStep(steps),
			}
		case "co2_storage_shipped":
			return {
				id: 5,
				title: "Receiving terminal",
				description:
					"Upon arrival in Øygården, the liquid carbon is pumped into the pipeline.",
				image: ReceivingTerminalImage,
				duration: getDurationForStep(steps),
			}
		case "co2_storage_stored":
			return {
				id: 6,
				title: "Permanent carbon storage",
				description:
					"The carbon is captured during the clinker process via amine-based technology",
				image: PermanentStorageImage,
				duration: getDurationForStep(steps),
			}
		case "deposited":
			return {
				id: 7,
				title: "Assurance by DNV, allowing a carbon bank deposit",
				description:
					"Once the 3rd party assurance body ‘DNV’ has assured the stored captured carbon volume in a digital Monitor-Report-Verify system, a new deposit is made in the carbon bank. ",
				image: DnvAssuranceTransactionImage,
				duration: getDurationForStep(steps),
			}
		case "withdraw":
			return {
				id: 8,
				title: "evoZero delivery to [RMC Producer], triggering a carbon bank withdrawal",
				description:
					"evoZero delivery to [RMC Producer], triggering a carbon bank withdrawal",
				image: ConcreteProductImage,
				duration: getDurationForStep(steps),
			}
		case "transferred":
			return {
				id: 9,
				title: "Transfer of carbon savings to [Customer]",
				description:
					"The concrete producer initiated the transfer of the carbon savings to [Customer], based on the evoZero cement volume that went in to the delivered concrete mix.",
				image: FinalOwnerImage,
				duration: getDurationForStep(steps),
			}
		default:
			throw Error(`event name ${stepName} is not valid`)
	}
}
