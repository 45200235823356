import { clx } from "@sustainability/fundamental"

interface ContainerBoxProps {
	children?: React.ReactNode
	"data-cy"?: string
	className?: string
	withBottomMargin?: boolean
}

export const ContainerBox: React.FC<ContainerBoxProps> = ({
	children,
	"data-cy": dataCy,
	className,
	withBottomMargin = false,
}: ContainerBoxProps) => {
	return (
		<div
			data-cy={dataCy}
			className={clx("px-24px", withBottomMargin && "mb-24px", className)}
		>
			{children}
		</div>
	)
}
