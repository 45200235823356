import { ModalContent, Loading } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const StepAssuring: React.FC = () => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureBalanceModal.assuringShared",
	})

	return (
		<ModalContent
			data-cy="step-assure"
			className="h-full flex flex-col items-center justify-center"
		>
			<Loading size="large" className="m-auto mb-12px" />
			{t("assuringLabel")}
		</ModalContent>
	)
}
