import { certificateApi, CustomerDetails, ProjectData } from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	certificates?: CustomerDetails
	isLoading: boolean
}
type Actions = {
	fetchCertificatesInformation: (id: string) => Promise<void>
	submitData: (
		projectDataInput: ProjectData,
		imageFile: File,
	) => Promise<void>
}

export const useCustomerStore = create<State & Actions>()(
	immer(set => ({
		certificates: undefined,
		isLoading: true,
		fetchCertificatesInformation: async (id: string) => {
			try {
				set(state => {
					state.isLoading = true
				})
				const response =
					await certificateApi.getBuildingProjectInformation(id)
				return set(state => {
					state.certificates = response
					state.isLoading = false
				})
			} catch (error) {
				spawnErrorToast(
					i18next.t("customer:customer.fetchingDataError"),
				)
				set(state => {
					state.isLoading = false
				})
			}
		},
		submitData: async (projectDataInput: ProjectData, imageFile: File) => {
			try {
				const projectData = {
					projectId: projectDataInput.projectId,
					projectTitle: projectDataInput.projectTitle,
					description: projectDataInput.description,
					city: projectDataInput.city,
					country: projectDataInput.country,
					completion: projectDataInput.completion,
					published: projectDataInput.published,
				}
				await certificateApi.createProject(projectData, imageFile)
			} catch (error) {
				spawnErrorToast("Failed to submit project")
			}
		},
	})),
)
