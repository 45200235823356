import { TransferCertificate } from "@carbonbank/api"
import { Button, Grid, H1, icon } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

import { MicroSiteLogo } from "../assets"

interface MicroSiteDecriptionProps {
	certificate?: TransferCertificate
}
export const MicroSiteDecription: React.FC<MicroSiteDecriptionProps> = ({
	certificate,
}) => {
	const { t } = useTranslation("microsite", {
		keyPrefix: "microsite.descption",
	})

	return (
		<Grid size={1} lgSize={2} data-cy="microsite-description">
			<div className="flex flex-col gap-6 pr-12">
				<H1 className="text-foreground_faded">
					<p>{certificate?.buildingProject.reference}</p>
				</H1>
				<p>{certificate?.buildingProject.description}</p>
				<div className="flex flex-col">
					<p>{t("completion")}</p>
					<p className="font-bold">
						{certificate?.buildingProject.completion}
					</p>
				</div>
				<div className="flex flex-col">
					<p>{t("builtWith")}</p>
					{certificate?.declaredProduct
						.toLocaleLowerCase()
						.includes("evozero") ? (
						<img
							src={MicroSiteLogo}
							className="w-[110px] h-[15px]"
							alt="micrositeLogo"
							data-cy="microsite-logo"
						/>
					) : (
						<p>{certificate?.declaredProduct}</p>
					)}
				</div>
			</div>
			<div className="flex flex-col gap-6 pr-12">
				<H1 className="text-foreground_faded">
					{certificate?.totalSavings.quantity} Kg CO<sub>2</sub>{" "}
					{t("title2")}
				</H1>
				<p>
					{t("descriptionFirstPart")}
					{certificate?.totalSavings.quantity}
					{t("descriptionSecondPart")}
				</p>
				<div className="flex items-center">
					<p className="font-bold">{t("viewTransaction")}</p>
					<Button
						variant="tertiary"
						icon={icon.mdiCallMade}
						className="text-black"
					></Button>
				</div>
			</div>
		</Grid>
	)
}
