import { Contract } from "@carbonbank/api"
import { CarbonCaptureModal } from "@carbonbank/components"
import {
	Button,
	CardHeader,
	DashboardCard,
	icon,
} from "@sustainability/fundamental"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CarbonSavingTable } from "./tables"

interface CarbonSavingsProps {
	data?: Contract
}
export const CarbonSavings: React.FC<CarbonSavingsProps> = ({ data }) => {
	const [openCarbonInfo, setOpenCarbonInfo] = useState(false)
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.carbonSavingTable",
	})
	return (
		<DashboardCard data-cy="contract-details-carbonsavings">
			<CardHeader
				aside={
					<Button
						className="text-wrap"
						variant="tertiary"
						icon={icon.mdiInformationOutline}
						onClick={() => setOpenCarbonInfo(true)}
						data-cy="carbonsavings_modal_button"
					>
						{t("carbonCaptureAndStorage")}
					</Button>
				}
				wrapAside
				className="!text-wrap"
			>
				{t("title")}
			</CardHeader>
			<CarbonCaptureModal
				openCarbonInfo={openCarbonInfo}
				setOpenCarbonInfo={setOpenCarbonInfo}
			/>
			<CarbonSavingTable data={data} />
		</DashboardCard>
	)
}
