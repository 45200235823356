import { CarbonSourceType } from "@carbonbank/api"

type CarbonSourceTitleTypes = "common" | "biogenic" | "fossil"
type ChartLineColorVariables = "--accent_1" | "--accent_2" | "--accent_3"
type CarbonSourceBgClassNames = "bg-accent_1" | "bg-accent_2" | "bg-accent_3"

export const carbonSourceTypeToTitleTypeMap: Record<
	CarbonSourceType,
	CarbonSourceTitleTypes
> = {
	[CarbonSourceType.All]: "common",
	[CarbonSourceType.Biogenic]: "biogenic",
	[CarbonSourceType.Fossil]: "fossil",
}

export const carbonSourceTypeToChartLineColorVariableMap: Record<
	CarbonSourceType,
	ChartLineColorVariables
> = {
	[CarbonSourceType.All]: "--accent_1",
	[CarbonSourceType.Biogenic]: "--accent_3",
	[CarbonSourceType.Fossil]: "--accent_2",
}

export const carbonSourceTypeToBgClassNameMap: Record<
	CarbonSourceType,
	CarbonSourceBgClassNames
> = {
	[CarbonSourceType.All]: "bg-accent_1",
	[CarbonSourceType.Biogenic]: "bg-accent_3",
	[CarbonSourceType.Fossil]: "bg-accent_2",
}
