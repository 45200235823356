import {
	TokenResponse,
	hconnectIdentityApi,
	hconnectUrl,
	RevokeRefreshTokenRequest,
	AuthRedirectQueryParams,
	DecodedToken,
	LoginFlowState,
	LoginState,
	PersistedToken,
	CreationTokenPayload,
} from "@carbonbank/api"
import { jwtDecode } from "jwt-decode"

import { deriveChallenge, generateRandomString } from "../authCryptography"
import {
	getStorageItemByName,
	removeStorageItemByName,
	setStorageItem,
} from "../local-storage"
import { getObjectByQueryString, getQueryStringByObject } from "../query-string"

const TOKEN_STORAGE_KEY = "CarbonBank"
const LOGIN_FLOW_STORAGE_KEY = "CarbonBank-loginFlow"

const CARBON_BANK_CLIENT_ID = "CarbonBank"

const SIGN_IN_PATH = "/auth"
const SIGN_OUT_PATH = "/auth/callback/logout"

const validateAuthRedirectQueryParams = (
	queryParams: AuthRedirectQueryParams,
	state?: string,
) => {
	if (queryParams.error) {
		throw new Error(
			`There has been an error while signin: ${queryParams.error_description}`,
		)
	}

	if (!queryParams.code) {
		throw new Error(
			"There was no code returned from IdentityServer, aborting login flow.",
		)
	}

	if (state !== queryParams.state) {
		throw new Error("There has been a state mismatch.")
	}
}

const getCreationTokenPayload = (
	queryParams: AuthRedirectQueryParams,
	flowState?: LoginFlowState,
): CreationTokenPayload => {
	const CARBON_BANK_GRANT_TYPE = "authorization_code"

	const creationTokenPayload: CreationTokenPayload = {
		grant_type: CARBON_BANK_GRANT_TYPE,
		client_id: CARBON_BANK_CLIENT_ID,
		code: queryParams.code,
		code_verifier: flowState?.codeVerifier ?? "",
		redirect_uri: `${window.location.origin}${SIGN_IN_PATH}`,
	}

	return creationTokenPayload
}

const exchangeAuthorizationCode = async (): Promise<TokenResponse> => {
	const flowState = getStorageItemByName<LoginFlowState>(
		LOGIN_FLOW_STORAGE_KEY,
	)

	const queryParams = getObjectByQueryString(
		window.location.search,
	) as AuthRedirectQueryParams

	validateAuthRedirectQueryParams(queryParams, flowState?.state)

	const params = getCreationTokenPayload(queryParams, flowState)

	return await hconnectIdentityApi.createToken(params)
}

const navigateToOrigin = () => {
	window.history.replaceState(null, "", window.origin)
}

const getNotSignActionState = (accessToken?: string): LoginState => {
	if (!accessToken) {
		return {
			loggedIn: false,
		}
	}

	const decodedToken = jwtDecode<DecodedToken>(accessToken)

	return {
		loggedIn: true,
		decodedToken,
	}
}

const getSignOutState = (): LoginState => {
	removeStorageItemByName(LOGIN_FLOW_STORAGE_KEY)
	navigateToOrigin()

	return {
		loggedIn: false,
	}
}

const getSignInState = async (): Promise<LoginState> => {
	const authToken = await exchangeAuthorizationCode()
	const isInvalidAuthToken =
		!authToken || !authToken.access_token || !authToken.refresh_token

	if (isInvalidAuthToken) {
		navigateToOrigin()

		return {
			loggedIn: false,
		}
	}

	const persistedToken: PersistedToken = {
		idToken: authToken.id_token,
		accessToken: authToken.access_token,
		refreshToken: authToken.refresh_token,
	}

	setStorageItem(TOKEN_STORAGE_KEY, persistedToken)
	removeStorageItemByName(LOGIN_FLOW_STORAGE_KEY)

	const decodedToken = jwtDecode<DecodedToken>(persistedToken.accessToken)
	const stateToken = window.origin.includes(hconnectUrl)
		? persistedToken
		: undefined

	return {
		loggedIn: true,
		decodedToken,
		token: stateToken,
	}
}

const getSignInQueryParams = async (state: string, codeVerifier: string) => {
	const CARBON_BANK_RESPONSE_TYPE = "code"
	const CARBON_BANK_SCOPE = "global openid offline_access"
	const CARBON_BANK_RESPONSE_MODE = "query"

	const signInUri = `${window.location.origin}${SIGN_IN_PATH}`

	const codeChallenge = await deriveChallenge(codeVerifier).catch(error => {
		console.error("Browser does not support crypto, not using PKCE", error)
		return undefined
	})

	return {
		redirect_uri: signInUri,
		client_id: CARBON_BANK_CLIENT_ID,
		response_type: CARBON_BANK_RESPONSE_TYPE,
		scope: CARBON_BANK_SCOPE,
		state,
		response_mode: CARBON_BANK_RESPONSE_MODE,
		...(codeChallenge
			? {
					code_challenge: codeChallenge,
					code_challenge_method: "S256",
				}
			: {}),
	}
}

const getFlowHref = () => {
	const isSignInOrSignOutUrl =
		window.location.pathname.indexOf(SIGN_IN_PATH) === 0

	return isSignInOrSignOutUrl ? window.origin : window.location.href
}

const createSignInRequest = async (authorizationUrl: string) => {
	const authorizationUrlParams = new URL(authorizationUrl)

	const state = generateRandomString(21)
	const codeVerifier = generateRandomString(96)
	const href = getFlowHref()

	const queryParams = await getSignInQueryParams(state, codeVerifier)
	const query = getQueryStringByObject(queryParams)

	const stringifiedQueryParams =
		query + authorizationUrlParams.search.replace("?", "&")

	return {
		url: `${authorizationUrl}?${stringifiedQueryParams}`,
		flow: {
			state,
			href,
			codeVerifier,
		},
	}
}

const createSignOutRequest = (endSessionUrl: string, token: PersistedToken) => {
	const signOutUri = `${window.location.origin}${SIGN_OUT_PATH}`

	const endSessionUrlParams = new URL(endSessionUrl)

	const queryParams = {
		id_token_hint: token.idToken,
		post_logout_redirect_uri: signOutUri,
	}
	const query = getQueryStringByObject(queryParams)

	const stringifiedQueryParams =
		query + endSessionUrlParams.search.replace("?", "&")

	return {
		url: `${endSessionUrl}?${stringifiedQueryParams}`,
	}
}

const revokeRefreshToken = async (token: PersistedToken) => {
	const CARBON_BANK_TOKEN_TYPE_HINT = "refresh_token"

	const body: RevokeRefreshTokenRequest = {
		client_id: CARBON_BANK_CLIENT_ID,
		token_type_hint: CARBON_BANK_TOKEN_TYPE_HINT,
		token: token.refreshToken,
	}

	await hconnectIdentityApi.revokeRefreshToken(body)
}

export const getLoginState = async (): Promise<LoginState> => {
	const currentPath = window.location.pathname
	const currentToken = getStorageItemByName<PersistedToken>(TOKEN_STORAGE_KEY)

	switch (currentPath) {
		case SIGN_IN_PATH:
			return await getSignInState()
		case SIGN_OUT_PATH:
			return getSignOutState()
		default:
			return getNotSignActionState(currentToken?.accessToken)
	}
}

export const startLoginProcess = async () => {
	const openIdConfig = await hconnectIdentityApi.fetchOpenIdConfig()
	const authorizationUrl = openIdConfig.authorization_endpoint || ""

	const signInRequest = await createSignInRequest(authorizationUrl)

	setStorageItem(LOGIN_FLOW_STORAGE_KEY, signInRequest.flow)
	window.location.href = signInRequest.url
}

export const startLogoutProcess = async () => {
	const token = getStorageItemByName<PersistedToken>(TOKEN_STORAGE_KEY)

	if (!token) {
		navigateToOrigin()
		return
	}

	const openIdConfig = await hconnectIdentityApi.fetchOpenIdConfig()
	const endSessionUrl = openIdConfig.end_session_endpoint || ""

	const signOutRequest = createSignOutRequest(endSessionUrl, token)

	await revokeRefreshToken(token)

	removeStorageItemByName(TOKEN_STORAGE_KEY)
	window.location.href = signOutRequest.url
}
