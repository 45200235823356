import { carbonBankWithAuthClient } from "@carbonbank/api"
import { CustomersResponses } from "@carbonbank/api/customers/types.ts"
import { generateGetUrl } from "@carbonbank/api/utils.ts"

export const customersApi = {
	getCustomers: (): Promise<CustomersResponses> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/customers"))
			.then(r => r.data),
}
