﻿import { carbonBankUrl } from "@carbonbank/api"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { Image, NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const QrCodeVerificationSection = (
	props: {
		transactionLink: string
		certificateTransactionNumber: string
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.flex_row, ...styles.gap_20 }}>
			<View style={{ ...styles.width_64px, ...styles.height_64px }}>
				<Image src={`${carbonBankUrl}/certificates/qr-code`}></Image>
			</View>
			<View style={{ ...styles.gap_20, ...styles.max_width_600px }}>
				<View>
					<Text style={{ ...styles.text_regular }}>
						To view the transaction details, you can either scan the
						QR code provided or simply click on the provided link.
						In case these options are not feasible, please visit
						evozero-carbonsavings.heidelbergmaterials.com on your
						preferred browser and search for the transaction number
						on the site.
					</Text>
				</View>
				<View>
					<Text>
						<Text style={{ ...styles.text_regular }}>
							Link to the transaction:
						</Text>
						<Text
							style={{
								...styles.text_regular_bold,
								...styles.text_color_grey,
							}}
						>
							&nbsp;{props.transactionLink}
						</Text>
					</Text>
					<Text>
						<Text style={{ ...styles.text_regular }}>
							Certificate Transaction number:
						</Text>
						<Text
							style={{
								...styles.text_regular_bold,
								...styles.text_color_grey,
							}}
						>
							&nbsp;{props.certificateTransactionNumber}
						</Text>
					</Text>
				</View>
			</View>
		</View>
	)
}
