interface PermissionDataScope {
	areaId?: string
	countryId?: string
	businessLine?: string
	businessLineId?: string
	customerId?: string
	orgUnitId?: string
}

interface RoleDataScope {
	areaId?: string
	countryId?: string
	businessLine?: string
	customerIds?: string[]
	orgUnitId?: string
}

export interface User {
	changePasswordAtNextLogin: boolean
	companyName?: null | string
	country?: null | string
	createdBy?: null | string
	createdOn?: null | string
	creationChannel: unknown
	creationProduct: unknown
	defaultBranding?: null | string
	defaultLocale?: null | string
	defaultTimezone?: number
	eMail?: null | string
	firstName?: null | string
	hasRoles: unknown
	id: string
	isActive: boolean
	isAnalyticsEnabled: boolean
	isBlocked: boolean
	isLockedOut: boolean
	isEmailVerified: boolean
	isInternal: boolean
	isInvited: boolean
	invitedOn: string | null
	invitationProduct: unknown
	invitationProducts?: unknown
	isMobileNumberVerified: boolean
	isPasswordChangeRequired: boolean
	isPushNotificationsEnabled: boolean
	isTermsApprovalRequired: boolean
	isTester: boolean
	jobTitle?: null | string
	lastLoginDate?: null | string
	lastName?: null | string
	mobileDeviceToken?: null | string
	mobileDeviceTokens: { token: string; updatedAt: string }[]
	mobileNumber?: null | string
	modifiedBy?: null | string
	modifiedOn?: null | string
	marketId?: null | string
	name?: null | string
	primaryOrganisationalUnit?: null | string
	region?: null | string
	subscriptions?: unknown
	username: string
	requestedCustomerNumber?: null | string
	user_id: string
	lastActivityDates?: unknown
	termsAcceptedOn?: Date | string
	termsAcceptedVersion?: string
	dataPrivacyAcknowledgedVersion?: string
	dataPrivacyAcknowledgedOn?: Date | string
	failedLogin: boolean
	twoFactorEnabled?: boolean
}

export enum PermissionType {
	ViewContracts = "VIEW_CONTRACTS",
	ViewCertificates = "VIEW_CARBON_CERTIFICATES",
	ManageCertiticates = "MANAGE_CARBON_CERTIFICATES",
	ViewTransactions = "VIEW_TRANSACTIONS",
	ManageTransactions = "MANAGE_TRANSACTIONS",
	ViewSavings = "VIEW_SAVINGS",
	ManageSavings = "MANAGE_SAVINGS",
}

export interface UserPermission {
	userId: string
	connector: string
	permissionType: PermissionType
	dataScope: PermissionDataScope
	expiryDate?: Date
}

export enum RoleType {
	CarbonBankAdmin = "CARBON_BANK_ADMIN",
	CarbonBankSavingAssurer = "CARBON_BANK_SAVING_ASSURER",
	CarbonBankAllocationAssurer = "CARBON_BANK_ALLOCATION_ASSURER",
	CarbonBankCustomer = "CARBON_BANK_CUSTOMER",
	CarbonCertificateOwner = "CARBON_CERTIFICATE_OWNER",
}

export interface UserRole {
	id: number
	userId: string
	dataScope: RoleDataScope
	roleType: RoleType
	expiryDate?: Date
	autoGenerated: boolean
	roleRequestId?: string
	isInternal: boolean
}
