import {
	ArcElement,
	CategoryScale,
	Chart,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	TimeScale,
	Title,
	Tooltip,
} from "chart.js"
import "chartjs-adapter-moment"

export const initChartJs = () => {
	Chart.register(
		CategoryScale,
		LinearScale,
		TimeScale,
		PointElement,
		LineElement,
		ArcElement,
		Title,
		Tooltip,
		Legend,
	)
}
