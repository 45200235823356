import { LoadingPage } from "@carbonbank/components"
import { useAuthStore } from "@carbonbank/store"
import React, { useEffect, useState } from "react"

type AuthProviderProps = {
	children: React.ReactNode
}
const AuthProvider = ({ children }: AuthProviderProps) => {
	const [isLoading, setLoading] = useState(true)

	const checkLoginState = useAuthStore(store => store.checkLoginState)

	useEffect(() => {
		setLoading(true)

		checkLoginState().finally(() => setLoading(false))
	}, [])

	return <>{isLoading ? <LoadingPage /> : children}</>
}

export default AuthProvider
