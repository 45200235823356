import { useAuthStore } from "@carbonbank/store"
import { Button, CardHeader, DetailsCard } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

export const AccountLoginCard = () => {
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactions.components.accountLogin",
	})

	const [login] = useAuthStore(state => [state.login])

	const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		await login()
	}

	return (
		<DetailsCard data-cy="login-transaction-card">
			<CardHeader>{t("accountLoginHeader")}</CardHeader>
			<Button className="w-full" onClick={handleLogin}>
				{t("accountLoginButton")}
			</Button>
		</DetailsCard>
	)
}
