import { Yup } from "@carbonbank/yup"
import { useTranslation } from "react-i18next"

export const useValidationSchema = () => {
	const { t } = useTranslation("cb", { keyPrefix: "common" })
	return Yup.object().shape({
		changedAmount: Yup.number()
			.typeError(t("validation.positive"))
			.positive(t("validation.positive"))
			.required(t("validation.required")),
		comment: Yup.string().required(t("validation.required")),
		confirmed: Yup.boolean().requireChecked(),
	})
}
