import { commentsClient } from "../client"
import { generateGetUrl } from "../utils"
import { Comment, CreateCommentRequest, GetCommentsRequest } from "./types"

export const commentsApi = {
	getComments: (params: GetCommentsRequest): Promise<Comment[]> =>
		commentsClient
			.get(generateGetUrl("/comments", { ...params }))
			.then(response => response.data),
	createComment: (comment: CreateCommentRequest): Promise<number> =>
		commentsClient
			.post("/comments", comment)
			.then(response => response.data),
	updateComment: (comment: Comment): Promise<Comment> =>
		commentsClient
			.put(`/comments/${comment.id}`, comment)
			.then(response => response.data),
	deleteComment: (id: number): Promise<void> =>
		commentsClient
			.delete(`/comments/${id}`)
			.then(response => response.data),
}
