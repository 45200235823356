import { BufferAdjustmentData } from "@carbonbank/api"
import { formatNumber } from "@carbonbank/utils"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	Kpi,
	icon,
	DetailsCard,
	DetailsCardHeader,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface BufferAdjustmentModalProps {
	isOpen: boolean
	onClose: () => void
	dataYear: number
	data?: BufferAdjustmentData
}

export const BufferAdjustmentModal: React.FC<BufferAdjustmentModalProps> = ({
	isOpen,
	onClose,
	data,
	dataYear,
}: BufferAdjustmentModalProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.components.bufferAdjustmentModal",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	const { t: tFuel } = useTranslation("cb", {
		keyPrefix: "common.fuel",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	if (!data) {
		return
	}

	const { labReportUrl, from, to } = data

	const bufferAdjustmentUnit = tUnits("kg")

	return (
		<Modal
			data-cy="buffer-adjustment-modal"
			open={isOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="card-title">
				{t("title", { year: dataYear })}
			</ModalTitle>
			<ModalContent>
				{t("description")}
				<div className="grid grid-cols-2 gap-6 mt-3">
					<DetailsCard data-cy="fuel-estimated">
						<DetailsCardHeader data-cy="title">
							{t("titleEstimated")}
						</DetailsCardHeader>
						<div className="flex flex-col gap-y-3">
							<Kpi
								size="xsmall"
								data-cy="estimated-biogenic"
								label={tFuel("biogenic")}
								value={Math.floor(
									(from.biogenic / from.total) * 100,
								)}
								unit="%"
							/>
							<Kpi
								size="xsmall"
								data-cy="estimated-fossil"
								label={tFuel("fossil")}
								value={Math.ceil(
									(from.fossil / from.total) * 100,
								)}
								unit="%"
							/>
						</div>
					</DetailsCard>
					<DetailsCard data-cy="fuel-actual">
						<DetailsCardHeader data-cy="title">
							{t("titleActual")}
						</DetailsCardHeader>
						<div className="flex flex-col gap-y-3">
							<Kpi
								size="xsmall"
								data-cy="actual-biogenic"
								label={tFuel("biogenic")}
								value={Math.floor(
									(to.biogenic / to.total) * 100,
								)}
								unit="%"
							/>
							<Kpi
								size="xsmall"
								data-cy="actual-fossil"
								label={tFuel("fossil")}
								value={Math.ceil((to.fossil / to.total) * 100)}
								unit="%"
							/>
						</div>
					</DetailsCard>
					<DetailsCard
						data-cy="previous-buffer"
						className="col-span-2"
					>
						<DetailsCardHeader data-cy="title">
							{t("titleCompositionPrevious")}
						</DetailsCardHeader>
						<div className="flex gap-x-6">
							<Kpi
								data-cy="fuel-total"
								size="small"
								label={tFuel("total")}
								value={formatNumber(from.total)}
								unit={bufferAdjustmentUnit}
							/>
							<Kpi
								data-cy="fuel-biogenic"
								size="small"
								label={tFuel("biogenic")}
								value={formatNumber(from.biogenic)}
								unit={bufferAdjustmentUnit}
							/>
							<Kpi
								data-cy="fuel-fossil"
								size="small"
								label={tFuel("fossil")}
								value={formatNumber(from.fossil)}
								unit={bufferAdjustmentUnit}
							/>
						</div>
					</DetailsCard>
					<DetailsCard
						data-cy="adjusted-buffer"
						className="col-span-2"
					>
						<DetailsCardHeader data-cy="title">
							{t("titleCompositionAdjusted")}
						</DetailsCardHeader>
						<div className="flex gap-x-6">
							<Kpi
								data-cy="fuel-total"
								size="small"
								label={tFuel("total")}
								value={formatNumber(to.total)}
								unit={bufferAdjustmentUnit}
							/>
							<Kpi
								data-cy="fuel-biogenic"
								size="small"
								label={tFuel("biogenic")}
								value={formatNumber(to.biogenic)}
								unit={bufferAdjustmentUnit}
							/>
							<Kpi
								data-cy="fuel-fossil"
								size="small"
								label={tFuel("fossil")}
								value={formatNumber(to.fossil)}
								unit={bufferAdjustmentUnit}
							/>
						</div>
					</DetailsCard>
				</div>
			</ModalContent>
			<ModalFooter justifyClass="justify-between">
				<a
					data-cy="lab-reports"
					href={labReportUrl}
					target="_blank"
					rel="noreferrer"
				>
					<Button variant="secondary" icon={icon.mdiOpenInNew}>
						{t("labReportButtonLabel")}
					</Button>
				</a>
				<Button data-cy="close" variant="primary" onClick={onClose}>
					{tCommon("close")}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
