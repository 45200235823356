﻿import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const TransactionVerificationSection = (
	props: {
		verificationNumber: string
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_20 }}>
			<Text style={{ ...styles.text_regular }}>
				To verify the transaction and confirm your ownership, locate the
				&quot;Verify transaction&quot; button located at the top right
				of your screen. Clicking on this button will prompt you to enter
				the required validation credentials. Once you have entered the
				necessary information, click on the &quot;Verify&quot; button.
				This will initiate the validation process for the transaction,
				ensuring that the ownership is securely verified and validated.
			</Text>
			<Text>
				<Text style={{ ...styles.text_regular }}>
					Certificate Verification ID:
				</Text>
				<Text
					style={{
						...styles.text_regular_bold,
						...styles.text_color_grey,
					}}
				>
					&nbsp;{props.verificationNumber}
				</Text>
			</Text>
		</View>
	)
}
