import { formatNumberWithUnit, UnitType } from "@carbonbank/utils"
import {
	ModalContent,
	TextRegular,
	Button,
	ModalFooter,
	DetailsCard,
	Label,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface StepAssuredProps {
	transactionId: string | number
	bufferBalance: number
	onClose: () => void
}

export const StepAssured: React.FC<StepAssuredProps> = ({
	transactionId,
	bufferBalance,
	onClose,
}: StepAssuredProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureBalanceModal.assuringShared",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})

	// TODO: Now we got empty transactionId, remove stub (238218377821789371) after implementation
	return (
		<>
			<ModalContent data-cy="step-assure">
				<div className="flex flex-col gap-y-6">
					<TextRegular
						data-cy="assured-description"
						className="text-foreground_faded"
					>
						{t("descrpitionAssured")}
					</TextRegular>
					<DetailsCard>
						<div className="flex gap-x-6">
							<Label
								data-cy="transaction-id"
								title={t("transactionIdLabel")}
							>
								{transactionId || "238218377821789371"}
							</Label>
							<Label
								data-cy="released-buffer"
								title={t("releaseBufferLabel")}
							>
								{formatNumberWithUnit(
									bufferBalance,
									UnitType.KgCO2,
								)}
							</Label>
						</div>
					</DetailsCard>
				</div>
			</ModalContent>
			<ModalFooter>
				<Button
					data-cy="cancel"
					type="button"
					variant="secondary"
					onClick={onClose}
				>
					{tCommon("close")}
				</Button>
			</ModalFooter>
		</>
	)
}
