import {
	CarbonSourceType,
	TransactionOverviewData,
	YearlySavingsChartData,
} from "@carbonbank/api"
import { formatNumber } from "@carbonbank/utils"
import {
	carbonSourceTypeToBgClassNameMap,
	carbonSourceTypeToChartLineColorVariableMap,
	carbonSourceTypeToTitleTypeMap,
} from "@carbonbank/utils/carbonSourseUtils"
import {
	DashboardCard,
	CardHeader,
	Kpi,
	LabelRegular,
} from "@sustainability/fundamental"
import { useLayoutEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

interface BankBalanceCardProps {
	dataYear: number
	chartData: YearlySavingsChartData[]
	savingsStats: TransactionOverviewData
	carbonSourceType: CarbonSourceType
}

export const BankBalanceCard: React.FC<BankBalanceCardProps> = ({
	dataYear,
	chartData,
	savingsStats: { bankBalance, totalDeposits, totalWithdraws, currentBuffer },
	carbonSourceType,
}: BankBalanceCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.components.bankBalanceCard",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const [chartLineColor, setChartLineColor] = useState("#ffffff")

	useLayoutEffect(() => {
		const chartLineColorVariable =
			carbonSourceTypeToChartLineColorVariableMap[carbonSourceType]
		const chartLineColor = getComputedStyle(document.body).getPropertyValue(
			chartLineColorVariable,
		)

		setChartLineColor(chartLineColor)
	}, [])

	const options: React.ComponentProps<typeof Line>["options"] = {
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				type: "time",
				time: {
					unit: "month",
				},
			},
		},
	}

	const data = {
		datasets: [
			{
				borderColor: chartLineColor,
				backgroundColor: chartLineColor,
				pointRadius: 0,
				borderWidth: 1,
				fill: false,
				stepped: true,
				data: chartData,
			},
		],
	}

	const balanceUnit = tUnits("kgCO2")

	const carbonSourceTitleType =
		carbonSourceTypeToTitleTypeMap[carbonSourceType]
	const carbonSourceBgClassName =
		carbonSourceTypeToBgClassNameMap[carbonSourceType]

	const cardTitle = t(`titles.${carbonSourceTitleType}`, { year: dataYear })
	const chartLabel = t(`chartLabels.${carbonSourceTitleType}`)

	const legendMarkerClassNames = `w-12px h-12px inline-block align-middle rounded-full ${carbonSourceBgClassName} mr-8px`

	return (
		<DashboardCard data-cy="bank-balance">
			<CardHeader
				data-cy="title"
				aside={
					<div className="flex gap-6">
						<Kpi
							data-cy="stats-bank-balance"
							size="small"
							label={t("bankBalance")}
							value={formatNumber(bankBalance)}
							unit={balanceUnit}
						/>
						<Kpi
							data-cy="stats-deposits"
							size="small"
							label={t("deposits")}
							value={formatNumber(totalDeposits.amount)}
							unit={balanceUnit}
						/>
						<Kpi
							data-cy="stats-withdraws"
							size="small"
							label={t("withdraws")}
							value={formatNumber(totalWithdraws.amount)}
							unit={balanceUnit}
						/>
						<Kpi
							data-cy="stats-buffer"
							size="small"
							label={t("buffer")}
							value={formatNumber(currentBuffer)}
							unit={balanceUnit}
						/>
					</div>
				}
			>
				{cardTitle}
			</CardHeader>
			<div style={{ height: "215px" }}>
				<Line data-cy="chart" options={options} data={data} />
			</div>
			<div data-cy="chart-legend">
				<div
					data-cy="chart-legend-marker"
					className={legendMarkerClassNames}
				/>
				<LabelRegular className="text-foreground_faded">
					{chartLabel}
				</LabelRegular>
			</div>
		</DashboardCard>
	)
}
