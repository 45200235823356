import { Modal, ModalTitle } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { StepAssured } from "./StepAssured"

interface AssureBalanceDoneModalProps {
	year: string | number
	transactionId: string
	bufferBalance: number
	isOpen: boolean
	onClose: () => void
}

export const AssureBalanceDoneModal: React.FC<AssureBalanceDoneModalProps> = ({
	year,
	transactionId,
	bufferBalance,
	isOpen,
	onClose,
}: AssureBalanceDoneModalProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureBalanceModal",
	})

	return (
		<Modal
			data-cy="assure-balance-done-modal"
			open={isOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="title">{t("title", { year })}</ModalTitle>
			<div className="min-h-[454px] grid grid-rows-[1fr_auto]">
				<StepAssured
					bufferBalance={bufferBalance}
					transactionId={transactionId}
					onClose={onClose}
				/>
			</div>
		</Modal>
	)
}
