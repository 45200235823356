import { Transfer, TransferValidationForm } from "@carbonbank/api"
import {
	CertificateScenarioVariant,
	useCertificateValidation,
} from "@carbonbank/store"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	Input,
	ButtonVariant,
	Loading,
} from "@sustainability/fundamental"
import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

interface CertificatesValidationModalProps {
	proof?: Transfer
}
export const CertificatesValidationModal: React.FC<
	CertificatesValidationModalProps
> = ({ proof }) => {
	const { t } = useTranslation("contracts", {
		keyPrefix:
			"certificatesValidation.components.certificatesValidationModal",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	const [
		isModalOpen,
		scenario,
		closeModal,
		submitData,
		transferId,
		fetchCertificatesValidation,
	] = useCertificateValidation(state => [
		state.isModalOpen,
		state.getScenario(),
		state.closeModal,
		state.submitData,
		state.transferId,
		state.fetchCertificatesValidation,
	])
	const scenarioToButtonPropsMap: Record<
		CertificateScenarioVariant,
		[CertificateScenarioVariant, ButtonVariant]
	> = {
		approve: [tCommon("approve") as CertificateScenarioVariant, "primary"],
		reject: [tCommon("reject") as CertificateScenarioVariant, "negative"],
	}
	const [submitButtonLabel, submitButtonVariant] =
		scenarioToButtonPropsMap[scenario]
	const initialFormState = {
		transferId: transferId,
		status: scenario === "approve" ? "Accepted" : "Rejected",
		rejectionReason: "",
	}
	const validationSchema =
		scenario === "reject"
			? Yup.object().shape({
					rejectionReason: Yup.string().required(
						t("rejectModalReason"),
					),
				})
			: Yup.object().shape({})

	const onFormSubmit = async (values: TransferValidationForm) => {
		await submitData(values)
		await fetchCertificatesValidation({ excludeFullTransfers: true })
	}

	return (
		<Modal
			data-cy="validate-certificate-modal"
			open={isModalOpen}
			onClose={closeModal}
		>
			<ModalTitle>{t(`title.${scenario}`)}</ModalTitle>
			<Formik
				initialValues={initialFormState}
				onSubmit={onFormSubmit}
				validationSchema={validationSchema}
				enableReinitialize={true}
			>
				{({ isSubmitting, isValid, dirty }) => (
					<Form>
						<ModalContent>
							<div className="flex flex-col gap-6">
								<TextRegular>
									{t("text.modalText1")}
									<span className="font-bold">
										{proof?.customerName}/
										{proof?.contractNumber}
									</span>
									{t("text.modalText2")}
								</TextRegular>
								{scenario === "reject" && (
									<Input
										data-cy="reject-reason-input"
										label={t("rejectModalReason")}
										name="rejectionReason"
									/>
								)}
							</div>
						</ModalContent>
						<ModalFooter>
							<div className="flex gap-3">
								<Button
									data-cy="approve-tansfer-modal-actions-cancel"
									size="large"
									variant="secondary"
									onClick={closeModal}
									type="button"
								>
									{tCommon("cancel")}
								</Button>

								<Button
									data-cy="assure-savings-modal-actions-assure"
									size="large"
									variant={submitButtonVariant}
									disabled={
										scenario === "reject"
											? isSubmitting || !isValid || !dirty
											: isSubmitting
									}
								>
									{isSubmitting ? (
										<Loading
											size="small"
											variant="secondary"
										/>
									) : (
										submitButtonLabel
									)}
								</Button>
							</div>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
