import { RoleType } from "@carbonbank/api"
import { useAuthStore, useFilterStore } from "@carbonbank/store"
import {
	Avatar,
	Gavel,
	LabelRegular,
	MenuItemConfig,
	UserMenu,
	icon,
} from "@sustainability/fundamental"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

export const Profile = () => {
	const { t } = useTranslation("cb", {
		keyPrefix: "layout.userMenu",
	})
	const [loginState, userRoles, login, logout] = useAuthStore(store => [
		store.loginState,
		store.userRoles,
		store.login,
		store.logout,
	])

	const [customerFilterData, fetchCustomerFilterData] = useFilterStore(
		store => [store.customerFilterData, store.fetchCustomerFilterData],
	)

	const [languageCode, setLanguageCode] = useState("English")

	useEffect(() => {
		const shouldFetchCustomerAccounts =
			loginState.loggedIn &&
			userRoles
				?.map(x => x.roleType)
				.includes(RoleType.CarbonBankCustomer)

		if (shouldFetchCustomerAccounts) {
			fetchCustomerFilterData()
		}
	}, [loginState.loggedIn, userRoles])

	const carbonBankCustomerItems = useMemo(() => {
		if (!customerFilterData) {
			return []
		}

		const formattedUserAccounts = customerFilterData.map(
			x => `${x.customerNumber} - ${x.customerName}`,
		)

		const accountsItem: MenuItemConfig = {
			title: t("accounts"),
			icon: icon.mdiAccountCircleOutline,
			subtitle: formattedUserAccounts,
			subtitleClassname:
				"whitespace-nowrap overflow-hidden text-ellipsis",
		}

		return [accountsItem]
	}, [customerFilterData])

	const commonItems: MenuItemConfig[] = useMemo(
		() => [
			{
				title: t("about.title"),
				icon: icon.mdiInformationOutline,
				items: [
					{
						title: t("about.termsAndConditions"),
						customIcon: <Gavel />,
						onClicked: () => {
							window.location.href =
								"https://www.heidelbergmaterials.com/terms-and-conditions"
						},
					},
					{
						title: t("about.privacyPolicy"),
						icon: icon.mdiShieldAlertOutline,
					},
					{
						title: t("about.openSourceAttribution"),
						icon: icon.mdiClipboardTextOutline,
					},
					{
						title: t("about.imprint"),
						icon: icon.mdiCopyright,
					},
				],
			},
			{
				title: t("language"),
				icon: icon.mdiTranslate,
				type: "select",
				onClicked: item => setLanguageCode(item.title),
				selectedItem: languageCode,
				items: [
					{
						title: "English",
						icon: icon.mdiAccountCircleOutline,
					},
					{
						title: "German",
						icon: icon.mdiAccountCircleOutline,
					},
				],
			},
		],
		[languageCode],
	)

	const loggedInItems: MenuItemConfig[] = useMemo(
		() => [
			{
				title: loginState.decodedToken?.name ?? "",
				customIcon: (
					<Avatar name={loginState.decodedToken?.name ?? ""} />
				),
				subtitle: loginState.decodedToken?.email ?? "",
			},
			...carbonBankCustomerItems,
			{
				title: t("subscribeToFeeback"),
				icon: icon.mdiEmailMarkAsUnread,
			},
			...commonItems,
			{
				title: t("logout"),
				icon: icon.mdiLogout,
				iconClasses: "text-red-400",
				onClicked: logout,
			},
		],
		[carbonBankCustomerItems, commonItems, loginState.loggedIn],
	)

	const loggedOutItems: MenuItemConfig[] = useMemo(
		() => [
			...commonItems,
			{
				title: t("login"),
				icon: icon.mdiLogin,
				iconClasses: "text-red-400",
				onClicked: login,
			},
		],
		[commonItems, loginState.loggedIn],
	)

	return (
		<UserMenu
			loggedIn={loginState.loggedIn}
			items={loginState.loggedIn ? loggedInItems : loggedOutItems}
			footer={
				<div className="flex flex-col items-center">
					<LabelRegular className="text-foreground_faded">
						©2024 HeidelbergMaterials. All rights reserved.
					</LabelRegular>
					<LabelRegular className="text-foreground_faded">
						<a href="heidelbergmaterials.com">Imprint</a>
					</LabelRegular>
				</div>
			}
		/>
	)
}
