import { Cycle } from "@carbonbank/api"
import { KpiStats } from "@carbonbank/components"
import { formatDate, formatNumberWithUnit } from "@carbonbank/utils"
import { Kpi } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { cycleStatusToLabelMap } from "../constants"

interface CycleInformationsProps {
	cycle: Cycle
}

export const CycleInformations: React.FC<CycleInformationsProps> = ({
	cycle: {
		cycle,
		shipmentDate,
		plant,
		status,
		totalSavings,
		assuranceTimestamp,
		buffer,
	},
}: CycleInformationsProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavingsDetails.components.cycleInformation",
	})

	const { t: tCommon } = useTranslation("cb")

	const isLCO2Verified = totalSavings && assuranceTimestamp && buffer

	return (
		<KpiStats data-cy="saving-details">
			<Kpi
				data-cy="cycle-id"
				size="small"
				label={t("cycleId")}
				value={`#${cycle}`}
			/>
			<Kpi
				data-cy="shipment-date"
				size="small"
				label={t("shipmentDate")}
				value={formatDate(shipmentDate)}
			/>
			<Kpi
				data-cy="plant"
				size="small"
				label={t("plant")}
				value={plant}
			/>
			<Kpi
				data-cy="status"
				size="small"
				label={t("status")}
				value={tCommon(cycleStatusToLabelMap[status])}
			/>
			{isLCO2Verified && (
				<Kpi
					data-cy="lco2-buffer"
					size="small"
					label={t("lco2Assured", {
						date: formatDate(assuranceTimestamp),
					})}
					value={`${formatNumberWithUnit(totalSavings)} (${formatNumberWithUnit(buffer)})`}
				/>
			)}
		</KpiStats>
	)
}
