import { BalanceAdjustmentData } from "@carbonbank/api"
import { formatNumber } from "@carbonbank/utils"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	Kpi,
	Label,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface BalanceAdjustmentModalProps {
	isOpen: boolean
	onClose: () => void
	data: BalanceAdjustmentData
}

export const BalanceAdjustmentModal: React.FC<BalanceAdjustmentModalProps> = ({
	isOpen,
	onClose,
	data: { carbonSavings: adjustment, details: comment, action: name },
}: BalanceAdjustmentModalProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.components.balanceAdjustmentModal",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const balanceAdjustmentUnit = tUnits("kg")

	return (
		<Modal
			data-cy="balance-adjustment-modal"
			open={isOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="title">{t("title")}</ModalTitle>
			<ModalContent>
				<div className="flex flex-col gap-6">
					<div className="flex flex-wrap gap-6">
						<Kpi
							data-cy="adjustment"
							size="small"
							label={t("adjustment")}
							value={formatNumber(adjustment.amount)}
							unit={balanceAdjustmentUnit}
						/>
						<Kpi
							data-cy="author"
							size="small"
							label={t("author")}
							value={name}
						/>
					</div>
					<div>
						<Label data-cy="comment" title={t("comment")}>
							{comment}
						</Label>
					</div>
				</div>
			</ModalContent>
			<ModalFooter>
				<Button data-cy="close" variant="primary" onClick={onClose}>
					{tCommon("close")}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
