import { TransactionType } from "@carbonbank/api"
import { Pill } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface TransactionTypePillProps {
	transactionType: TransactionType
}

export const TransactionTypePill: React.FC<TransactionTypePillProps> = ({
	transactionType,
}: TransactionTypePillProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.components.transactionTypePill",
	})
	const transactionTypeToPillPropsMap: Record<
		TransactionType,
		Pick<React.ComponentProps<typeof Pill>, "text" | "type">
	> = {
		[TransactionType.Deposit]: {
			text: t("deposit"),
			type: "positive",
		},
		[TransactionType.Withdrawal]: {
			text: t("withdrawal"),
			type: "negative",
		},
		[TransactionType.BufferRelease]: {
			text: t("bufferRelease"),
			type: "positive",
		},
		[TransactionType.BufferAdjustment]: {
			text: t("bufferAdjustment"),
			type: "neutral",
		},
		[TransactionType.BalanceAdjustment]: {
			text: t("balanceAdjustment"),
			type: "positive",
		},
		[TransactionType.BufferDeposit]: {
			text: t("bufferDeposit"),
			type: "neutral",
		},
	}

	return (
		<Pill
			{...transactionTypeToPillPropsMap[transactionType]}
			size="large"
		/>
	)
}
