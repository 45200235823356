import { carbonBankClient, carbonBankWithAuthClient } from "../client"
import {
	VerificationPayload,
	VerificationDetails,
	CustomerDetails,
	ProjectData,
} from "./types"

export const certificateApi = {
	verify: (payload: VerificationPayload): Promise<VerificationDetails> =>
		carbonBankClient
			.post(`/certificates/verification`, payload)
			.then(response => response.data),
	getBuildingProjectInformation: (id: string): Promise<CustomerDetails> =>
		carbonBankWithAuthClient
			.get(`/certificates/building/${id}`)
			.then(response => response.data),
	createProject: (
		projectData: ProjectData,
		imageFile: File,
	): Promise<void> => {
		const formData = new FormData()
		formData.append("imageFile", imageFile)
		formData.append("buildingDetailsJson", JSON.stringify(projectData))

		return carbonBankWithAuthClient.post(
			"/certificates/building",
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		)
	},
}
