import { Contract } from "@carbonbank/api"
import { LogoEvoZero } from "@carbonbank/assets"
import { CardHeader, DashboardCard, Label } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface DeclaredProductProps {
	data?: Contract
}
export const DeclaredProduct: React.FC<DeclaredProductProps> = ({ data }) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.declaredProduct",
	})
	return (
		<DashboardCard className="self-stretch">
			<CardHeader wrapAside className="!text-wrap">
				{t("title")}
			</CardHeader>
			<div className="flex flex-col gap-y-6">
				<div className="flex items-center gap-6 flex-wrap">
					<Label title={t("declaredProduct")}>
						{data?.declaredProduct}
					</Label>
					<img
						src={LogoEvoZero}
						alt="evezero"
						className="w-[110px] h-[15px]"
					/>
				</div>
				<Label title={t("originCarbon")}>
					{data?.originDeclaredProduct}
				</Label>
			</div>
		</DashboardCard>
	)
}
