import { CustomerDetails } from "@carbonbank/api"
import { H3, Label } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

import { CustomerKeys } from "../types"

interface CertificateInformationProps {
	data?: CustomerDetails
}
const useTypedTranslation = () => {
	const { t } = useTranslation("customer", {
		keyPrefix: "customer",
	})
	return (key: CustomerKeys) => t(key)
}
export const CertificateInformation: React.FC<CertificateInformationProps> = ({
	data,
}) => {
	const t = useTypedTranslation()

	return (
		<div
			className="flex flex-col gap-3"
			data-cy="customer-certificate-information-component"
		>
			<H3>{t("title")}</H3>
			<Label
				title={t("certificateInformation.companyName")}
				className="mt-3"
				data-cy="customer-company-name"
			>
				{data?.recipientDetails.companyName}
			</Label>
			<Label
				title={t("certificateInformation.street")}
				data-cy="customer-street"
			>
				{data?.recipientDetails.street}
			</Label>
			<Label
				title={t("certificateInformation.zipCode")}
				data-cy="customer-zipcode"
			>
				{data?.recipientDetails.postalCode}
			</Label>
			<Label
				title={t("certificateInformation.city")}
				data-cy="customer-city"
			>
				{data?.recipientDetails.city}
			</Label>
			<Label
				title={t("certificateInformation.country")}
				data-cy="customer-country"
			>
				{data?.recipientDetails.country}
			</Label>
		</div>
	)
}
