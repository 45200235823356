import { ContainerBox, LoadingPage } from "@carbonbank/components"
import { useYearlySavingsStore } from "@carbonbank/store"
import {
	Alert,
	Button,
	PageHeader,
	useModal,
} from "@sustainability/fundamental"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { getOverviewUrl } from "../carbonSavingsRoutes"
import { AssureBalanceModal } from "../components"
import { BufferBalanceCard, EtsReportCard } from "./components"

export const DnvYearlyAssuranceReport: React.FC = () => {
	const { year, location } = useParams()
	const navigate = useNavigate()

	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssuranceReport.page",
	})

	const { isOpen, openModal, closeModal } = useModal(false)

	const [reportData, fetchReportData] = useYearlySavingsStore(s => [
		s.reportData,
		s.fetchReportData,
	])

	useEffect(() => {
		if (!year || !location) {
			return navigate("/")
		}

		fetchReportData(year, location)
	}, [year, location])

	if (!reportData) {
		return (
			<LoadingPage
				backToPageUrlOnClick={() => navigate(getOverviewUrl())}
				backToPageLabel={t("backToPageLabel")}
				title={t("title", { year })}
			/>
		)
	}

	return (
		<ContainerBox withBottomMargin>
			<PageHeader
				data-cy="page-title"
				backToPageLabel={t("backToPageLabel")}
				backToPageUrlOnClick={() => navigate(getOverviewUrl())}
				aside={
					<Button
						data-cy="action-button"
						variant="primary"
						size="large"
						disabled={!reportData.totalDepositsFromEtsReport}
						onClick={openModal}
					>
						{t("assureYearlyBalanceLabel")}
					</Button>
				}
			>
				{t("title", { year })}
			</PageHeader>
			<div className="flex flex-col gap-y-6">
				{reportData.totalDepositsFromEtsReport ? (
					<EtsReportCard
						totalDeposits={reportData.totalDeposits.amount}
						productionEtsReportUrl={
							reportData.productionEtsReportUrl
						}
						storageEtsReportUrl={reportData.storageEtsReportUrl}
						totalDepositsFromEtsReport={
							reportData.totalDepositsFromEtsReport.amount
						}
					/>
				) : (
					<Alert data-cy="report-not-available" variant="error">
						{t("reportNotAvailable")}
					</Alert>
				)}
				<BufferBalanceCard year={year!} report={reportData} />
			</div>

			<AssureBalanceModal
				isOpen={isOpen}
				onClose={closeModal}
				year={year!}
				location={location!}
				bufferBalance={
					reportData.bufferDepositsRow.carbonSavings.amount +
					reportData.totalDepositsFromEtsReport.amount -
					reportData.totalDeposits.amount
				}
			/>
		</ContainerBox>
	)
}
