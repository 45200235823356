import axios, { AxiosRequestConfig } from "axios"

export const hconnectUrl = import.meta.env.VITE_AUTH_HCONNECT

const axiosConfig: AxiosRequestConfig = {
	baseURL: hconnectUrl,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
}

export const hconnectClient = axios.create(axiosConfig)
