import { RouteObject } from "react-router-dom"

import { CarbonSavings } from "./CarbonSavings"
import { CarbonSavingsDetails } from "./CarbonSavingsDetails"
import { DnvProductAllocation } from "./DnvProductAllocation"
import { DnvProductAllocationDetails } from "./DnvProductAllocationDetails"
import { DnvYearlyAssurance } from "./DnvYearlyAssurance"
import { DnvYearlyAssuranceReport } from "./DnvYearlyAssuranceReport"
import { Layout } from "./Layout"

const baseUrl = "/carbon-savings"

export const carbonSavingsRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <DnvYearlyAssurance />,
			},
			{
				path: ":year/:location/report",
				element: <DnvYearlyAssuranceReport />,
			},
			{
				path: "assure-savings",
				element: <CarbonSavings />,
			},
			{
				path: "assure-savings/:id",
				element: <CarbonSavingsDetails />,
			},
			{
				path: "product-allocation",
				element: <DnvProductAllocation />,
			},
			{
				path: "product-allocation/:id",
				element: <DnvProductAllocationDetails />,
			},
		],
	},
]

export const getOverviewUrl = () => baseUrl
export const getAssureSavingsUrl = () => `${baseUrl}/assure-savings`
export const getAssureSavingDetailsUrl = (id: string | number) =>
	`${baseUrl}/assure-savings/${id}`
export const getAssureYearlyBalanceReportUrl = (
	year: string | number,
	location: string,
) => `${baseUrl}/${year}/${location}/report`
export const getProductAllocationUrl = () => `${baseUrl}/product-allocation`
export const getTransactionDetailsCarbonSavingUrl = (id: string | number) =>
	`${baseUrl}/product-allocation/${id}`
