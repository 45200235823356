import { ContainerBox } from "@carbonbank/components"
import { H1 } from "@sustainability/fundamental"

export const Forbidden: React.FC = () => {
	return (
		<ContainerBox withBottomMargin data-cy="forbidden-page">
			<H1>Forbidden</H1>
		</ContainerBox>
	)
}
