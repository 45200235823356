export const getStorageItemByName = <T>(itemName: string): T | undefined => {
	const item = localStorage.getItem(itemName)

	return item ? JSON.parse(item) : undefined
}

export const setStorageItem = (itemName: string, itemData: unknown) => {
	if (!itemName) {
		return
	}

	const itemJson = JSON.stringify(itemData)
	localStorage.setItem(itemName, itemJson)
}

export const removeStorageItemByName = (itemName: string) => {
	localStorage.removeItem(itemName)
}
