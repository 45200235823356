import { useCommentsStore } from "@carbonbank/store"
import {
	CardHeader,
	DashboardCard,
	CommentBox,
	CommentProps,
	FormFields,
} from "@sustainability/fundamental"
import { FormikHelpers } from "formik"
import { useTranslation } from "react-i18next"

interface CommentBoxProps {
	comments: CommentProps[]
	onSubmit: (
		fields: FormFields,
		helpers: FormikHelpers<FormFields>,
	) => Promise<void>
}

export const CommentBoxCard: React.FC<CommentBoxProps> = ({
	comments,
	onSubmit,
}: CommentBoxProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavingsDetails.components.commentsCard",
	})

	const [updateComment, deleteComment] = useCommentsStore(state => [
		state.updateComment,
		state.deleteComment,
	])

	return (
		<DashboardCard data-cy="comment-box">
			<CardHeader data-cy="title">{t("title")}</CardHeader>
			<CommentBox
				data-cy="comment-component"
				comments={comments}
				onSubmit={onSubmit}
				onEdit={updateComment}
				onDelete={deleteComment}
			/>
		</DashboardCard>
	)
}
