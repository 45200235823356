import { carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import { Contract, ContractSearchParams, ContractShortView } from "./types"

export const contractsApi = {
	getContracts: (
		params: ContractSearchParams,
	): Promise<ContractShortView[]> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/contracts", { ...params }))
			.then(response => response.data),
	getContractById: (id: string): Promise<Contract> =>
		carbonBankWithAuthClient
			.get(`/contracts/${id}`)
			.then(response => response.data),
}
