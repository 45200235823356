import { Loading, PageHeader } from "@sustainability/fundamental"

import { ContainerBox } from "../ContainerBox"

interface LoadingPageProps {
	title?: string
	backToPageUrlOnClick?: React.ComponentProps<
		typeof PageHeader
	>["backToPageUrlOnClick"]
	backToPageLabel?: React.ComponentProps<typeof PageHeader>["backToPageLabel"]
	noContainerBox?: boolean
}

export const LoadingPage: React.FC<LoadingPageProps> = ({
	title,
	backToPageUrlOnClick,
	backToPageLabel,
	noContainerBox = false,
}: LoadingPageProps) => {
	const Wrapper = noContainerBox ? "div" : ContainerBox

	return (
		<Wrapper data-cy="loading-page">
			<PageHeader
				backToPageUrlOnClick={backToPageUrlOnClick}
				backToPageLabel={backToPageLabel}
			>
				{title}
			</PageHeader>
			<Loading size={"xlarge"} className="block mt-6 mx-auto" />
		</Wrapper>
	)
}
