import { transferApi, TransferStatus } from "@carbonbank/api"
import { CertificatePdfDocument } from "@carbonbank/pdf/certificates/transfer/CertificatePdfDocument.tsx"
import { downloadFile } from "@carbonbank/utils/blobTransformation.ts"
import ReactPDF from "@react-pdf/renderer"
import {
	Button,
	icon,
	Pill,
	spawnErrorToast,
	spawnSuccessToast,
	TableCell,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface CertificateStatusProps {
	transactionId: string
	status: TransferStatus
}

export const CertificateStatus: React.FC<CertificateStatusProps> = ({
	status,
	transactionId,
}) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "certificates",
	})

	const downloadCertificate = async (transactionId: string) => {
		try {
			const pdfData =
				await transferApi.getTransferCertificate(transactionId)
			const pdfBlob = await ReactPDF.pdf(
				<CertificatePdfDocument data={pdfData} />,
			).toBlob()

			downloadFile("certificate.pdf", [pdfBlob], "application/pdf")
			spawnSuccessToast(t("successfullyDownloaded"))
		} catch {
			spawnErrorToast(t("failedToDownload"))
		}
	}

	return (
		<TableCell>
			{
				{
					Pending: (
						<Pill
							variant="shade"
							shape="rounded"
							type="warning"
							text={status}
						/>
					),
					Rejected: (
						<Pill
							variant="shade"
							shape="rounded"
							type="negative"
							text={status}
						/>
					),
					"Not claimed yet": (
						<Pill
							variant="shade"
							shape="rounded"
							type="neutral"
							text={status}
						/>
					),
					Downloadable: (
						<Button
							variant="tertiary"
							icon={icon.mdiDownloadOutline}
							onClick={() => downloadCertificate(transactionId)}
						>
							{t("components.certificatesTable.download")}
						</Button>
					),
				}[status]
			}
		</TableCell>
	)
}
