import { PermissionType, RoleType } from "@carbonbank/api"
import { Forbidden } from "@carbonbank/pages/common/Forbidden/Forbidden"
import { RouteObject } from "react-router-dom"

const commonRoutePaths = {
	home: "/",
	notFound: "/*",
	forbidden: "/forbidden",

	//TODO: must be moved to routePaths when specific permissions are defined
	customer: "/customer/:id",

	microSite: "/microsite/:id",

	transactions: "/transactions",
	transactionDetails: "/transactions/:id",
}

const routePaths = {
	yearlyAssurance: "/carbon-savings",
	yearlyAssuranceReport: "/carbon-savings/:year/:location/report",
	carbonSavings: "/carbon-savings/assure-savings",
	carbonSavingsDetails: "/carbon-savings/assure-savings/:id",
	productAllocation: "/carbon-savings/product-allocation",
	productAllocationDetails: "/carbon-savings/product-allocation/:id",

	contracts: "/customers/contracts",
	contractValidation: "/customers/contracts/validation",
	contractDetails: "/customers/contracts/:id",
	contractCertificates: "/customers/contracts/:id/certificates",
	contractTransfers: "/customers/contracts/:id/transfers",
}

const permissionTypeToRoutes: Record<PermissionType, string[]> = {
	[PermissionType.ViewContracts]: [
		routePaths.contracts,
		routePaths.contractDetails,
		routePaths.contractValidation,
		routePaths.contractTransfers,
	],
	[PermissionType.ViewCertificates]: [routePaths.contractCertificates],
	[PermissionType.ManageCertiticates]: [
		routePaths.contractValidation,
		routePaths.contractTransfers,
	],
	[PermissionType.ViewTransactions]: [
		routePaths.yearlyAssurance,
		routePaths.productAllocation,
		routePaths.productAllocationDetails,
	],
	[PermissionType.ManageTransactions]: [
		routePaths.yearlyAssurance,
		routePaths.yearlyAssuranceReport,
		routePaths.productAllocation,
		routePaths.productAllocationDetails,
	],
	[PermissionType.ViewSavings]: [
		routePaths.carbonSavings,
		routePaths.carbonSavingsDetails,
	],
	[PermissionType.ManageSavings]: [
		routePaths.carbonSavings,
		routePaths.carbonSavingsDetails,
	],
}

const roleTypeToRoutes: Record<RoleType, string> = {
	[RoleType.CarbonBankAdmin]: commonRoutePaths.home,
	[RoleType.CarbonBankAllocationAssurer]: routePaths.yearlyAssurance,
	[RoleType.CarbonBankCustomer]: routePaths.contracts,
	[RoleType.CarbonBankSavingAssurer]: routePaths.carbonSavings,
	[RoleType.CarbonCertificateOwner]: commonRoutePaths.transactions,
}

const isCommonRoutePath = (path: string) => {
	return Object.values(commonRoutePaths).includes(path)
}

const getFullRoutePath = (route: RouteObject, routePathPrefix: string) => {
	const routePath = !route.index && route.path ? route.path : ""
	const fullRoutePathSeparator =
		!routePath || routePathPrefix === commonRoutePaths.home ? "" : "/"
	const fullRoutePath = routePathPrefix
		? `${routePathPrefix}${fullRoutePathSeparator}${routePath}`
		: routePath

	return fullRoutePath
}

const shouldSkipRoute = (
	route: RouteObject,
	routePathPrefix: string,
	fullRoutePath: string,
) => {
	return (
		!routePathPrefix ||
		(!route.path && !route.index) ||
		!route.element ||
		isCommonRoutePath(fullRoutePath)
	)
}

export const markAllRoutesAsUnavailable = (
	routes: RouteObject[],
	routePathPrefix = "",
) => {
	routes.forEach(route => {
		const fullRoutePath = getFullRoutePath(route, routePathPrefix)
		const isSkipRoute = shouldSkipRoute(
			route,
			routePathPrefix,
			fullRoutePath,
		)
		const childrenRoutes = route.children as RouteObject[]

		if (childrenRoutes) {
			markAllRoutesAsUnavailable(childrenRoutes, fullRoutePath)
		}

		if (isSkipRoute) {
			return
		}

		route.element = <Forbidden />
	})
}

const getAvailableRoutesByPermissions = (permissions: PermissionType[]) => {
	const availableRoutes = new Set<string>()

	permissions.forEach(permission => {
		const routes = permissionTypeToRoutes[permission]
		routes?.forEach(route => availableRoutes.add(route))
	})

	return Array.from(availableRoutes)
}

const markUnavailableRoutes = (
	routes: RouteObject[],
	availableRoutes: string[],
	routePathPrefix = "",
) => {
	routes.forEach(route => {
		const fullRoutePath = getFullRoutePath(route, routePathPrefix)
		const isSkipRoute = shouldSkipRoute(
			route,
			routePathPrefix,
			fullRoutePath,
		)
		const childrenRoutes = route.children as RouteObject[]

		if (childrenRoutes) {
			markUnavailableRoutes(
				childrenRoutes,
				availableRoutes,
				fullRoutePath,
			)
		}

		if (isSkipRoute) {
			return
		}

		const isPathUnavailable = !availableRoutes.includes(fullRoutePath)

		if (isPathUnavailable) {
			route.element = <Forbidden />
		}
	})
}

export const markUnavailableRoutesByPermissionTypes = (
	routes: RouteObject[],
	permissions: PermissionType[],
) => {
	if (permissions.length === 0) {
		markAllRoutesAsUnavailable(routes)
		return
	}

	const availableRoutes = getAvailableRoutesByPermissions(permissions)

	markUnavailableRoutes(routes, availableRoutes)
}

export const getDefaultRouteByRoleTypes = (
	roleTypes: RoleType[] | undefined,
) => {
	if (!roleTypes || roleTypes.length === 0) {
		return commonRoutePaths.transactions
	}

	const isCarbonBankAdmin = roleTypes.includes(RoleType.CarbonBankAdmin)

	if (isCarbonBankAdmin) {
		return commonRoutePaths.home
	}

	const fullCarbonBankAssurerRoles = [
		RoleType.CarbonBankAllocationAssurer,
		RoleType.CarbonBankSavingAssurer,
	]
	const isCarbonBankAssurer = fullCarbonBankAssurerRoles.every(x =>
		roleTypes.includes(x),
	)

	if (isCarbonBankAssurer) {
		return routePaths.carbonSavings
	}

	const supportedRoleType = Object.keys(roleTypeToRoutes).find(roleType =>
		roleTypes.includes(roleType as RoleType),
	)

	return (
		roleTypeToRoutes[supportedRoleType as RoleType] ??
		commonRoutePaths.transactions
	)
}

export const isHomeRoute = (path: string) => {
	return path === commonRoutePaths.home || path === "/auth"
}
