import { NotFoundPage } from "@carbonbank/assets"
import { ContainerBox } from "@carbonbank/components"
import { Button, H1, TextLong } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const NotFound: React.FC = () => {
	const { t } = useTranslation("cb", {
		keyPrefix: "common.notFound",
	})

	return (
		<ContainerBox withBottomMargin data-cy="not-found-page">
			<div className="flex flex-col justify-center items-center mt-8">
				<img
					src={NotFoundPage}
					alt="Not Found"
					className="w-[24Opx] h-[154px]"
				/>
				<div className="flex flex-col items-center gap-6 mt-8 w-[400px]">
					<H1>{t("title")}</H1>
					<TextLong className="text-center text-foreground">
						{t("decription")}
					</TextLong>
					<Link to="/">
						<Button variant="primary">{t("buttonTtitle")}</Button>
					</Link>
				</div>
			</div>
		</ContainerBox>
	)
}
