import {
	Transfer,
	transferApi,
	TransferSearchParams,
	TransferValidationForm,
} from "@carbonbank/api"
import { downloadFile } from "@carbonbank/utils/blobTransformation"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	proofs?: Transfer[]
	isModalOpen: boolean
	scenario?: CertificateScenarioVariant
	transferId: string
}
type Actions = {
	fetchCertificatesValidation: (params: TransferSearchParams) => Promise<void>
	getProof: (transferId: string) => Promise<void>
	submitData: (validationInputs: TransferValidationForm) => Promise<void>
	openModal: (transferId: string, scenario?: State["scenario"]) => void
	closeModal: () => void
	getScenario: () => CertificateScenarioVariant
}
export type CertificateScenarioVariant = "approve" | "reject"

export const useCertificateValidation = create<State & Actions>()(
	immer((set, get) => ({
		proofs: undefined,
		isModalOpen: false,
		transferId: "",
		scenario: undefined,
		openModal: (transferId: string, scenario) => {
			set(state => {
				state.transferId = transferId
				state.scenario = scenario
				state.isModalOpen = true
			})
		},
		closeModal: () => {
			set(state => {
				state.isModalOpen = false
			})
		},
		getScenario: () => {
			return get().scenario ?? "approve"
		},
		fetchCertificatesValidation: async (params: TransferSearchParams) => {
			try {
				const response = await transferApi.getTransfers(params)
				set(state => {
					state.proofs = response
				})
			} catch (error) {
				console.error("Failed to fetch proofs:", error)
			}
		},
		getProof: async (transferId: string) => {
			try {
				const proofResponse = await transferApi.downloadEpd(transferId)
				downloadFile(
					"proof.xlsx",
					[proofResponse],
					"application/vnd.ms-excel",
				)
			} catch (error) {
				console.error("Failed to fetch proof:", error)
			}
		},
		submitData: async (validationInputs: TransferValidationForm) => {
			try {
				const scenario = get().getScenario()
				if (scenario === "approve") {
					await transferApi.updateStatusCertificate(validationInputs)
				} else if (scenario === "reject") {
					await transferApi.updateStatusCertificate(validationInputs)
				}
				get().closeModal()
			} catch (e) {
				console.error("Error while submiting data:", e)
			}
		},
	})),
)
