﻿import { TransferCertificate } from "@carbonbank/api"
import {
	AssuranceInfoSection,
	CarbonBankTransparencySection,
	CarbonCaptureAssuranceDescriptionSection,
	CarbonCaptureAttributes,
	CarbonCaptureDescriptionSection,
	CarbonSavingsVerificationSection,
	DateOfIssue,
	OwnerAddress,
	ProductInformationSection,
	TransferCyclesSection,
	TransferTransactionFooter,
	PageHeader,
	CertificateSectionHeader,
} from "@carbonbank/pdf/certificates/transfer/components"
import { firstPageImage } from "@carbonbank/pdf/certificates/transfer/images/images.ts"
import {
	EvoZeroLogo,
	HcLogo,
	PageTitleLogo,
} from "@carbonbank/pdf/certificates/transfer/images/logos.tsx"
import { loadLexend } from "@carbonbank/pdf/fonts/lexend/lexend.ts"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import {
	formatTimestampAsFullDate,
	getTransactionHashScanLink,
} from "@carbonbank/utils"
import {
	Document,
	Image,
	NodeProps,
	Page,
	Text,
	View,
} from "@react-pdf/renderer"

export const CertificatePdfDocument = (
	props: {
		data: TransferCertificate
	} & NodeProps,
) => {
	const pageDpi = 96
	loadLexend()

	const allSteps = props.data.emissions.flatMap(x => x.steps)
	const stepsSortedBySteps = allSteps.sort(
		(a, b) => Date.parse(a.endDate) - Date.parse(b.endDate),
	)

	const firstStartingStep = stepsSortedBySteps[0]
	const mostRecentlyEndingStep = stepsSortedBySteps[allSteps.length - 1]

	return (
		<Document>
			<Page size="A4" style={styles.page} dpi={pageDpi}>
				<View>
					<Image
						style={styles.background_image}
						src={firstPageImage}
					/>
				</View>
				<View style={styles.position_absolute}>
					<View style={styles.first_page_content}>
						<EvoZeroLogo />
						<PageTitleLogo />
						<DateOfIssue
							dateOfIssue={
								formatTimestampAsFullDate(
									props.data.transaction.timestamp,
								) ?? "@carbonTransferred.endDate"
							}
						/>
						<OwnerAddress
							postalCode={
								props.data.address.postalCode ??
								"@ownerAddress.postalCode"
							}
							customerName={props.data.customerName}
							city={
								props.data.address.city ?? "@ownerAddress.city"
							}
							country={"dwa"}
							street={"dwadwada"}
						/>
						<View>
							<Text style={styles.text_regular_small}>
								This certificate is in reference with the
								methodology developed by Heidelberg Materials
								and reviewed by DNV (reference to the document).
								The [EPD OR self-declaration] is prepared
								according to the standard EN 15804.
							</Text>
						</View>
					</View>
					<View style={styles.first_page_hc_logo}>
						<HcLogo />
					</View>
				</View>
			</Page>
			<Page
				size="A4"
				style={{
					...styles.page,
					...styles.page_wrappable,
					...styles.page_info,
				}}
				dpi={pageDpi}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={2}
				/>
				<ProductInformationSection
					declaredCementProduct={props.data.declaredProduct}
					originCarbonCapture={props.data.originDeclaredProduct}
					bundledProduct={props.data.bundledProduct}
					deliveredCementQuantity={props.data.cementQuantity}
					deliveredCementQuantityUom={"tonnes"}
					productionOrigin={props.data.originBundledProduct}
					reference={"EPD-HM-20240101-CEMII/A-LL32,5R"}
				/>
				<CarbonCaptureAttributes
					declaredProduct={props.data.declaredProduct}
					gwpCarbonCaptured={props.data.gwpCarbonCaptured.quantity}
					gwpGross={props.data.gwpTotal.quantity}
					fossilAmount={props.data.fossil.quantity}
				/>
				<AssuranceInfoSection
					assurances={props.data.deposits}
					approver="DNV Business Assurance Germany GmbH"
					carbonSavings={props.data.totalSavings.quantity}
					customer={props.data.customerName}
					plant={props.data.originDeclaredProduct}
					firstDate={
						formatTimestampAsFullDate(
							firstStartingStep.startDate,
						) ?? "@startDate"
					}
					lastDate={
						formatTimestampAsFullDate(
							mostRecentlyEndingStep.endDate,
						) ?? "@endDate"
					}
				/>
				<TransferTransactionFooter
					transactionNumber={
						props.data.transaction.proof.identifier ??
						"@carbonTransferred.proofs"
					}
					verificationId={props.data.verificationNumber}
				/>
			</Page>
			<Page
				size="A4"
				dpi={pageDpi}
				style={{
					...styles.page,
					...styles.page_wrappable,
					...styles.page_info,
				}}
			>
				<View style={{ ...styles.gap_10 }}>
					<PageHeader
						customerName={props.data.customerName}
						pageNumber={3}
					/>
					<CertificateSectionHeader content="Traceability of carbon savings" />
					<Text style={{ ...styles.text_regular }}>
						Each step in the carbon saving process is meticulously
						tracked. To maintain transparency and accountability, a
						unique transaction ID is assigned to each step. Please
						note that the time periods of the recorded data may
						overlap across the steps as the storage terminal
						receives a new shipment of captured carbon every fourth
						day and a allocation of carbon savings to a customer
						delivery can include multiple carbon savings shipments.
					</Text>
					<TransferCyclesSection emissions={props.data.emissions} />
				</View>
			</Page>
			<Page
				size="A4"
				dpi={pageDpi}
				style={{ ...styles.page, ...styles.page_info }}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={4}
				/>
				<CarbonCaptureDescriptionSection />
				<CarbonCaptureAssuranceDescriptionSection />
			</Page>
			<Page
				size="A4"
				dpi={pageDpi}
				style={{
					...styles.page,
					...styles.page_wrappable,
					...styles.page_info,
				}}
			>
				<PageHeader
					customerName={props.data.customerName}
					pageNumber={5}
				/>
				<CarbonBankTransparencySection />
				<CarbonSavingsVerificationSection
					verificationNumber={props.data.verificationNumber}
					transactionLink={getTransactionHashScanLink(
						props.data.transaction.proof.identifier,
					)}
					certificateTransactionNumber={
						props.data.transaction.proof.identifier ??
						"@data.transferTransactionId"
					}
				/>
			</Page>
		</Document>
	)
}
