import { PageLayout } from "@carbonbank/components"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

export const Layout = () => {
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.productNames",
	})

	return (
		<PageLayout productName={tCommon("carbonBank")} navigation={[]}>
			<Outlet />
		</PageLayout>
	)
}
