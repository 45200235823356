import { TransferCertificate } from "@carbonbank/api"
import {
	DashboardCard,
	Button,
	icon,
	Label,
	CardHeader,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface BundledDeliveredProductProps {
	certificate?: TransferCertificate
}
export const TransactionBundledProduct: React.FC<
	BundledDeliveredProductProps
> = ({ certificate }: BundledDeliveredProductProps) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.bundledDeliveredProduct",
	})

	return (
		<DashboardCard
			className="self-stretch"
			data-cy="transaction-bundled-product"
		>
			<CardHeader
				aside={
					<Button variant="tertiary" icon={icon.mdiOpenInNew}>
						{t("showEpd")}
					</Button>
				}
			>
				{t("title")}
			</CardHeader>
			<div className="flex flex-col gap-y-6">
				<Label title={t("bundledDeliveredProduct")}>
					{certificate?.bundledProduct}
				</Label>
				<Label title={t("productOrigin")}>
					{certificate?.originBundledProduct}
				</Label>
			</div>
		</DashboardCard>
	)
}
