import i18next from "@carbonbank/i18n"

const DefaultLocale = "en"

export enum UnitType {
	Kg = "kg",
	KgCO2 = "kgCO2",
	Ton = "ton",
}

export const formatNumber = (
	value: number | string,
	config: Intl.NumberFormatOptions | undefined = undefined,
): string => {
	const numValue = typeof value === "string" ? parseFloat(value) : value

	if (isNaN(numValue) || numValue === 0) {
		return "0"
	}

	return numValue.toLocaleString(
		i18next.language || DefaultLocale,
		config || { minimumFractionDigits: 2, maximumFractionDigits: 2 },
	)
}

export const formatIntNumber = (value: number | string): string => {
	const config = { minimumFractionDigits: 0, maximumFractionDigits: 0 }

	return formatNumber(value, config)
}

export const formatNumberWithUnit = (
	value: number | string,
	unitType: UnitType = UnitType.Kg,
): string => {
	const localUnit = i18next.t(`cb:units.${unitType}`)
	const formattedNumber = formatNumber(value)

	return `${formattedNumber} ${localUnit}`
}
