import { H1 } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

export const TransactionHeader: React.FC = () => {
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactions",
	})
	return (
		<H1 data-cy="transatcion-title" className="text-center">
			{t("titleFirstPart")} <br />
			{t("titleSecondPart")}
		</H1>
	)
}
