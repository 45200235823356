export const getTransactionHashScanLink = (transactionId: string) => {
	const hashScanNet =
		import.meta.env.MODE === "production" ? "mainnet" : "testnet"
	return `https://hashscan.io/${hashScanNet}/transaction/${transactionId}`
}

export const getTokenHashScanLink = (tokenId: string) => {
	const hashScanNet =
		import.meta.env.MODE === "production" ? "mainnet" : "testnet"
	return `https://hashscan.io/${hashScanNet}/token/${tokenId}`
}
