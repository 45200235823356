import { Input } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

export const AutoTransferForm: React.FC = () => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferForm",
	})

	return (
		<>
			<div className="w-full md:w-[500px]" data-cy="auto-transfer">
				<div className="flex flex-col gap-3">
					<Input
						data-cy="project-reference-input"
						label={t("projectReference")}
						name="projectReference"
					/>
					<Input
						data-cy="email-input"
						label={t("email")}
						name="email"
					/>
					<Input
						data-cy="confirm-email-input"
						label={t("confirmEmail")}
						name="confirmEmail"
					/>
				</div>
			</div>
		</>
	)
}
