import { SiteFilterData } from "../../types"

const siteFilterDataMock: SiteFilterData[] = [
	{
		siteId: "eu.00000001",
		siteNumber: "00000001",
		customerId: "eu.12088595",
		siteName: "Brevik",
		street: "Test",
		postalCode: "00001",
		city: "Test",
		state: "01",
		country: "DE",
		orgUnitId: "0001",
	},
	{
		siteId: "eu.00000002",
		siteNumber: "00000002",
		customerId: "eu.00000001",
		siteName: "BrightPlant",
		street: "Test",
		postalCode: "00002",
		city: "Test",
		state: "01",
		country: "DE",
		orgUnitId: "0001",
	},
]

export default siteFilterDataMock
