import { transactionIdFormatter } from "@carbonbank/utils"
import { Yup } from "@carbonbank/yup"
import {
	DetailsCard,
	CardHeader,
	CardFooter,
	Button,
	Input,
	TextLong,
} from "@sustainability/fundamental"
import { Formik, Form } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getTransactionDetailsUrl } from "../transactionsRoutes"

export const ShowTransactionsCard: React.FC = () => {
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactions.components.showTransactions",
	})
	const transactionNumberValidationSchema = Yup.object().shape({
		transactionNumber: Yup.string()
			.required(t("transactionNumberValidation"))
			.matches(/^(.{0,21}|.{23,})$/, t("transactionNumberTypeValidation"))
			.transactionIdFormat(),
	})
	const navigate = useNavigate()
	const onSubmit = (values: { transactionNumber: string }) => {
		const formattedId = transactionIdFormatter(
			values.transactionNumber,
			true,
		)
		navigate(getTransactionDetailsUrl(formattedId))
	}
	return (
		<DetailsCard data-cy="show-transaction-card">
			<CardHeader>{t("transactionCardHeader")}</CardHeader>
			<TextLong>{t("transactionCardDescription")}</TextLong>
			<Formik
				initialValues={{ transactionNumber: "" }}
				onSubmit={onSubmit}
				validationSchema={transactionNumberValidationSchema}
			>
				{({ isValid }) => (
					<Form className="mt-2">
						<Input
							name="transactionNumber"
							classID="p-2"
							label={t("transactionLabel")}
							data-cy="transaction-number-input"
						/>
						<CardFooter className="text-center">
							<Button
								variant="tertiary"
								type="submit"
								data-cy="transaction-submit-button"
								disabled={!isValid}
							>
								{t("showTransactionsButton")}
							</Button>
						</CardFooter>
					</Form>
				)}
			</Formik>
		</DetailsCard>
	)
}
