import { Transfer, PermissionType } from "@carbonbank/api"
import {
	CertificateScenarioVariant,
	useAuthStore,
	useCertificateValidation,
} from "@carbonbank/store"
import { formatDate, formatNumberWithUnit, UnitType } from "@carbonbank/utils"
import { nameof } from "@carbonbank/utils/nameof"
import {
	Button,
	Pill,
	Table,
	TextRegular,
	useSortableTable,
} from "@sustainability/fundamental"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CertificatesValidationModal } from "./CertificatesValidationModal"

export const TransferProofsTable: React.FC = () => {
	const [proofInfo, setProofInfo] = useState<Transfer | undefined>(undefined)
	const [openModal, proofs, getProof] = useCertificateValidation(state => [
		state.openModal,
		state.proofs,
		state.getProof,
	])

	const { t } = useTranslation("contracts", {
		keyPrefix: "certificatesValidation.components.transferProofsTable",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})

	const [userPermissions] = useAuthStore(state => [state.userPermissions])

	const isActionBtnShouldBeDisplayed = userPermissions?.some(
		x => x.permissionType === PermissionType.ManageCertiticates,
	)

	const downloadProof = async (transferId: string) => {
		await getProof(transferId)
	}
	const modalHandler = (
		transferId: string,
		action: CertificateScenarioVariant,
	) => {
		const proof = proofs?.find(proof => proof.transferId === transferId)
		setProofInfo(proof)
		openModal(transferId, action)
	}

	const tableConfig = useSortableTable<Transfer>(
		[
			{
				id: "customerName",
				label: t("customerTableHeader"),
			},
			{
				id: "contractNumber",
				label: t("contractNumberTableHeader"),
			},
			{
				id: "timestamp",
				label: t("requestDateTableHeader"),
				render: ({ timestamp }) => formatDate(timestamp),
			},
			{
				id: "quantity",
				label: t("quantityTableHeader"),
				render: ({ quantity }) =>
					formatNumberWithUnit(quantity, UnitType.Ton),
			},
			{
				id: "carbonSavings",
				label: t("carbonSavingsTableHeader"),
				render: ({ carbonSavings }) =>
					formatNumberWithUnit(carbonSavings, UnitType.KgCO2),
			},
			{
				id: "transferId",
				label: t("proofTableHeader"),
				render: ({ transferId }) => (
					<TextRegular onClick={() => downloadProof(transferId)}>
						{t("showProof")}
					</TextRegular>
				),
				sortable: false, // Probably doesn't make sense to sort an "action" column
				valueClassName: "text-primary cursor-pointer",
			},
			{
				id: "contractNumber",
				label: t("actionTableHeader"),
				render: ({ status, transferId }) => (
					<>
						{isActionBtnShouldBeDisplayed &&
							status === "Pending" && (
								<div className="flex gap-x-3 justify-end">
									<Button
										data-cy="assure-button"
										variant="primary"
										onClick={() =>
											modalHandler(transferId, "approve")
										}
									>
										{tCommon("approve")}
									</Button>
									<Button
										variant="negative"
										data-cy="reject-button"
										onClick={() =>
											modalHandler(transferId, "reject")
										}
									>
										{tCommon("reject")}
									</Button>
								</div>
							)}
						{status === "Rejected" && (
							<Pill
								data-cy="status"
								text="Rejected"
								type="negative"
								variant="shade"
								shape="rounded"
								className="ml-auto"
							/>
						)}
						{(status === "Not claimed yet" ||
							status === "Downloadable") && (
							<Pill
								data-cy="status"
								text="Approved"
								type="positive"
								variant="shade"
								shape="rounded"
								className="ml-auto"
							/>
						)}
					</>
				),
				headerClassName: "text-right",
				valueClassName: "text-right",
			},
		],
		undefined,
		{
			prefix: "epd-proofs-table-rows",
			postfixFieldName: nameof<Transfer>("contractNumber"),
		},
		{ enableLocalSorting: true },
	)
	return (
		<>
			<Table
				config={tableConfig}
				data={proofs}
				data-cy="epd-proofs-table"
			/>
			<CertificatesValidationModal proof={proofInfo} />
		</>
	)
}
