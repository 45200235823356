﻿// todo bold is not working
import { CertificateSectionHeader } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { Text, View } from "@react-pdf/renderer"

export const CarbonBankTransparencySection = () => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content="Ensuring transparency through state-of-the art technology" />
			<Text>
				<Text style={{ ...styles.text_regular }}>
					The carbon savings of our evoZero cement are trackable and
					traceable by applying a blockchain-style design principle in
					our distributed ledger technology (DLT). Recognizing the
					challenge of being aware of every entity within the supply
					chain,
				</Text>
				<Text
					style={{
						...styles.text_lexend,
						...styles.text_size_12px,
						...styles.text_color_dark,
					}}
				>
					&nbsp;Heidelberg Materials has chosen to enhance trust by
					decentralising it
				</Text>
				<Text style={{ ...styles.text_regular }}>
					, employing a public distributed ledger to document the
					journey of the carbon savings - from CCS plan to the
					end-customer. Through DLT, Heidelberg Materials’ customers
					will receive a verifiable carbon proof for their evoZero
					purchase.
				</Text>
			</Text>
			<View style={{ ...styles.flex_row, ...styles.gap_20 }}>
				<View
					style={{
						...styles.background_grey,
						...styles.padding_10,
						...styles.width_336px,
					}}
				>
					<Text>
						<Text
							style={{
								...styles.text_lexend,
								...styles.text_size_12px,
								...styles.text_color_dark,
							}}
						>
							DLT&nbsp;
						</Text>
						<Text
							style={{
								...styles.text_regular,
								...styles.text_font_weigh_700,
							}}
						>
							(Distributed Ledger Technology) employs a network of
							computers, known as nodes, to automatically verify
							and record transactions. It functions similar to a
							digital notary, ensuring the authenticity of each
							step in the process. While blockchain is probably
							the best known example for a distributed ledger
							technology, it is often associated with
							sustainability concerns. Heidelberg Materials made a
							conscious decision to when deciding for a DLT that
							it is in line with our company’s sustainability
							commitements. Therefore, evoZero carbon savings are
							tracked using Hedera&apos;s Hashgraph DLT. Notably,
							Hashgraph transactions consume just 0.000003 kWH,
							making them 3,300 times more energy-efficient than
							Ethereum transactions*.
						</Text>
					</Text>
				</View>
				<View style={{ ...styles.gap_20, ...styles.width_336px }}>
					<Text style={{ ...styles.text_regular_bold }}>
						Why choose Distributed Ledger Technology (DLT) instead
						of a centralized database for tracking carbon savings?
					</Text>
					<Text style={{ ...styles.text_regular }}>
						While it&apos;s true that a centralized database can be
						automatically updated just as well, it may still be
						prone to manipulation. In contrast, DLT offers
						immutability and a secure record-keeping system. With
						DLT, every transaction is securely recorded on a
						decentralized network, ensuring transparency and
						providing all participants in the supply chain with
						access to an identical and tamper-proof record. The
						commitment to precision and transparency is officially
						documented on this digital certificate, guaranteeing an
						indisputable record of the carbon savings associated
						with evoZero.
					</Text>
				</View>
			</View>
			<Text style={{ ...styles.text_regular_small }}>
				*source: https://hedera.com/ucl-blockchain-energy
			</Text>
		</View>
	)
}
