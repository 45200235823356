import { EmissionsResponse, EmissionStepResponse } from "@carbonbank/api"
import { formatTimestampAsFullDate } from "@carbonbank/utils"
import { Grid } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

import { TransactionHistoryCard } from "./TransactionHistoryCard"
import { TransactionHistoryCardListConstant } from "./TransactionHistoryConstants"

interface TransactionHistoryCardListProps {
	emissions: EmissionsResponse[]
}

const getDurationForStep = (
	steps: EmissionStepResponse[],
): [string, string] => {
	const stepsByEarliestStartDate = steps.sort(
		(a, b) => Date.parse(a.startDate) - Date.parse(b.startDate),
	)
	const stepsSortedByLatestEndDate = steps.sort(
		(a, b) => Date.parse(b.startDate) - Date.parse(a.startDate),
	)
	const startDate = stepsByEarliestStartDate[0].startDate
	const endDate = stepsSortedByLatestEndDate[0].endDate
	return [
		formatTimestampAsFullDate(startDate),
		formatTimestampAsFullDate(endDate),
	]
}

export const TransactionHistoryCardList: React.FC<
	TransactionHistoryCardListProps
> = ({ emissions }) => {
	const { t } = useTranslation("transactions")
	const allSteps = emissions.flatMap(e => e.steps)
	const transactionHistoryItems = emissions[0].steps.map((step, index) => {
		const historyCard = TransactionHistoryCardListConstant[index]

		return {
			id: index + 1,
			title: t(historyCard.title),
			description: t(historyCard.description),
			startDate: getDurationForStep(
				allSteps.filter(s => s.name === step.name),
			)[0],
			endDate: getDurationForStep(
				allSteps.filter(s => s.name === step.name),
			)[1],
			image: historyCard.image,
			proofDetails: step.proof.identifier,
		}
	})

	return (
		<Grid
			size={1}
			lgSize={4}
			mdSize={2}
			className="px-0 flex flex-col gap-4"
			data-cy="transaction-history-list"
		>
			{transactionHistoryItems.map(transaction => (
				<TransactionHistoryCard
					key={transaction.id}
					title={transaction.title}
					description={transaction.description}
					startDate={transaction.startDate}
					endDate={transaction.endDate}
					image={transaction.image}
					proofId={transaction.proofDetails}
				/>
			))}
		</Grid>
	)
}
