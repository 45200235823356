import { CarbonSourceType, SiteFilterData } from "@carbonbank/api"
import { FilterConfig } from "@sustainability/fundamental"
import { TFunction } from "i18next"

const mapInputToValue = (input: SiteFilterData[]) =>
	input.map(value => ({ label: value.siteName!, value: value.siteName! }))

export const dnvYearlyAssuranceFiltersConfig = (
	input: SiteFilterData[],
	t: TFunction<"cs", "yearlyAssurance.page">,
): FilterConfig[] => [
	{
		label: t("filter.year"),
		type: "select",
		values: [
			{ label: "2025", value: 2025 },
			{ label: "2024", value: 2024 },
		],
		name: "year",
	},
	{
		label: t("filter.location"),
		type: "select",
		values: mapInputToValue(input),
		name: "siteName",
	},
	{
		label: "",
		type: "switch",
		name: "carbonSourceType",
		values: [
			{
				label: t("filter.carbonSource.all"),
				value: CarbonSourceType.All,
			},
			{
				label: t("filter.carbonSource.biogenic"),
				value: CarbonSourceType.Biogenic,
			},
			{
				label: t("filter.carbonSource.fossil"),
				value: CarbonSourceType.Fossil,
			},
		],
	},
]
