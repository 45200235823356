import { LoadingPage } from "@carbonbank/components"
import { useCarbonTransferStore, useContractsStore } from "@carbonbank/store"
import {
	CanvasCard,
	LayoutTwoOneRatio,
	LayoutTwoOneRatioLeftColumn,
	LayoutTwoOneRatioRightColumn,
	PageHeader,
} from "@sustainability/fundamental"
import { Form, Formik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { getContractDetailsUrl } from "../contractsRoutes"
import { CarbonTransferDataFormCard } from "./components/CarbonTransferDataFormCard"
import { CarbonTransferSummary } from "./components/CarbonTransferSummary"
import { TransferFormInputs } from "./types"
import { useValidationSchema } from "./validationSchema"

export const CarbonTransfer: React.FC = () => {
	const validationSchema = useValidationSchema()
	const [submitTransfer, setSuccessModal] = useCarbonTransferStore(state => [
		state.submitTransfer,
		state.setSuccessModal,
	])
	const [contract, fetchContractDetails, fetchContacts, isLoading] =
		useContractsStore(state => [
			state.contract,
			state.fetchContractDetails,
			state.fetchContacts,
			state.isLoading,
		])
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferForm",
	})

	const { id } = useParams()

	useEffect(() => {
		if (id !== undefined) {
			fetchContractDetails(id)
			fetchContacts(id)
		}
	}, [fetchContractDetails, fetchContacts, id])

	const stateInputs: TransferFormInputs = {
		contractNumber: id || "",
		customerNumber: contract?.invoices?.[0].customerNumber || "",
		accumulatedQuantity: contract?.accumulatedQuantity || 0,
		cementAmount: contract?.availableToTransfer || 0,
		totalAvailableToTransfer: contract?.availableToTransfer || 0,
		product: contract?.declaredProduct || "",
		projectReference: "",
		companyName: "",
		street: "",
		zipCode: "",
		city: "",
		country: "",
		email: "",
		confirmEmail: "",
		isManualCertificate: false,
		isPreviousContact: false,
		epdFile: [],
	}

	const navigate = useNavigate()

	const handleSubmit = async (values: TransferFormInputs) => {
		await submitTransfer(values, values.epdFile)
		if (values.isManualCertificate)
			navigate(`/customers/contracts/${id}/certificates`)
		else setSuccessModal()
	}

	if (isLoading) {
		return (
			<LoadingPage
				backToPageUrlOnClick={() =>
					navigate(getContractDetailsUrl(id!))
				}
				backToPageLabel={`${t("backToContract")} ${id}`}
				title={t("transferCarbonSavings")}
			/>
		)
	}

	return (
		<main
			className="flex flex-col min-h-screen"
			data-cy="carbon-transfer-main"
		>
			<PageHeader
				backToPageUrlOnClick={() =>
					navigate(getContractDetailsUrl(id!))
				}
				backToPageLabel={`${t("backToContract")} ${id}`}
				data-cy="page-title"
			>
				{t("transferCarbonSavings")}
			</PageHeader>
			<CanvasCard
				className="flex-grow rounded-t-3xl relative"
				data-cy="carbon-transfer-canvas-card"
			>
				<Formik
					initialValues={stateInputs}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
					enableReinitialize={true}
				>
					{({ isValid }) => (
						<Form>
							<LayoutTwoOneRatio>
								<LayoutTwoOneRatioLeftColumn
									breakpoint="md"
									className="mt-12 md:px-12 mb-[130px]"
									data-cy="carbon-transfer-left-column"
								>
									<CarbonTransferDataFormCard />
								</LayoutTwoOneRatioLeftColumn>
								<LayoutTwoOneRatioRightColumn
									className="fixed left-0 bottom-0 w-full md:static"
									breakpoint="md"
									data-cy="carbon-transfer-right-column"
								>
									<CarbonTransferSummary isValid={isValid} />
								</LayoutTwoOneRatioRightColumn>
							</LayoutTwoOneRatio>
						</Form>
					)}
				</Formik>
			</CanvasCard>
		</main>
	)
}
