import { ProductFilterData, SiteFilterData } from "@carbonbank/api"
import { CustomerResponse } from "@carbonbank/api/customers/types"
import { AllocationFilterData } from "@carbonbank/store/types"
import { FilterConfig } from "@sustainability/fundamental"
import { TFunction } from "i18next"

const mapInputToCustomerValue = (input: CustomerResponse[]) =>
	input.map(value => ({
		label: value.name!,
		value: value.number!,
	}))

const mapInputToProductValue = (input: ProductFilterData[]) =>
	input.map(value => ({
		label: value.productName!,
		value: value.productName!,
	}))

const mapInputToLocationValue = (input: SiteFilterData[]) =>
	input.map(value => ({ label: value.siteName!, value: value.siteName! }))

export const productAllocationFilterConfig = (
	input: AllocationFilterData,
	t: TFunction<"cs", "productAllocation.page">,
): FilterConfig[] => [
	{
		label: t("customer"),
		type: "select",
		values: mapInputToCustomerValue(input.customerFilterData),
		name: "customer",
	},
	{
		label: t("product"),
		type: "select",
		values: mapInputToProductValue(input.productFilterData),
		name: "product",
	},
	{
		label: t("timeframe"),
		type: "datepicker",
		name: "timeframe",
		timeframe: true,
		showMonthYearPicker: true,
	},
	{
		label: t("location"),
		type: "select",
		values: mapInputToLocationValue(input.siteFilterData),
		name: "site",
	},
	{
		label: "",
		type: "switch",
		name: "flagged",
		values: [
			{
				label: t("all"),
				value: "all",
			},
			{
				label: t("flagged"),
				value: "flagged",
			},
		],
	},
]
