import {
	formatDate,
	getTokenHashScanLink,
	getTransactionHashScanLink,
} from "@carbonbank/utils"
import {
	DetailsCard,
	H3,
	LabelRegular,
	TextMedium,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface TransactionHistoryCardProps {
	title: string
	description: string
	startDate?: string | null
	endDate?: string | null
	image: string
	proofId: string
}
export const TransactionHistoryCard: React.FC<TransactionHistoryCardProps> = ({
	title,
	description,
	startDate,
	endDate,
	image,
	proofId,
}) => {
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactionDetails.components.transactionHistoryCard",
	})

	const isTransactionCard =
		proofId && /^\d\.\d\.\d{7}@\d{10}\.\d{9}$/.test(proofId)
	const proofLink = isTransactionCard
		? getTransactionHashScanLink(proofId)
		: getTokenHashScanLink(proofId)

	return (
		<>
			<DetailsCard data-cy="transaction-history-card">
				<H3 data-cy="transaction-history-title">{title}</H3>
				<div className="flex flex-col gap-6">
					<img
						src={image}
						alt=""
						data-cy="transaction-history-image"
						className="w-46 h-46 object-cover mx-auto"
					/>
					<div className="flex flex-col">
						<LabelRegular
							className="text-foreground_faded"
							data-cy="transaction-history-description"
						>
							{t("description")}
						</LabelRegular>
						<TextMedium>{description}</TextMedium>
					</div>
					<div className="flex flex-col">
						<LabelRegular data-cy="transaction-history-time">
							{t("time")}
						</LabelRegular>
						<TextMedium>
							{startDate || endDate ? (
								<>
									{formatDate(startDate ?? "")} -{" "}
									{formatDate(endDate ?? "")}
								</>
							) : (
								"-"
							)}
						</TextMedium>
					</div>
					<div
						className="flex flex-col"
						data-cy="transaction-history-ids"
					>
						<LabelRegular
							className="text-foreground_faded"
							data-cy="transaction-history-measurementid"
						>
							{isTransactionCard
								? t("transactionId")
								: t("TokenId")}
						</LabelRegular>
						<TextMedium className="text-primary  cursor-pointer">
							<a
								href={proofLink}
								target="_blank"
								rel="noreferrer"
								className="underline block break-words"
							>
								{proofId}
							</a>
						</TextMedium>
					</div>
				</div>
			</DetailsCard>
		</>
	)
}
