import { AxiosError } from "axios"

export const statusCheckInterceptor = async (
	error: AxiosError,
): Promise<AxiosError> => {
	const isForbidden = error.response && error.response.status === 403

	if (isForbidden) {
		window.location.href = "/forbidden"
	}

	return Promise.reject(error)
}
