import { NoContracts as NoContractsImage } from "@carbonbank/assets"
import { ContainerBox } from "@carbonbank/components"
import { H1, PageHeader, TextLong } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const NoContracts: React.FC = () => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contracts.components.noContracts",
	})
	const { t: tPage } = useTranslation("contracts", {
		keyPrefix: "contracts.page",
	})

	return (
		<ContainerBox withBottomMargin>
			<PageHeader data-cy="contracts-list-header">
				{tPage("pageTitle")}
			</PageHeader>
			<div className="flex flex-col justify-center items-center mt-8">
				<img
					src={NoContractsImage}
					alt="No Contracts"
					className="w-[24Opx] h-[154px]"
				/>
				<div className="flex flex-col items-center gap-6 mt-8 w-[400px]">
					<H1>{t("title")}</H1>
					<TextLong className="text-center text-foreground">
						{t("description")}
					</TextLong>
				</div>
			</div>
		</ContainerBox>
	)
}
