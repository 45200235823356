import { RouteObject } from "react-router-dom"

import { Layout } from "./Layout"
import { TransactionDetails } from "./TransactionDetails"
import { TransactionsOverview } from "./TransactionsOverview"

const baseUrl = "/transactions"

export const transactionsRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <TransactionsOverview />,
			},
			{
				path: ":id",
				element: <TransactionDetails />,
			},
		],
	},
]

export const getTransactionsOverviewUrl = () => baseUrl
export const getTransactionDetailsUrl = (id: string | number) =>
	`${baseUrl}/${id.toString()}`
