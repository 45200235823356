import { RouteObject } from "react-router-dom"

import { CarbonTransfer } from "./CarbonTransfer"
import { Certificates } from "./Certificates"
import { CertificatesValidation } from "./CertificatesValidation"
import { ContractDetail } from "./ContractDetails"
import { Contracts } from "./Contracts"
import { Layout } from "./Layout"

const baseUrl = "/customers/contracts"

export const contractsRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Contracts />,
			},
			{
				path: "validation",
				element: <CertificatesValidation />,
			},
			{
				path: ":id",
				children: [
					{ index: true, element: <ContractDetail /> },
					{ path: "certificates", element: <Certificates /> },
					{ path: "transfers", element: <CarbonTransfer /> },
				],
			},
		],
	},
]

export const getContractsUrl = () => baseUrl
export const getCertificatesValidationUrl = () => `${baseUrl}/validation`
export const getContractDetailsUrl = (id: string | number) => `${baseUrl}/${id}`
export const getContractCertificatesUrl = (id: string | number) =>
	`${baseUrl}/${id}/certificates`
export const getContractTransfersUrl = (id: string | number) =>
	`${baseUrl}/${id}/transfers`
