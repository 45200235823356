import { transferApi } from "@carbonbank/api"
import { TransferFormInputs } from "@carbonbank/pages/contracts/CarbonTransfer"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type CarbonTransferStoreState = {
	openSuccessModal: boolean
	openValidationModal: boolean
	isLoading: boolean
}

type CarbonTransferStoreAction = {
	setValidationModal: () => void
	setSuccessModal: () => void
	submitTransfer: (
		transferData: TransferFormInputs,
		epdFile: File[],
	) => Promise<void>
}

export const useCarbonTransferStore = create<
	CarbonTransferStoreState & CarbonTransferStoreAction
>()(
	immer(set => ({
		isLoading: false,
		openSuccessModal: false,
		openValidationModal: false,
		setSuccessModal: () =>
			set(state => {
				state.openSuccessModal = !state.openSuccessModal
			}),
		setValidationModal: () =>
			set(state => {
				state.openValidationModal = !state.openValidationModal
			}),
		submitTransfer: async (
			transferData: TransferFormInputs,
			epdFile: File[],
		) => {
			set(state => {
				state.isLoading = true
			})
			const transferValues = {
				contractNumber: transferData.contractNumber,
				customerNumber: transferData.customerNumber,
				projectReference: transferData.projectReference,
				companyName: transferData.companyName,
				street: transferData.street,
				postalCode: transferData.zipCode,
				city: transferData.city,
				state: "",
				country: transferData.country,
				recipientEmail: transferData.email,
				quantity: transferData.cementAmount,
			}
			try {
				await transferApi.createTransfer(transferValues, epdFile)
			} catch (error) {
				spawnErrorToast("error")
				throw error
			} finally {
				set(state => {
					state.isLoading = false
				})
			}
		},
	})),
)
