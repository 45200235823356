import { ContainerBox, LoadingPage } from "@carbonbank/components"
import {
	useAuthStore,
	useContractsStore,
	useFilterStore,
} from "@carbonbank/store"
import {
	PageHeader,
	icon,
	Button,
	DashboardCard,
	CardHeader,
} from "@sustainability/fundamental"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { ContractTable, NoContracts } from "./components"

export const Contracts: React.FC = () => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contracts.page",
	})

	const [userRoles] = useAuthStore(state => [state.userRoles])

	const [customerFilterData, fetchCustomerFilterData] = useFilterStore(
		state => [state.customerFilterData, state.fetchCustomerFilterData],
	)

	const [contracts, fetchContracts, fetchClosedContracts] = useContractsStore(
		state => [
			state.contracts,
			state.fetchContracts,
			state.fetchClosedContracts,
		],
	)

	const [showAllContracts, setShowAllContracts] = useState(false)
	const [filter, setFilter] = useState({
		customerName: "",
		customerNumber: "",
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		fetchCustomerFilterData()
	}, [])

	useEffect(() => {
		const shouldSetFilter =
			!filter.customerNumber &&
			!filter.customerName &&
			customerFilterData &&
			customerFilterData.length > 0 &&
			customerFilterData[0].customerNumber &&
			customerFilterData[0].customerName

		if (shouldSetFilter) {
			setFilter({
				customerName: customerFilterData[0].customerName!,
				customerNumber: customerFilterData[0].customerNumber!,
			})
		}
	}, [customerFilterData])

	useEffect(() => {
		const businessLine = userRoles?.find(
			x => x.dataScope.businessLine !== undefined,
		)?.dataScope.businessLine

		if (!businessLine) {
			return
		}

		;(async () => {
			setLoading(true)

			await fetchContracts({
				customerNumber: filter.customerNumber,
				showClosed: showAllContracts,
				businessLine: businessLine,
			})

			setLoading(false)
		})()
	}, [filter])

	useEffect(() => {
		const businessLine = userRoles?.find(
			x => x.dataScope.businessLine !== undefined,
		)?.dataScope.businessLine
		const shouldFetchClosedContracts = showAllContracts && businessLine

		if (!shouldFetchClosedContracts) {
			return
		}

		fetchClosedContracts({
			customerNumber: filter.customerNumber,
			showClosed: showAllContracts,
			businessLine: businessLine,
		})
	}, [showAllContracts])

	const handleShowAllContracts = () => {
		setShowAllContracts(true)
	}

	if (!contracts || !customerFilterData || isLoading) {
		return <LoadingPage title={t("pageTitle")} />
	}

	if (contracts?.length === 0) {
		return <NoContracts />
	}

	return (
		<ContainerBox withBottomMargin data-cy="contracts-page-saving">
			<PageHeader data-cy="contracts-list-header">
				{t("pageTitle")}
			</PageHeader>
			<DashboardCard
				data-cy="contracts-list-card"
				className="px-3 p-6 mb-16"
			>
				<CardHeader
					data-cy="contracts-list-header-title"
					className="px-3"
				>
					{t("openContracts")}
				</CardHeader>
				<ContractTable displayedContracts={contracts} />
				<div
					data-cy="contracts-list-showmore"
					className="flex justify-center mt-6 p-3"
				>
					{!showAllContracts && (
						<Button
							variant="tertiary"
							icon={icon.mdiArrowDown}
							className="flex gap-2"
							onClick={() => handleShowAllContracts()}
						>
							{t("closedContractsLabel")}
						</Button>
					)}
				</div>
			</DashboardCard>
		</ContainerBox>
	)
}
