﻿import { CertificateSectionHeader } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { carbonCaptureProcessImage } from "@carbonbank/pdf/certificates/transfer/images/images.ts"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { Image, Text, View } from "@react-pdf/renderer"
import React from "react"

export const CarbonCaptureDescriptionSection = () => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content="Carbon carpure and storage" />
			<Image src={carbonCaptureProcessImage} />
			<Text style={{ ...styles.text_regular }}>
				Carbon Capture and Storage (CCS) is a technology that can
				capture the carbon dioxide (CO2) emissions produced from the
				calcination process and use of fuels in the kiln operation,
				preventing the carbon dioxide from entering the atmosphere and
				storing it permanently underground. The captured CO2 from
				Heidelberg Materials’ plant in [Brevik, Norway], is compressed
				and transported through to a storage site beneath the North Sea.
				The facility at our Brevik plant will capture 400,000 tonnes of
				CO2 annually.
			</Text>
			<View style={{ ...styles.gap_10, ...styles.flex_row }}>
				<Text style={{ ...styles.text_regular }}>
					Learn more on the process on:
				</Text>
				<Text
					style={{
						...styles.text_regular_bold,
						...styles.text_color_grey,
					}}
				>
					https://www.brevikccs.com/en
				</Text>
			</View>
		</View>
	)
}
