import { PermissionType, RoleType } from "@carbonbank/api"
import { PageLayout } from "@carbonbank/components"
import { useAuthStore } from "@carbonbank/store"
import {
	getDefaultRouteByRoleTypes,
	isHomeRoute,
} from "@carbonbank/utils/routesUtils"
import { NavigationTabsProps } from "@sustainability/fundamental"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { getAssureSavingsUrl, getOverviewUrl } from "../carbon-savings"
import { getContractsUrl } from "../contracts"
import { getTransactionsOverviewUrl } from "../transactions"
import { getLandingUrl } from "./commonRoutes"

export const Layout = () => {
	const { t: tLayout } = useTranslation("cb", {
		keyPrefix: "layout.menuTabs",
	})

	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.productNames",
	})

	const [userRoles, userPermissions] = useAuthStore(state => [
		state.userRoles,
		state.userPermissions,
	])

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const shouldNavigateToDefaultPage = isHomeRoute(location.pathname)

		if (!shouldNavigateToDefaultPage) {
			return
		}

		const roleTypes = userRoles?.map(x => x.roleType)
		const defaultRoute = getDefaultRouteByRoleTypes(roleTypes)

		navigate(defaultRoute)
	}, [userRoles])

	const getNavTabs = (): NavigationTabsProps[] => {
		const navTabs: NavigationTabsProps[] = []

		navTabs.push({
			label: tLayout("transactions"),
			url: getTransactionsOverviewUrl(),
		})

		if (!userPermissions || !userRoles) {
			return navTabs
		}

		const addTab = (condition: boolean, label: string, url: string) => {
			if (condition) {
				navTabs.push({ label, url })
			}
		}

		const isViewTransactionsPermission = userPermissions.some(
			permission =>
				permission.permissionType === PermissionType.ViewTransactions,
		)

		addTab(
			userPermissions.some(
				permission =>
					permission.permissionType === PermissionType.ViewContracts,
			),
			tLayout("contracts"),
			getContractsUrl(),
		)
		addTab(
			userPermissions.some(permission =>
				[
					PermissionType.ViewSavings,
					PermissionType.ViewTransactions,
				].includes(permission.permissionType),
			),
			tLayout("assureCarbonSavings"),
			isViewTransactionsPermission
				? getOverviewUrl()
				: getAssureSavingsUrl(),
		)
		addTab(
			userRoles.some(role => role.roleType === RoleType.CarbonBankAdmin),
			tLayout("home"),
			getLandingUrl(),
		)

		return navTabs.reverse()
	}

	return (
		<PageLayout
			productName={tCommon("carbonBank")}
			navigation={getNavTabs()}
		>
			<Outlet />
		</PageLayout>
	)
}
