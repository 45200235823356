import { authenticationClient } from "../client"
import { waitResolve } from "../utils"
import productFilterDataMock from "./__tests__/__mocks__/productFilterData"
import siteFilterDataMock from "./__tests__/__mocks__/siteFilterData"
import { CustomerFilterData, ProductFilterData, SiteFilterData } from "./types"

export const filterApi = {
	fetchSiteFilterData: (): Promise<SiteFilterData[]> =>
		waitResolve(siteFilterDataMock, 1000),
	fetchProductFilterData: (): Promise<ProductFilterData[]> =>
		waitResolve(productFilterDataMock, 1000),
	fetchCustomerFilterData: (): Promise<CustomerFilterData[]> =>
		authenticationClient.get("/customers").then(response => response.data),
}
