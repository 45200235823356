﻿import { CertificateSectionHeader } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { Text, View } from "@react-pdf/renderer"
import React from "react"

export const CarbonCaptureAssuranceDescriptionSection = () => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content="Enusring accounrability by installing a carbon bank" />
			<Text style={{ ...styles.text_regular }}>
				Heidelberg Materials has taken thorough measures to guarantee
				the credibility of carbon savings generated by our CCS cement
				plant. To prevent any possibility of double accounting, we have
				implemented a secure virtual carbon bank on a public distributed
				ledger.
			</Text>
			<Text style={{ ...styles.text_regular }}>
				Throughout the process from production at the cement plant to
				the final delivery, we track over 75 data points. Among them, 30
				significant data points are added to the public distributed
				ledger for transparency. At the commencement of this process, a
				source transaction is generated to record the necessary data
				points for the first process step. Every new data point added
				generated a transaction that reference the relevant upstream
				transactions. This results in a comprehensive and coherent
				record.
			</Text>
			<Text style={{ ...styles.text_regular }}>
				The carbon bank process is diligent: Each carbon savings deposit
				to the carbon bank is only authorized upon the permanent storage
				and assurance completion by DNV. The carbon bank categorizes
				deposits as either biogenic or fossil captured carbon, enabling
				accurate reporting. When a delivery of our evoZero cement is
				made, we calculate the amount of carbon savings to be deducted
				based on the ordered product type and volume. This value is then
				withdrawn from the carbon bank, specifically allocated to the
				customer&apos;s delivery, ensuring precise tracking.
			</Text>
			<Text style={{ ...styles.text_regular }}>
				Upon the closure of the assurance period, DNV conducts a
				thorough review, meticulously examining the carbon bank&apos;s
				operations in comparison with Heidelberg Materials&apos; sales
				orders. This additional assurance process certifies that no
				instances of double accounting have occurred, providing official
				confirmation of the integrity and authenticity of our carbon
				savings.
			</Text>
		</View>
	)
}
