import { Transfer } from "@carbonbank/api"
import { ContainerBox, LoadingPage } from "@carbonbank/components"
import { useCertificateStore } from "@carbonbank/store/useCertificateStore"
import { formatDate, formatNumberWithUnit, UnitType } from "@carbonbank/utils"
import { nameof } from "@carbonbank/utils/nameof"
import {
	CanvasCard,
	PageHeader,
	Table,
	useSortableTable,
} from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { getContractDetailsUrl } from "../contractsRoutes"
import { CertificateStatus } from "./CertificateStatus"

export const Certificates: React.FC = () => {
	const navigate = useNavigate()
	const [certificates, fetchCertificates] = useCertificateStore(state => [
		state.certificates,
		state.fetchCertificates,
	])
	const { t } = useTranslation("contracts", {
		keyPrefix: "certificates.components.certificatesTable",
	})
	const { t: tCommon } = useTranslation("contracts", {
		keyPrefix: "certificates.components.certifictesHeader",
	})
	const { id } = useParams()
	useEffect(() => {
		if (id !== undefined) {
			fetchCertificates({ contractNumber: id })
		}
	}, [fetchCertificates, id])

	const tableConfig = useSortableTable<Transfer>(
		[
			{
				id: "projectReference",
				label: t("projectReference"),
			},
			{
				id: "timestamp",
				label: t("timestamp"),
				render: ({ timestamp }) => formatDate(timestamp),
			},
			{
				id: "product",
				label: t("product"),
			},
			{
				id: "quantity",
				label: t("quantity"),
				render: ({ quantity }) =>
					formatNumberWithUnit(quantity, UnitType.Ton),
			},
			{
				id: "carbonSavings",
				label: t("carbonSavings"),
				render: ({ carbonSavings }) =>
					formatNumberWithUnit(carbonSavings, UnitType.KgCO2),
			},
			{
				id: "status",
				label: t("status"),
				render: ({ transactionId, status }) => (
					<CertificateStatus
						transactionId={transactionId}
						status={status}
					/>
				),
			},
		],
		undefined,
		{
			prefix: "certificates-list-table-row",
			postfixFieldName: nameof<Transfer>("transferId"),
		},
		{ enableLocalSorting: true },
	)

	if (!certificates) {
		return (
			<LoadingPage
				backToPageUrlOnClick={() =>
					navigate(getContractDetailsUrl(id!))
				}
				backToPageLabel={`${tCommon("backToContract")} ${id}`}
				title={tCommon("title")}
			/>
		)
	}

	return (
		<main
			className="flex flex-col min-h-screen"
			data-cy="certificates-page"
		>
			<ContainerBox>
				<PageHeader
					backToPageUrlOnClick={() =>
						navigate(getContractDetailsUrl(id!))
					}
					backToPageLabel={`${tCommon("backToContract")} ${id}`}
					data-cy="page-title"
				>
					{tCommon("title")}
				</PageHeader>
			</ContainerBox>
			<CanvasCard className="flex-grow rounded-t-3xl mt-4">
				<Table
					data={certificates}
					config={tableConfig}
					data-cy="certificates-list-table"
				/>
			</CanvasCard>
		</main>
	)
}
