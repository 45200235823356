import { clx } from "@sustainability/fundamental"
import React from "react"

interface KpiStatsProps {
	className?: string
	children?: React.ReactNode
	"data-cy"?: string
}

export const KpiStats: React.FC<KpiStatsProps> = ({
	className,
	children,
	"data-cy": dataId,
}) => {
	return (
		<div
			data-cy={dataId}
			className={clx("flex flex-wrap gap-x-12 gap-y-3 px-6", className)}
		>
			{children}
		</div>
	)
}
