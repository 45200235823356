import { useContractsStore } from "@carbonbank/store"
import { formatNumber } from "@carbonbank/utils"
import { ProgressBar, Kpi } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

export const DataExtraction: React.FC = () => {
	const contract = useContractsStore(state => state.contract)
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common",
	})
	const { t } = useTranslation("contracts", {
		keyPrefix: "contracts.components.listContractsTable",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	if (!contract) return null

	const { accumulatedQuantity, targetQuantity, allSavingsTransferred } =
		contract
	const valueLabel = `${accumulatedQuantity} / ${targetQuantity} t`
	const carbonSavingsUnit = tUnits("kgCO2")

	return (
		<div
			className="flex items-center max-sm:flex-wrap px-24px gap-12"
			data-cy="contract-details-data-extractions"
		>
			<div className="w-[300px]">
				<ProgressBar
					size="large"
					currentValue={accumulatedQuantity}
					maxValue={targetQuantity}
					label={tCommon("quantity")}
					valueLabel={valueLabel}
				/>
			</div>
			<div className="flex gap-12 max-[360px]:flex-wrap">
				<Kpi
					value={formatNumber(contract.allocatedCarbonSavingsKgCO2)}
					size="small"
					label={tCommon("carbonSavings")}
					unit={carbonSavingsUnit}
					className="w-full"
				></Kpi>
				<Kpi
					size="small"
					value={
						allSavingsTransferred ? tCommon("yes") : tCommon("no")
					}
					label={t("allSavingsTransferred")}
					valueClassName="text-negative"
					className="w-full"
				></Kpi>
			</div>
		</div>
	)
}
