﻿import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const HorizontalSectionItem = (
	props: {
		title: string
		content: string
		underline?: boolean
	} & NodeProps,
) => {
	const useUnderline = props.underline ?? true

	return (
		<View
			style={{
				...styles.flex_row,
				...styles.gap_10,
				...styles.justify_space_between,
			}}
		>
			<View
				style={{
					...(useUnderline ? styles.border_underline : []),
					...styles.padding_10_20_10,
					...styles.flex_1,
				}}
			>
				<Text
					style={{ ...styles.text_regular, ...styles.max_wight_315 }}
				>
					{props.title}
				</Text>
			</View>
			<View
				style={{
					...(useUnderline ? styles.border_underline : []),
					...styles.padding_10_20_10,
					...styles.text_right,
				}}
			>
				<Text style={{ ...styles.text_regular }}>{props.content}</Text>
			</View>
		</View>
	)
}
export const SectionItem = (
	props: { title: string; value: string } & NodeProps,
) => {
	return (
		<View>
			<Text style={styles.text_regular}>{props.title}</Text>
			<Text style={styles.text_regular_bold}>{props.value}</Text>
		</View>
	)
}

export const PageHeader = (
	props: {
		customerName: string
		pageNumber: number
	} & NodeProps,
) => {
	return (
		<View style={styles.page_header}>
			<View>
				<Text
					style={{
						...styles.text_regular_small,
						...styles.text_color_grey,
					}}
				>
					0{props.pageNumber}
				</Text>
			</View>
			<View>
				<Text style={styles.text_regular_small}>
					Certification of Carbon Captured Product Attributes -{" "}
					{props.customerName}
				</Text>
			</View>
		</View>
	)
}

export const CertificateSectionHeader = (
	props: { content: string } & NodeProps,
) => {
	return (
		<Text
			style={{ ...styles.text_section_header, ...styles.text_color_grey }}
		>
			{props.content}
		</Text>
	)
}
