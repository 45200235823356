import { TextMedium } from "@sustainability/fundamental"
import { clx } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface ContactSwitcherProps {
	isPreviousContact: boolean
	setIsPreviousContact: (value: boolean) => void
}
export const ContactSwitcher: React.FC<ContactSwitcherProps> = ({
	isPreviousContact,
	setIsPreviousContact,
}) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.contactSwitcher",
	})
	// @TODO: Switch component
	return (
		<div
			className="flex items-center gap-8 w-[500px] h-12 bg-border_highlight_10
		rounded-xl p-1"
		>
			<div
				data-cy="previous-contacts-switcher"
				className={clx(
					"px-14 py-2 rounded-md cursor-pointer transition ease-in-out",
					isPreviousContact && "bg-primary text-white",
				)}
				onClick={() => setIsPreviousContact(true)}
			>
				<TextMedium>{t("previousContact")}</TextMedium>
			</div>
			<div
				data-cy="new-contacts-switcher"
				className={clx(
					"px-14 py-2 rounded-md cursor-pointer transition ease-in-out",
					!isPreviousContact && "bg-primary text-white",
				)}
				onClick={() => setIsPreviousContact(false)}
			>
				<TextMedium>{t("newContact")}</TextMedium>
			</div>
		</div>
	)
}
