import { TransferCertificate } from "@carbonbank/api"
import { CarbonCaptureModal } from "@carbonbank/components"
import { useTransactionStore } from "@carbonbank/store"
import {
	Alert,
	Button,
	CardHeader,
	DashboardCard,
	Icon,
	icon,
	TextLong,
	TextMedium,
} from "@sustainability/fundamental"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { TransactionCarbonSavingTable } from "./TransactionCarbonSavingTable"

interface CarbonSavingsProps {
	certificate?: TransferCertificate
}
export const TransactionCarbonSavings: React.FC<CarbonSavingsProps> = ({
	certificate,
}) => {
	const [verified, verificationDetails] = useTransactionStore(state => [
		state.verified,
		state.verificationDetails,
	])
	const [openCarbonInfo, setOpenCarbonInfo] = useState(false)
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.carbonSavingTable",
	})
	const { t: tVerification } = useTranslation("transactions", {
		keyPrefix: "transactionDetails",
	})

	return (
		<DashboardCard data-cy="contract-details-carbonsavings">
			<CardHeader
				aside={
					<Button
						variant="tertiary"
						icon={icon.mdiInformationOutline}
						onClick={() => setOpenCarbonInfo(true)}
						data-cy="carbonsavings_modal_button"
					>
						{t("carbonCaptureAndStorage")}
					</Button>
				}
			>
				{t("title")}
			</CardHeader>
			<CarbonCaptureModal
				openCarbonInfo={openCarbonInfo}
				setOpenCarbonInfo={setOpenCarbonInfo}
			/>
			{verified && (
				<Alert
					variant="success"
					className=" mb-3"
					data-cy="verification-alert"
				>
					<div className="flex items-center gap-3">
						<Icon
							path={icon.mdiCheckDecagramOutline}
							size="xlarge"
							className="text-primary"
						/>
						<div className="flex flex-col gap-1">
							<TextMedium>
								{verificationDetails?.companyName},{" "}
								{verificationDetails?.country},{" "}
								{verificationDetails?.postalCode},{" "}
								{verificationDetails?.city},{" "}
								{verificationDetails?.country}
							</TextMedium>
							<TextLong>
								{tVerification("verifiedMessage")}
							</TextLong>
						</div>
					</div>
				</Alert>
			)}
			<TransactionCarbonSavingTable certificate={certificate} />
		</DashboardCard>
	)
}
