import { PermissionType } from "@carbonbank/api"
import { PageLayout } from "@carbonbank/components"
import { useAuthStore } from "@carbonbank/store"
import { NavigationTabsProps } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

import { getContractsUrl } from "./contractsRoutes"

export const Layout = () => {
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.productNames",
	})
	const { t } = useTranslation("contracts", {
		keyPrefix: "layout",
	})

	const [userPermissions] = useAuthStore(state => [state.userPermissions])

	const getNavTabs = (): NavigationTabsProps[] => {
		return userPermissions?.some(
			permission =>
				permission.permissionType === PermissionType.ViewContracts,
		)
			? [
					{
						label: t("contracts"),
						url: getContractsUrl(),
					},
				]
			: []
	}

	return (
		<PageLayout
			productName={tCommon("carbonBank")}
			navigation={getNavTabs()}
		>
			<Outlet />
		</PageLayout>
	)
}
