import { carbonBankClient, carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	TransferValidationForm,
	Transfer,
	TransferSearchParams,
	TransferCertificate,
	CreateTransferForm,
	Contact,
} from "./types"

export const transferApi = {
	getTransfers: (params?: TransferSearchParams): Promise<Transfer[]> =>
		carbonBankWithAuthClient
			.get("/transfers/all", { params })
			.then(response => response.data),
	getTransferCertificate: (
		transactionId: string,
	): Promise<TransferCertificate> =>
		carbonBankClient
			.get(`/transfers/${transactionId}/certificate`)
			.then(response => response.data),
	downloadEpd: (transferId: string): Promise<BlobPart> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/transfers/download-epd", { transferId }), {
				responseType: "blob",
			})
			.then(response => response.data),
	createTransfer: (
		transfer: CreateTransferForm,
		epdFile: File[],
	): Promise<void> => {
		const formData = new FormData()
		Object.entries(transfer).forEach(([key, value]) =>
			formData.append(key, value),
		)

		epdFile.forEach(file => {
			formData.append("epdFile", file)
		})

		return carbonBankWithAuthClient.post("/transfers", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
	},
	updateStatusCertificate: (
		validationIputs: TransferValidationForm,
	): Promise<void> =>
		carbonBankWithAuthClient.post(
			"/transfers/update-status",
			validationIputs,
		),
	getPreviousContacts: (contractNumber: string): Promise<Contact[]> =>
		carbonBankWithAuthClient
			.get(
				generateGetUrl("/transfers/previous-contacts", {
					contractNumber,
				}),
			)
			.then(response => response.data),
}
