import { RouteObject } from "react-router-dom"

import { Layout } from "./Layout"
import { MicrositePage } from "./MicroSitePage"

const baseUrl = "/microsite/:id"

export const microSiteRoutes: RouteObject[] = [
	{
		path: baseUrl,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <MicrositePage />,
			},
		],
	},
]

export const getMicroSiteUrl = () => baseUrl
