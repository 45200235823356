import { Contact } from "@carbonbank/api"
import { useContractsStore } from "@carbonbank/store"
import { TableBase, TableCell, TableRow } from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import React from "react"

export const ContactsList: React.FC = () => {
	const { setFieldValue } = useFormikContext()
	const contacts = useContractsStore(state => state.contacts)
	const uniqueRecipientEmails = contacts?.filter(
		(contact, index, self) =>
			contact.recipientEmail &&
			index ===
				self.findIndex(
					t => t.recipientEmail === contact.recipientEmail,
				),
	)

	const handleRadioChange = (contact: Contact) => {
		setFieldValue("projectReference", contact.projectReference)
		setFieldValue("email", contact.recipientEmail)
	}

	return (
		<div className="w-[500px] px-4" data-cy="contacts-list">
			<TableBase>
				{uniqueRecipientEmails?.map(contact => (
					<TableRow key={contact.recipientEmail}>
						<TableCell>
							<div className="flex gap-4">
								<input
									data-cy="contacts-list-input"
									type="radio"
									name="email"
									value={contact.recipientEmail}
									className="rounded-full accent-primary"
									onChange={() => handleRadioChange(contact)}
								/>
								{contact.projectReference} {""}(
								{contact.recipientEmail})
							</div>
						</TableCell>
					</TableRow>
				))}
			</TableBase>
		</div>
	)
}
