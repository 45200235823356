import { useTransactionStore } from "@carbonbank/store"
import { transactionIdFormatter } from "@carbonbank/utils"
import { Yup } from "@carbonbank/yup"
import {
	Button,
	Input,
	Loading,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextMedium,
} from "@sustainability/fundamental"
import { Form, Formik, FormikProps } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

interface VerificationModalModalProps {
	isOpen: boolean
	onClose: () => void
}
export const VerificationModal: React.FC<VerificationModalModalProps> = ({
	isOpen,
	onClose,
}) => {
	const [verifyTransaction] = useTransactionStore(state => [
		state.verifyTransaction,
	])
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactionDetails.components.verificationModal",
	})
	const validationSchema = Yup.object().shape({
		verificationNumber: Yup.string().required(),
	})
	const { id } = useParams()
	const formattedId = id ? transactionIdFormatter(id) : ""

	const handleSubmit = async (values: { verificationNumber: string }) => {
		await verifyTransaction(formattedId, values.verificationNumber)
		onClose()
	}

	return (
		<Modal open={isOpen} onClose={onClose} data-cy="verification-modal">
			<ModalTitle data-cy="verification-title">{t("title")}</ModalTitle>
			<Formik
				initialValues={{ verificationNumber: "" }}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				enableReinitialize={true}
			>
				{({
					isSubmitting,
					isValid,
					dirty,
				}: FormikProps<{ verificationNumber: string }>) => (
					<Form>
						<ModalContent data-cy="verification-content">
							<div className="flex flex-col gap-6">
								<TextMedium>{t("description")}</TextMedium>
								<Input
									data-cy="verification-input"
									label={t("inputLabel")}
									name="verificationNumber"
								/>
							</div>
						</ModalContent>
						<ModalFooter>
							<div className="flex gap-3">
								<Button
									variant="secondary"
									onClick={onClose}
									data-cy="verification-cancel-button"
									type="button"
								>
									{tCommon("cancel")}
								</Button>
								<Button
									variant="primary"
									type="submit"
									disabled={
										isSubmitting || !isValid || !dirty
									}
									data-cy="verification-verify-button"
								>
									{isSubmitting ? (
										<Loading
											size="small"
											variant="secondary"
										/>
									) : (
										t("verifyButton")
									)}
								</Button>
							</div>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
