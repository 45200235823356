import { KpiStats } from "@carbonbank/components"
import { formatNumber } from "@carbonbank/utils"
import { Kpi } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface AssureStatsProps {
	className?: string
	toBeAssured: number
	newCycles: number
	inValidation: number
}

export const AssureStats: React.FC<AssureStatsProps> = ({
	className,
	toBeAssured,
	newCycles,
	inValidation,
}) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavings.components.assureStats",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const toBeAssuredUnit = tUnits("kgCO2")

	return (
		<KpiStats data-cy="assure-stats" className={className}>
			<Kpi
				data-cy="to-be-assured"
				size="small"
				label={t("savingsToBeAssured")}
				value={formatNumber(toBeAssured)}
				unit={toBeAssuredUnit}
			/>
			<Kpi
				data-cy="new-cycles"
				size="small"
				label={t("newCycles")}
				value={newCycles}
			/>
			<Kpi
				data-cy="in-validation"
				size="small"
				label={t("inValidation")}
				value={inValidation}
				valueClassName={inValidation > 0 ? "text-warning" : ""}
			/>
		</KpiStats>
	)
}
