export const downloadFile = (
	fileName: string,
	fileData: BlobPart[],
	mimeType: string = "text/plain",
) => {
	const blob = new Blob(fileData, { type: mimeType })
	const url = window.URL.createObjectURL(blob)
	const link = document.createElement("a")

	link.href = url
	link.download = fileName
	link.click()
}
