import { formatNumber } from "@carbonbank/utils"
import {
	DashboardCard,
	CardHeader,
	Button,
	icon,
	Kpi,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface EtsReportCardProps {
	productionEtsReportUrl: string
	storageEtsReportUrl: string
	totalDepositsFromEtsReport: number
	totalDeposits: number
}

export const EtsReportCard: React.FC<EtsReportCardProps> = ({
	productionEtsReportUrl,
	storageEtsReportUrl,
	totalDepositsFromEtsReport,
	totalDeposits,
}: EtsReportCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssuranceReport.components.etsReportCard",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const etsUnit = tUnits("kgCO2")

	return (
		<DashboardCard data-cy="ets-report">
			<CardHeader
				data-cy="title"
				wrapAside={false}
				aside={
					<div
						data-cy="action-buttons"
						className="flex flex-wrap gap-3 justify-end"
					>
						<a
							href={productionEtsReportUrl}
							target="_blank"
							rel="noreferrer"
						>
							<Button
								variant="secondary"
								icon={icon.mdiDownloadOutline}
							>
								{t("productionReportLabel")}
							</Button>
						</a>
						<a
							href={storageEtsReportUrl}
							target="_blank"
							rel="noreferrer"
						>
							<Button
								variant="secondary"
								icon={icon.mdiDownloadOutline}
							>
								{t("storageReportLabel")}
							</Button>
						</a>
					</div>
				}
			>
				{t("title")}
			</CardHeader>
			<div data-cy="stats" className="flex gap-x-6">
				<Kpi
					data-cy="total-deposits"
					size="small"
					label={t("totalDeposits")}
					value={formatNumber(totalDeposits)}
					unit={etsUnit}
				/>
				<Kpi
					data-cy="total-deposits-ets"
					size="small"
					label={t("totalDepositsEts")}
					value={formatNumber(totalDepositsFromEtsReport)}
					unit={etsUnit}
				/>
				<Kpi
					data-cy="delta"
					size="small"
					label={t("delta")}
					value={formatNumber(
						totalDepositsFromEtsReport - totalDeposits,
					)}
					valueClassName={
						totalDepositsFromEtsReport > totalDeposits
							? "text-positive"
							: "text-negative"
					}
					unit={etsUnit}
				/>
			</div>
		</DashboardCard>
	)
}
