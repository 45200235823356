import { useYearlySavingsStore } from "@carbonbank/store"
import { Yup } from "@carbonbank/yup"
import { Modal, ModalTitle } from "@sustainability/fundamental"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { StepAssured } from "./StepAssured"
import { StepAssuring } from "./StepAssuring"
import { StepConfirm } from "./StepConfirm"
import { StepFill } from "./StepFill"

interface AssureBalanceModalProps {
	year: string
	location: string
	bufferBalance: number
	isOpen: boolean
	onClose: () => void
}

export const AssureBalanceModal: React.FC<AssureBalanceModalProps> = ({
	year,
	location,
	bufferBalance,
	isOpen,
	onClose,
}: AssureBalanceModalProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureBalanceModal",
	})

	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.validation",
	})

	const assureYearlyBalance = useYearlySavingsStore(
		s => s.assureYearlyBalance,
	)
	//TODO: Mocked because not implemented on the BE
	const [transactionId, setTransactionId] = useState(
		"0.0.4247256@1719226465.886266858",
	)
	const [step, setStep] = useState(0)

	const goToNextStep = () => setStep(step + 1)
	const goToPreviousStep = () => setStep(step - 1)

	const validationSchema = Yup.object().shape({
		serialNumber: Yup.string()
			.dnvSerialFormat()
			.required(tCommon("required")),
	})

	const onFormSubmit = async (values: { serialNumber: string }) => {
		try {
			const response = await assureYearlyBalance(
				year,
				location,
				values.serialNumber,
			)
			setTransactionId(response.transactionId)
			goToNextStep()
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<Modal
			data-cy="assure-balance-modal"
			open={isOpen}
			onClose={() => {
				// If is assuring, does not allow for closing:
				if (step === 2) {
					return
				}

				onClose()
			}}
		>
			<ModalTitle data-cy="title">{t("title", { year })}</ModalTitle>
			<Formik
				initialValues={{ serialNumber: "" }}
				validationSchema={validationSchema}
				onSubmit={onFormSubmit}
			>
				{({ submitForm }) => (
					<Form>
						<div className="min-h-[454px] grid grid-rows-[1fr_auto]">
							{step === 0 && (
								<StepFill
									bufferBalance={bufferBalance}
									onCancel={onClose}
									onConfirm={goToNextStep}
								/>
							)}
							{step === 1 && (
								<StepConfirm
									bufferBalance={bufferBalance}
									onCancel={goToPreviousStep}
									onConfirm={() => {
										goToNextStep()
										submitForm()
									}}
								/>
							)}
							{step === 2 && <StepAssuring />}
							{step === 3 && (
								<StepAssured
									bufferBalance={bufferBalance}
									transactionId={transactionId}
									onClose={onClose}
								/>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
