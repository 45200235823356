import { PermissionType } from "@carbonbank/api"
import { useAuthStore } from "@carbonbank/store"
import { Button, icon } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"

import {
	getContractCertificatesUrl,
	getContractTransfersUrl,
} from "../../contractsRoutes"

type PageHeaderButtonsProps = {
	allowSavingsTransfer: boolean
}

export const PageHeaderButtons: React.FC<PageHeaderButtonsProps> = ({
	allowSavingsTransfer,
}: PageHeaderButtonsProps) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.contractDetailsHeader",
	})
	const navigate = useNavigate()
	const { id } = useParams()

	const [userPermissions] = useAuthStore(state => [state.userPermissions])

	const shouldDisplayTransferButton =
		allowSavingsTransfer &&
		userPermissions?.some(
			x => x.permissionType === PermissionType.ManageCertiticates,
		)

	return (
		<div className="grid grid-rows-2 grid-cols-1 gap-4 sm:flex sm:flex-wrap sm:gap-3 sm:items-center sm:justify-end">
			<Button
				size="large"
				className="hidden lg:flex"
				variant="secondary"
				icon={icon.mdiOpenInNew}
			>
				{t("showOrdersInHub")}
			</Button>
			<Button
				size="large"
				variant="secondary"
				onClick={() => navigate(getContractCertificatesUrl(id!))}
			>
				{t("showCertificates")}
			</Button>
			<Link to={getContractTransfersUrl(id!)}>
				<Button
					disabled={!shouldDisplayTransferButton}
					size="large"
					className="max-sm:w-full"
					variant="primary"
				>
					{t("transferCarbonSavings")}
				</Button>
			</Link>
		</div>
	)
}
