import { ContainerBox, LoadingPage } from "@carbonbank/components"
import { useCertificateValidation } from "@carbonbank/store"
import { CanvasCard, PageHeader } from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { TransferProofsTable } from "./components"

export const CertificatesValidation: React.FC = () => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "certificatesValidation",
	})
	const [proofs, fetchCertificatesValidation] = useCertificateValidation(
		state => [state.proofs, state.fetchCertificatesValidation],
	)

	useEffect(() => {
		fetchCertificatesValidation({ excludeFullTransfers: true })
	}, [fetchCertificatesValidation])

	if (!proofs) {
		return <LoadingPage title={t("pageTitle")} />
	}

	return (
		<div data-cy="proof-page-content">
			<ContainerBox>
				<PageHeader data-cy="proof-page-title">
					{t("pageTitle")}
				</PageHeader>
			</ContainerBox>
			<CanvasCard className="flex-grow">
				<TransferProofsTable />
			</CanvasCard>
		</div>
	)
}
