import { InvoiceAllocationItem } from "@carbonbank/api"
import { getTransactionDetailsCarbonSavingUrl } from "@carbonbank/pages"
import {
	formatNumberWithUnit,
	formatTimestamp,
	UnitType,
} from "@carbonbank/utils"
import {
	useSortableTable,
	TextRegular,
	Table,
	Icon,
	icon,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface TransactionsCardProps {
	data: InvoiceAllocationItem[]
}

export const TransactionsTable: React.FC<TransactionsCardProps> = ({
	data,
}) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "productAllocation.components.transactionsTable",
	})

	const config = useSortableTable<InvoiceAllocationItem>(
		[
			{
				id: "timestamp",
				label: t("timestamp"),
				render: ({ timestamp }) => (
					<TextRegular>{formatTimestamp(timestamp)}</TextRegular>
				),
			},
			{
				id: "customer",
				label: t("customer"),
				showBreakpoint: "lg",
				render: ({ customer }) => <TextRegular>{customer}</TextRegular>,
			},
			{
				id: "product",
				label: t("product"),
				valueClassName: "w-1/3",
				render: ({ product }) => <TextRegular>{product}</TextRegular>,
			},
			{
				id: "quantity",
				label: t("quantity"),
				valueClassName: "text-left md:text-right",
				headerClassName: "text-left md:text-right",
				// TODO: Discuss with BE if values are always converted to the same unit in the whole list,
				// otherwise a conversion mechanism must be implemented so that sorting works correctly
				sortFunction: (
					{ quantity: leftQuantity },
					{ quantity: rightQuantity },
				) => leftQuantity.amount - rightQuantity.amount,
				render: ({ quantity }) => (
					<TextRegular>
						{formatNumberWithUnit(quantity.amount, UnitType.Ton)}
					</TextRegular>
				),
			},
			{
				id: "productEmissions",
				label: t("productEmissions"),
				valueClassName: "text-left md:text-right",
				headerClassName: "text-left md:text-right",
				showBreakpoint: "md",
				render: ({ productEmissions }) => (
					<TextRegular>
						{formatNumberWithUnit(productEmissions, UnitType.KgCO2)}
					</TextRegular>
				),
			},
			{
				id: "allocatedSavings",
				label: t("allocatedCarbonSavings"),
				valueClassName: "text-left md:text-right",
				headerClassName: "text-left md:text-right",
				showBreakpoint: "md",
				render: ({ allocatedSavings, flagged }) => (
					<TextRegular
						className={flagged ? "text-warning" : undefined}
					>
						{flagged && (
							<Icon
								path={icon.mdiFlagOutline}
								className="inline-block align-baseline"
							/>
						)}{" "}
						{formatNumberWithUnit(allocatedSavings, UnitType.KgCO2)}
					</TextRegular>
				),
			},
			{
				sortable: false,
				label: "",
				valueClassName: "text-right",
				headerClassName: "text-right",
				render: ({ id }) => (
					<Link to={getTransactionDetailsCarbonSavingUrl(id)}>
						<Icon path={icon.mdiChevronRight} />
					</Link>
				),
			},
		],
		undefined,
		undefined,
		{ enableLocalSorting: true },
	)

	return <Table data-cy="transactions-table" config={config} data={data} />
}
