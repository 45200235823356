import { KpiStats } from "@carbonbank/components"
import { formatNumber, formatTimestamp } from "@carbonbank/utils"
import { Kpi } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface TransactionStats {
	className?: string
	date?: string
	transactionNumber?: string
	carbonSavings?: number
}
export const TransactionDetailsStats: React.FC<TransactionStats> = ({
	className,
	date,
	transactionNumber,
	carbonSavings,
}) => {
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactionDetails.components.transactionStats",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const toBeAssuredUnit = tUnits("kgCO2")

	return (
		<KpiStats className={className} data-cy="transaction-details-kpis">
			<Kpi
				data-cy="tranaction-date-kpi"
				size="small"
				label={t("date")}
				value={date ? formatTimestamp(date) : ""}
			/>
			<Kpi
				data-cy="transaction-number-kpi"
				size="small"
				label={t("transactionNumber")}
				value={transactionNumber}
			/>
			<Kpi
				data-cy="carbon-savings-kpi"
				size="small"
				label={t("carbonSavings")}
				value={formatNumber(carbonSavings ?? 0)}
				valueClassName="text-primary"
				unit={toBeAssuredUnit}
			/>
		</KpiStats>
	)
}
