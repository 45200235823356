import { Button } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { getAssureYearlyBalanceReportUrl } from "../../carbonSavingsRoutes"

interface ActionButtonsProps {
	isYearAssured: boolean
	onYearAssureModalOpen: () => void
	onOpenAdjustmentBalanceModal: () => void
	year: number
	location: string
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
	isYearAssured,
	onYearAssureModalOpen,
	onOpenAdjustmentBalanceModal,
	year,
	location,
}: ActionButtonsProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.page.actionButtons",
	})

	return (
		<div
			data-cy="action-buttons"
			className="flex flex-wrap sm:flex-nowrap gap-x-4 gap-y-3"
		>
			{isYearAssured ? (
				<Button variant="secondary" onClick={onYearAssureModalOpen}>
					{t("yearlyBalanceLabel")}
				</Button>
			) : (
				<>
					<Button
						variant="secondary"
						onClick={onOpenAdjustmentBalanceModal}
					>
						{t("adjustBalanceLabel")}
					</Button>
					<Link to={getAssureYearlyBalanceReportUrl(year, location)}>
						<Button variant="primary">
							{t("assureYearlyBalanceLabel")}
						</Button>
					</Link>
				</>
			)}
		</div>
	)
}
