import axios, { AxiosRequestConfig } from "axios"

import { correlationIdInterceptor } from "../interceptors"

const commentsUrl = import.meta.env.VITE_COMMENTS_API

const axiosConfig: AxiosRequestConfig = {
	baseURL: commentsUrl,
	headers: {
		"Content-Type": "application/json",
	},
}

export const commentsClient = axios.create(axiosConfig)

commentsClient.interceptors.request.use(correlationIdInterceptor)
