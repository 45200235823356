import {
	Alert,
	Button,
	DragAndDropFiles,
	H3,
	icon,
	Input,
	Label,
	Textarea,
	Toggle,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

import { CustomerKeys } from "../types"

const useTypedTranslation = () => {
	const { t } = useTranslation("customer", {
		keyPrefix: "customer",
	})
	return (key: CustomerKeys) => t(key)
}
export const ProjectInformationForm: React.FC = () => {
	const t = useTypedTranslation()

	return (
		<div
			className="flex flex-col gap-6 max-w-[500px] mt-12"
			data-cy="customer-form-component"
		>
			<H3>{t("title")}</H3>
			<div className="flex flex-col">
				<Input
					label={t("projectInformation.projectTitle")}
					name="projectTitle"
					data-cy="customer-form-project-title"
				/>
				<Label
					title={t("projectInformation.projectTitleMessage")}
					className="px-3 mt-1"
				/>
			</div>
			<H3 className="mt-9">{t("projectInformation.microsite")}</H3>
			<div className="flex flex-col gap-3">
				<Textarea
					label={t("projectInformation.aboutProject")}
					name="description"
					data-cy="customer-form-description"
				/>
				<Input
					label={t("projectInformation.city")}
					name="city"
					data-cy="customer-form-city"
				/>
				<Input
					label={t("projectInformation.Country")}
					name="country"
					data-cy="customer-form-country"
				/>
				<Input
					label={t("projectInformation.Completion")}
					name="completion"
					data-cy="customer-form-completion"
				/>
			</div>
			<div className="flex flex-col gap-3">
				<Label title={t("projectInformation.addImageMessage")}></Label>
				<DragAndDropFiles name="image" data-cy="customer-form-image" />
			</div>
			<div className="flex gap-3">
				<Button
					variant="secondary"
					icon={icon.mdiOpenInNew}
					data-cy="customer-show-preview"
				>
					{t("projectInformation.showPreviw")}
				</Button>
				<Button
					variant="secondary"
					icon={icon.mdiDownloadOutline}
					data-cy="customer-download-qr"
				>
					{t("projectInformation.downloadQr")}
				</Button>
			</div>
			<Toggle
				name="published"
				label={t("projectInformation.micrositePublished")}
				data-cy="customer-form-published"
			/>
			<Alert variant="warning" data-cy="customer-alert">
				{t("projectInformation.alert")}
			</Alert>
		</div>
	)
}
