import { useMicrositeStore } from "@carbonbank/store"
import { formatDate } from "@carbonbank/utils"
import { Button, Grid, H1, icon } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

import { HmLogoImage } from "../assets"

export const CarbonCaptureAssurance: React.FC = () => {
	const { t } = useTranslation("microsite", {
		keyPrefix: "microsite.carbonAsurance",
	})
	const [certificate] = useMicrositeStore(state => [state.certificate])

	return (
		<>
			<H1
				className="mb-3 text-foreground_faded"
				data-cy="microsite-asurance-title"
			>
				{t("title")}
			</H1>
			<Grid size={1} lgSize={2} data-cy="microsite-asurance">
				<div className="flex flex-col gap-3 ">
					{certificate?.deposits.map(({ proof, timestamp }) => (
						<>
							<div className="h-[160px] w-[160px] bg-[#FF00FF]"></div>
							<div
								className="flex flex-col"
								data-cy="microsite-transactionId"
							>
								<p>{t("assuranceTransactionId")}</p>
								<p className="font-bold">{proof.identifier}</p>
							</div>
							<div
								className="flex flex-col"
								data-cy="microsite-issuer"
							>
								<p>{t("issued")}</p>
								<p className="font-bold">
									{formatDate(timestamp || "")}
								</p>
							</div>
							<div
								className="flex flex-col mt-2"
								data-cy="microsite-approver"
							>
								<p>{t("approver")}</p>
								<p className="font-bold">{t("approverDnv")}</p>
							</div>
						</>
					))}
				</div>
				<div className="flex flex-col gap-6">
					<p>{t("description")}</p>
					<p>{t("learnMore")}</p>
					<div className="flex items-center">
						<p className="font-bold">{t("assuranceGuidline")}</p>
						<Button
							variant="tertiary"
							icon={icon.mdiCallMade}
							className="text-black"
						></Button>
					</div>
				</div>
			</Grid>
			<div className="flex items-center justify-between">
				<img
					src={HmLogoImage}
					alt="hm-logo"
					className="h-[40px] w-[326px]"
				/>
				<div className="flex gap-3 cursor-pointer">
					<p>{t("dataProtection")}</p>
					<p>{t("legalDisclaimer")}</p>
					<p>{t("imprint")}</p>
				</div>
			</div>
		</>
	)
}
