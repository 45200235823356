import { LogoDnv } from "@carbonbank/assets"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	icon,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface DnvAssuranceInfoProps {
	openDnvInfo: boolean
	setOpenDnvInfo: (open: boolean) => void
}
export const DnvAssuranceModal: React.FC<DnvAssuranceInfoProps> = ({
	openDnvInfo,
	setOpenDnvInfo,
}) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.carbonDnvModal",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	return (
		<Modal
			open={openDnvInfo}
			onClose={() => setOpenDnvInfo(false)}
			data-cy="dnv_modal"
		>
			<ModalTitle data-cy="dnv-title">
				<div className="flex justify-between">
					{t("title")}
					<img
						src={LogoDnv}
						alt="dnvassurance"
						className="w-[52px] h-[28]"
					/>
				</div>
			</ModalTitle>
			<ModalContent data-cy="dnv-content">
				<TextRegular>
					{t("paragraph1")}
					<br />
					<br />
					{t("paragraph2")}
					<br />
					<br />
					{t("paragraph3")}
					<br />
					<br />
				</TextRegular>
			</ModalContent>
			<ModalFooter>
				<div className="flex gap-6">
					<Button
						variant="secondary"
						icon={icon.mdiOpenInNew}
						data-cy="dnv-show-assurance-button"
					>
						{t("showFullAssuranceFromDnv")}
					</Button>
					<Button
						onClick={() => setOpenDnvInfo(false)}
						data-cy="dnv-close-button"
					>
						{tCommon("close")}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
