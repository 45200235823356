import { getQueryStringByObject } from "@carbonbank/utils/query-string"

import { hconnectClient } from "../client"
import {
	OpenIdConfig,
	RevokeRefreshTokenRequest,
	CreationTokenPayload,
	TokenResponse,
} from "./types"

export const hconnectIdentityApi = {
	fetchOpenIdConfig: (): Promise<OpenIdConfig> =>
		hconnectClient
			.get("/.well-known/openid-configuration")
			.then(response => response.data),
	createToken: (payload: CreationTokenPayload): Promise<TokenResponse> =>
		hconnectClient
			.post(
				"/api/identity/connect/token",
				getQueryStringByObject(payload),
			)
			.then(response => response.data),
	revokeRefreshToken: (data: RevokeRefreshTokenRequest): Promise<void> =>
		hconnectClient.post("/api/identity/connect/revocation", data),
}
