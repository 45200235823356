import { correlationScope } from "@carbonbank/infrastructure/analytics"
import { CORRELATION_ID_HEADER } from "@carbonbank/infrastructure/analytics/constants"
import { InternalAxiosRequestConfig } from "axios"

export const correlationIdInterceptor = async (
	config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
	config.headers[CORRELATION_ID_HEADER] = correlationScope.get()

	return config
}
