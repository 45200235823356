import axios, { AxiosRequestConfig } from "axios"

import { tokenInterceptor } from "../interceptors"

export const authenticationUrl = import.meta.env.VITE_AUTH_API

const axiosConfig: AxiosRequestConfig = {
	baseURL: authenticationUrl,
	headers: {
		"Content-Type": "application/json",
	},
}

export const authenticationClient = axios.create(axiosConfig)

authenticationClient.interceptors.request.use(tokenInterceptor)
