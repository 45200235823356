import { i18n as fundamentalI18nInstance } from "@sustainability/fundamental"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import { globalTranslations } from "./assets"
import { carbonSavingsTranslations } from "./pages/carbon-savings/assets"
import { ModuleKeys as CarbonSavingsModuleKeys } from "./pages/carbon-savings/i18n"
import { ModuleKeys as CommonModuleKeys } from "./pages/common/i18n"
import { contractsTranslations } from "./pages/contracts/assets"
import { ModuleKeys as ContractsModuleKeys } from "./pages/contracts/i18n"
import { customer } from "./pages/customer/assets"
import { ModuleKeys as CustomersModuleKeys } from "./pages/customer/i18n"
import { microsite } from "./pages/microsite/assets"
import { ModuleKeys as MicrositeModuleKeys } from "./pages/microsite/i18n"
import { transactions } from "./pages/transactions/assets"
import { ModuleKeys as TransactionsModuleKeys } from "./pages/transactions/i18n"

const namespaces = [
	"cb",
	"cs",
	"contracts",
	"transactions",
	"microsite",
	"customer",
] as const
export const defaultNS: (typeof namespaces)[number] = "cb"
export const resources = {
	en: {
		cb: globalTranslations.en,
		cs: carbonSavingsTranslations.en,
		contracts: contractsTranslations.en,
		transactions: transactions.en,
		microsite: microsite.en,
		customer: customer.en,
	},
}

i18next.use(initReactI18next).init({
	resources,
	lng: "en",
	fallbackLng: "en",
	ns: namespaces,
	defaultNS,
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
	react: {
		useSuspense: true,
	},
})

i18next.on("languageChanged", lang => {
	fundamentalI18nInstance.changeLanguage(lang)
})

export type TranslationKeys =
	| CarbonSavingsModuleKeys
	| CommonModuleKeys
	| TransactionsModuleKeys
	| ContractsModuleKeys
	| CustomersModuleKeys
	| MicrositeModuleKeys

export default i18next
