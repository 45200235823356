export interface CycleSearchParams {
	plantName?: string
	page?: number
}

export enum CycleStatus {
	New = "New",
	Rejected = "Rejected",
	RequireMoreInformation = "RequireMoreInformation",
	AwaitingAssurance = "AwaitingAssurance",
	Assured = "Assured",
	Failed = "Failed",
	Deposited = "Deposited",
}

export interface CycleStepView {
	sensorId: string
	name: string
	timestamp: string
	totalSavings: number
	biogenic: number
	fossil: number
	unit: string
}

export interface Cycle {
	cycle: string
	plant: string
	status: CycleStatus
	Unit: string
	totalSavings: number
	totalBiogenic: number
	percentBiogenic: number
	totalFossil: number
	percentFossil: number
	buffer: number
	assuranceTimestamp?: string
	shipmentDate: string
	co2PumpedIntoStorageDate: string
	steps: CycleStepView[]
}

export interface CycleShortView {
	cycle: string
	plant: string
	year: string
	status: CycleStatus
	totalSavings: number
	totalBiogenic: number
	totalFossil: number
	buffer: number
	units: string
	shipmentDate: string
	co2PumpedIntoStorageDate: string
	assuranceTimestamp?: string
}

export interface CycleShortViewData {
	totalSavingsToBeAssured: number
	totalNewCycles: number
	totalCyclesInValidation: number
	cycles: CycleShortView[]
}

export interface RequestInformationDetails {
	assetNumber: string
	name?: string
	fullName?: string
	email?: string
	comment: string
	buttonLink: string
}

export interface ChangeCycleStatus {
	cycle: string
	status: CycleStatus
	serialNumber: string
	requestInformationDetails?: RequestInformationDetails
}
