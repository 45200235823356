import { InvoiceView } from "@carbonbank/api"
import {
	formatDate,
	formatNumberWithUnit,
	getTransactionHashScanLink,
	UnitType,
} from "@carbonbank/utils"
import {
	TableBase,
	TableHeader,
	TableHeaderCell,
	TableRow,
	TableCell,
	TextRegular,
	Button,
	icon,
	TableBody,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface DeliveriesTableProps {
	data: InvoiceView[] | undefined
}
export const DeliveriesTable: React.FC<DeliveriesTableProps> = ({ data }) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.DeliveriesTable",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common",
	})

	return (
		<TableBase data-cy="deliveries-list-table">
			<TableHeader data-cy="deliveries-list-table-header">
				<TableHeaderCell>{t("deliveryDate")}</TableHeaderCell>
				<TableHeaderCell>{t("sapOrderId")}</TableHeaderCell>
				<TableHeaderCell>{t("product")}</TableHeaderCell>
				<TableHeaderCell>{t("deliveredQuantity")}</TableHeaderCell>
				<TableHeaderCell>{tCommon("carbonSavings")}</TableHeaderCell>
				<TableHeaderCell>{t("transactionId")}</TableHeaderCell>
			</TableHeader>
			<TableBody>
				{data?.map(delivery => (
					<TableRow
						key={delivery.invoiceNumber}
						data-cy="deliveries-list-table-row"
					>
						<TableCell responsiveLabel={t("deliveryDate")}>
							<TextRegular className="max-md:font-medium">
								{formatDate(delivery?.deliveryDate)}
							</TextRegular>
						</TableCell>
						<TableCell
							className="max-md:hidden"
							responsiveLabel={t("sapOrderId")}
						>
							<TextRegular>{delivery?.sapOrderId}</TextRegular>
						</TableCell>
						<TableCell responsiveLabel={t("product")}>
							<TextRegular className="max-md:font-medium">
								{delivery?.product}
							</TextRegular>
						</TableCell>
						<TableCell responsiveLabel={t("deliveredQuantity")}>
							<TextRegular className="max-md:font-medium">
								{formatNumberWithUnit(
									delivery?.cementQuantity,
									UnitType.Ton,
								)}
							</TextRegular>
						</TableCell>
						<TableCell responsiveLabel={tCommon("carbonSavings")}>
							<TextRegular className="max-md:font-medium">
								{formatNumberWithUnit(
									delivery?.carbonSavingsQuantity,
									UnitType.KgCO2,
								)}
							</TextRegular>
						</TableCell>
						<TableCell className="text-primary underline max-md:hidden">
							<div className="flex justify-between">
								<a
									target="_blank"
									href={getTransactionHashScanLink(
										delivery?.transactionId,
									)}
									rel="noreferrer"
								>
									<TextRegular className="cursor-pointer">
										{delivery?.transactionId}
									</TextRegular>
								</a>
								<Button
									variant="tertiary"
									icon={icon.mdiChevronRight}
									className="h-full text-foreground_faded"
								/>
							</div>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</TableBase>
	)
}
