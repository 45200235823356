﻿import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const TransferTransactionFooter = (
	props: {
		transactionNumber: string
		verificationId: string
	} & NodeProps,
) => {
	return (
		<View
			style={{
				...styles.flex_col,
				...styles.background_grey,
				...styles.padding_10,
				...styles.gap_10,
			}}
		>
			<View style={{ ...styles.flex_row }}>
				<Text style={{ ...styles.text_regular }}>
					Carbon bank transaction number:{" "}
				</Text>
				<Text style={{ ...styles.text_regular_bold }}>
					{props.transactionNumber}
				</Text>
			</View>
			<View style={{ ...styles.flex_row }}>
				<Text style={{ ...styles.text_regular }}>
					Verification ID:{" "}
				</Text>
				<Text style={{ ...styles.text_regular_bold }}>
					{props.verificationId}
				</Text>
			</View>
		</View>
	)
}
