import { carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	TransactionOverviewSearchParams,
	TransactionOverviewData,
	ReportDataSearchParams,
	YearlySavingsReport,
	UpdateBalanceInput,
	AssureYearlyBalancePayload,
	AssuredYearlyBalanceResponse,
} from "./types"

export const yearlySavingsApi = {
	getTransactionsData: (
		params: TransactionOverviewSearchParams,
	): Promise<TransactionOverviewData> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/transactions", { ...params }))
			.then(response => response.data),
	getReportData: (
		params: ReportDataSearchParams,
	): Promise<YearlySavingsReport> =>
		carbonBankWithAuthClient
			.get(generateGetUrl("/transactions/details", { ...params }))
			.then(response => response.data),
	updateBalance: (data: UpdateBalanceInput): Promise<string> =>
		carbonBankWithAuthClient
			.post("/transactions", data)
			.then(response => response.data),
	assureYearlyBalance: (
		payload: AssureYearlyBalancePayload,
	): Promise<AssuredYearlyBalanceResponse> =>
		carbonBankWithAuthClient
			.put(generateGetUrl("/transactions", { ...payload }))
			.then(response => response.data),
}
