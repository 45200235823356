import { CycleStatus } from "@carbonbank/api"
import { ScenarioVariant } from "@carbonbank/store"
import { Button } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface PageHeaderButtonsProps {
	status: CycleStatus
	onScenario: (scenario: ScenarioVariant) => void
}

export const PageHeaderButtons: React.FC<PageHeaderButtonsProps> = ({
	status,
	onScenario,
}: PageHeaderButtonsProps) => {
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})

	return (
		<div className="flex gap-x-3" data-cy="action-buttons">
			<Button
				size="large"
				onClick={() => {
					onScenario("assure")
				}}
			>
				{tCommon("assure")}
			</Button>
			{status === CycleStatus.RequireMoreInformation ? (
				<Button
					size="large"
					variant="negative"
					onClick={() => {
						onScenario("reject")
					}}
				>
					{tCommon("reject")}
				</Button>
			) : (
				<Button
					size="large"
					variant="secondary"
					onClick={() => {
						onScenario("requestInformation")
					}}
				>
					<span className="hidden md:inline-block">
						{tCommon("requestInformation")}
					</span>
					<span className="inline-block md:hidden">...</span>
				</Button>
			)}
		</div>
	)
}
