import { useContractsStore } from "@carbonbank/store"
import { formatNumber } from "@carbonbank/utils"
import {
	DashboardCard,
	LabelRegular,
	Input,
	DragAndDropFiles,
	Label,
	CardHeader,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"

import { TransferFormInputs } from "../../types"
import { CarbonTransferReceiver } from "./CarbonTransferReceiver"

export const CarbonTransferDataFormCard: React.FC = () => {
	const contract = useContractsStore(state => state.contract)

	const { values } = useFormikContext<TransferFormInputs>()

	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferForm",
	})
	const isPartialTransfer =
		values?.cementAmount &&
		contract?.availableToTransfer &&
		(contract.availableToTransfer < contract?.accumulatedQuantity ||
			values.cementAmount < contract.targetQuantity)

	return (
		<DashboardCard
			className="flex flex-col"
			data-cy="carbon-transfer-data-entry"
		>
			<CardHeader>{t("title")}</CardHeader>
			<div className="flex flex-col gap-3">
				<Label title={t("product")}>{contract?.declaredProduct}</Label>
				<div className="flex gap-4">
					<div className="max-w-[272px]">
						<Input
							data-cy="cement-amount-input"
							label={t("amount")}
							name="cementAmount"
						/>
					</div>
					<Label title={t("totalAvailableAmount")}>
						{formatNumber(contract?.availableToTransfer ?? 0)}
					</Label>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<LabelRegular className="mt-6 text-foreground_faded text-[16px] leading-6">
					{t("uploadEpdText")}
				</LabelRegular>
				{!!isPartialTransfer && (
					<DragAndDropFiles
						name="epdFile"
						className="w-[500px]"
						data-cy="upload-epd"
					/>
				)}
			</div>
			<CarbonTransferReceiver />
			<LabelRegular className="mt-6 text-foreground_faded text-[16px] leading-6">
				{t("privacyNotice")}
			</LabelRegular>
		</DashboardCard>
	)
}
