import {
	BalanceAdjustmentData,
	BufferAdjustmentData,
	CarbonSourceType,
	TransactionType,
	YearlyTransactionItem,
} from "@carbonbank/api"
import {
	formatNumber,
	formatTimestamp,
	getTransactionHashScanLink,
} from "@carbonbank/utils"
import { carbonSourceTypeToTitleTypeMap } from "@carbonbank/utils/carbonSourseUtils"
import {
	useSortableTable,
	TextRegular,
	ExternalLink,
	TextMedium,
	clx,
	DropdownWithButton,
	icon,
	DropdownItem,
	DashboardCard,
	CardHeader,
	Table,
	useModal,
} from "@sustainability/fundamental"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { BalanceAdjustmentModal } from "./BalanceAdjustmentModal"
import { BufferAdjustmentModal } from "./BufferAdjustmentModal"
import { TransactionTypePill } from "./TransactionTypePill"

interface TransactionsCardProps {
	data: YearlyTransactionItem[]
	dataYear: number
	carbonSourceType: CarbonSourceType
}

export const TransactionsCard: React.FC<TransactionsCardProps> = ({
	data,
	dataYear,
	carbonSourceType,
}: TransactionsCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "yearlyAssurance.components.transactionsCard",
	})

	const {
		isOpen: isBalanceAdjustmentModalOpen,
		closeModal: closeBalanceAdjustmentModal,
		openModal: openBalanceAdjustmentModal,
	} = useModal()
	const [balanceAdjustmentData, setBalanceAdjustmentData] =
		useState<BalanceAdjustmentData>({
			carbonSavings: { amount: 0, unit: "kg" },
			action: "John Doe",
			details: "This should be a comment content",
		})

	const {
		isOpen: isBufferAdjustmentModalOpen,
		closeModal: closeBufferAdjustmentModal,
		openModal: openBufferAdjustmentModal,
	} = useModal()
	const [bufferAdjustmentData, setBufferAdjustmentData] =
		useState<BufferAdjustmentData>()

	const config = useSortableTable<YearlyTransactionItem>(
		[
			{
				id: "timestamp",
				label: t("timestamp"),
				render: ({ timestamp }) => (
					<TextRegular>{formatTimestamp(timestamp)}</TextRegular>
				),
			},
			{
				id: "transactionType",
				label: t("transactionType"),
				render: ({ transactionType }) => (
					<TransactionTypePill transactionType={transactionType} />
				),
			},
			{
				id: "proof",
				label: t("transactionId"),
				showBreakpoint: "lg",
				sortable: false,
				render: ({ proof }) =>
					proof ? (
						<ExternalLink
							href={getTransactionHashScanLink(proof.identifier)}
						>
							{proof.identifier}
						</ExternalLink>
					) : (
						<>-</>
					),
			},
			{
				id: "carbonSavings",
				label: t("carbonSavings"),
				headerClassName: "text-left md:text-right",
				valueClassName: "text-left md:text-right",
				render: ({
					bufferAdjustmentData,
					carbonSavings,
					transactionType,
				}) => (
					<>
						{bufferAdjustmentData && (
							<TextMedium>
								{t("savingsBufferAdjustment", {
									fossilFrom: formatNumber(
										bufferAdjustmentData.from.fossil,
									),
									biogenicFrom: formatNumber(
										bufferAdjustmentData.from.biogenic,
									),
									fossilTo: formatNumber(
										bufferAdjustmentData.to.fossil,
									),
									biogenicTo: formatNumber(
										bufferAdjustmentData.to.biogenic,
									),
								})}
							</TextMedium>
						)}
						{!bufferAdjustmentData && (
							<TextMedium
								className={clx(
									transactionType ===
										TransactionType.BufferDeposit
										? false
										: carbonSavings > 0
											? "text-positive"
											: "text-negative",
								)}
							>
								{formatNumber(carbonSavings)}
							</TextMedium>
						)}
					</>
				),
			},
			{
				sortable: false,
				label: t("actions.title"),
				valueClassName: "text-left md:text-right",
				headerClassName: "text-left md:text-right",
				render: ({
					transactionType,
					proof,
					balanceAdjustedValue,
					commentDetails,
					bufferAdjustmentData,
				}) => (
					<DropdownWithButton
						data-cy="action-trigger"
						label=""
						align="right"
						buttonProps={{
							square: true,
							variant: "secondary",
							icon: icon.mdiDotsVertical,
							size: "small",
						}}
					>
						{proof && (
							<DropdownItem
								title={t("actions.showTransaction")}
								onClick={() => {
									window.open(
										getTransactionHashScanLink(
											proof.identifier,
										),
									)
								}}
							/>
						)}
						{transactionType ===
							TransactionType.BufferAdjustment && (
							<DropdownItem
								title={t("actions.showBufferAdjustment")}
								onClick={() => {
									openBufferAdjustmentModal()
									setBufferAdjustmentData(
										bufferAdjustmentData,
									)
								}}
							/>
						)}
						{transactionType ===
							TransactionType.BalanceAdjustment &&
							commentDetails && (
								<DropdownItem
									title={t("actions.showComment")}
									onClick={() => {
										openBalanceAdjustmentModal()
										setBalanceAdjustmentData({
											carbonSavings: {
												amount: balanceAdjustedValue!,
												unit: "kg",
											},
											action: commentDetails.username,
											details: commentDetails.content,
										})
									}}
								/>
							)}
					</DropdownWithButton>
				),
			},
		],
		undefined,
		undefined,
		{ enableLocalSorting: true },
	)

	const carbonSourceTitleType =
		carbonSourceTypeToTitleTypeMap[carbonSourceType]
	const cardTitle = t(`titles.${carbonSourceTitleType}`, {
		count: data.length,
	})

	return (
		<DashboardCard data-cy="transactions-list">
			<CardHeader data-cy="title">{cardTitle}</CardHeader>
			<Table data-cy="transactions-table" config={config} data={data} />
			<BalanceAdjustmentModal
				isOpen={isBalanceAdjustmentModalOpen}
				onClose={closeBalanceAdjustmentModal}
				data={balanceAdjustmentData}
			/>
			<BufferAdjustmentModal
				isOpen={isBufferAdjustmentModalOpen}
				onClose={closeBufferAdjustmentModal}
				dataYear={dataYear}
				data={bufferAdjustmentData}
			/>
		</DashboardCard>
	)
}
