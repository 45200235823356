import CementManufacturing from "./cementManufacturing.png"
import ConcreteProduct from "./concreteProduct.png"
import DnvAssuranceTransaction from "./dnvAssuranceTransaction.png"
import LogoDnvImport from "./dnvassurance.png"
import EvoBuildImport from "./evoBuild.png"
import EvoZeroImport from "./evozero.png"
import FinalOwner from "./finalOwner.png"
import translationsEn from "./locales/en-US.json"
import NoContractsImport from "./nocontracts.png"
import NotFound from "./notFoundPage.png"
import PermanentStorage from "./permanentStorage.png"
import ReceivingTerminal from "./receivingTerminal.png"
import SucessTransferImport from "./sucessTransfer.png"
import TemporaryStorage from "./temporaryStorage.png"
import TransactionsImage from "./transactionOverView.png"
import TransportPreparation from "./transportPreparation.png"
import TransportViaShip from "./transportViaShip.png"

export const CementManufacturingImage = CementManufacturing
export const TemporaryStorageImage = TemporaryStorage
export const TransportPreparationImage = TransportPreparation
export const TransportViaShipImage = TransportViaShip
export const ReceivingTerminalImage = ReceivingTerminal
export const PermanentStorageImage = PermanentStorage
export const DnvAssuranceTransactionImage = DnvAssuranceTransaction
export const ConcreteProductImage = ConcreteProduct
export const FinalOwnerImage = FinalOwner
export const LogoDnv = LogoDnvImport
export const LogoEvoZero = EvoZeroImport
export const NoContracts = NoContractsImport
export const SucessTransfer = SucessTransferImport
export const Transactions = TransactionsImage
export const LogoEvoBuild = EvoBuildImport
export const NotFoundPage = NotFound
export const globalTranslations = {
	en: translationsEn,
}
