import { CycleStatus } from "@carbonbank/api"
import { Pill } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface CycleStatusPillProps {
	status: CycleStatus
}

export const CycleStatusPill: React.FC<CycleStatusPillProps> = ({
	status,
}: CycleStatusPillProps) => {
	const { t } = useTranslation("cb", {
		keyPrefix: "common.cycleStatus",
	})
	const cycleStatusToPillPropsMap: Record<
		CycleStatus,
		{
			type: React.ComponentProps<typeof Pill>["type"]
			text: string
		}
	> = {
		[CycleStatus.New]: {
			type: "neutral",
			text: t("new"),
		},
		[CycleStatus.AwaitingAssurance]: {
			type: "warning",
			text: t("awaitingAssurance"),
		},
		[CycleStatus.Rejected]: {
			type: "negative",
			text: t("rejected"),
		},
		[CycleStatus.Assured]: {
			type: "positive",
			text: t("assured"),
		},
		[CycleStatus.Deposited]: {
			type: "positive",
			text: t("assured"),
		},
		[CycleStatus.Failed]: {
			type: "negative",
			text: t("failed"),
		},
		[CycleStatus.RequireMoreInformation]: {
			type: "warning",
			text: t("inValidation"),
		},
	}

	const { text, type } = cycleStatusToPillPropsMap[status]

	return <Pill text={text} type={type} variant="shade" shape="rounded" />
}
