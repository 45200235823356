import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	icon,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface CarbonCaptureInfoProps {
	openCarbonInfo: boolean
	setOpenCarbonInfo: (open: boolean) => void
}
export const CarbonCaptureModal: React.FC<CarbonCaptureInfoProps> = ({
	openCarbonInfo,
	setOpenCarbonInfo,
}) => {
	const { t } = useTranslation("cb", {
		keyPrefix: "common",
	})
	const { t: tCommon } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.carbonStorageModal",
	})
	return (
		<Modal
			open={openCarbonInfo}
			onClose={() => setOpenCarbonInfo(false)}
			data-cy="carbonsavings_modal"
		>
			<ModalTitle data-cy="carbonsavings-title">
				{tCommon("title")}
			</ModalTitle>
			<ModalContent data-cy="carbonsavings-content">
				<TextRegular>{tCommon("text")}</TextRegular>
			</ModalContent>
			<ModalFooter>
				<div className="flex gap-3">
					<Button
						variant="secondary"
						icon={icon.mdiOpenInNew}
						data-cy="carbonsavings-learn-more-button"
					>
						{tCommon("learnMoreOnCarbonCapturing")}
					</Button>
					<Button
						onClick={() => setOpenCarbonInfo(false)}
						data-cy="carbonsavings-close-button"
					>
						{t("close")}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
