import {
	CementManufacturingImage,
	TemporaryStorageImage,
	TransportPreparationImage,
	TransportViaShipImage,
	ReceivingTerminalImage,
	PermanentStorageImage,
	DnvAssuranceTransactionImage,
	ConcreteProductImage,
	FinalOwnerImage,
} from "@carbonbank/assets"

import { ModuleKeys } from "../../i18n"

const translationPrefix = "transactionDetails.components.transactionsHistory"

type TransactionHistoryCardListConstantType = {
	title: ModuleKeys
	image: string
	description: ModuleKeys
}

export const TransactionHistoryCardListConstant: TransactionHistoryCardListConstantType[] =
	[
		{
			title: `${translationPrefix}.firstTitle`,
			image: CementManufacturingImage,
			description: `${translationPrefix}.firstDescription`,
		},
		{
			title: `${translationPrefix}.secondTitle`,
			image: TemporaryStorageImage,
			description: `${translationPrefix}.secondDescription`,
		},
		{
			title: `${translationPrefix}.thirdTitle`,
			image: TransportPreparationImage,
			description: `${translationPrefix}.thirdDescription`,
		},
		{
			title: `${translationPrefix}.fourthTitle`,
			image: TransportViaShipImage,
			description: `${translationPrefix}.fourthDescription`,
		},
		{
			title: `${translationPrefix}.fifthTitle`,
			image: ReceivingTerminalImage,
			description: `${translationPrefix}.fifthDescription`,
		},
		{
			title: `${translationPrefix}.sixthTitle`,
			image: PermanentStorageImage,
			description: `${translationPrefix}.sixthDescription`,
		},
		{
			title: `${translationPrefix}.seventhTitle`,
			image: DnvAssuranceTransactionImage,
			description: `${translationPrefix}.seventhDescription`,
		},
		{
			title: `${translationPrefix}.eighthTitle`,
			image: ConcreteProductImage,
			description: `${translationPrefix}.eighthDescription`,
		},
		{
			title: `${translationPrefix}.ninethTitle`,
			image: FinalOwnerImage,
			description: `${translationPrefix}.ninethDescription`,
		},
	]
