import { CycleStepView } from "@carbonbank/api"
import { formatTimestamp, formatNumberWithUnit } from "@carbonbank/utils"
import {
	DashboardCard,
	CardHeader,
	TableBase,
	TableHeader,
	TableHeaderCell,
	TableRow,
	TableCell,
	TextMedium,
	TextRegular,
	Label,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface SensorCardProps {
	steps: CycleStepView[]
}

export const SensorCard: React.FC<SensorCardProps> = ({
	steps,
}: SensorCardProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "carbonSavingsDetails.components.sensorCard",
	})

	return (
		<DashboardCard data-cy="sensor-data">
			<CardHeader data-cy="title">{t("title")}</CardHeader>
			<TableBase data-cy="sensor-table">
				<TableHeader>
					<TableHeaderCell>{t("step")}</TableHeaderCell>
					<TableHeaderCell>{t("sensorId")}</TableHeaderCell>
					<TableHeaderCell className="text-right">
						{t("lco2Measured")}
					</TableHeaderCell>
				</TableHeader>
				{steps.map(({ sensorId, name, timestamp, totalSavings }) => (
					<TableRow
						data-cy={`sensor-row-${sensorId}`}
						key={`${name}-${sensorId}`}
					>
						<TableCell>
							<Label title={formatTimestamp(timestamp)} inverted>
								{name}
							</Label>
						</TableCell>
						<TableCell>
							<TextRegular>{sensorId}</TextRegular>
						</TableCell>
						<TableCell className="text-nowrap">
							<div
								className={
									"flex gap-x-1 items-center justify-end"
								}
							>
								<TextMedium>
									{formatNumberWithUnit(totalSavings)}
								</TextMedium>
							</div>
						</TableCell>
					</TableRow>
				))}
			</TableBase>
		</DashboardCard>
	)
}
