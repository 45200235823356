import { TransferCertificate } from "@carbonbank/api"
import { formatNumber } from "@carbonbank/utils"
import {
	TextKpiMedium,
	TableBase,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
	TextMedium,
} from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface CarbonSavingTableProps {
	certificate?: TransferCertificate
}
export const TransactionCarbonSavingTable: React.FC<CarbonSavingTableProps> = ({
	certificate,
}) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "contractDetails.components.carbonSavingTable",
	})
	const { t: Common } = useTranslation("cb", {
		keyPrefix: "common",
	})
	return (
		<TableBase data-cy="carbon-savings-details-table">
			<TableHeader data-cy="carbon-savings-details-header">
				<TableHeaderCell>{t("coreIndicator")}</TableHeaderCell>
				<TableHeaderCell className="text-end">
					{Common("value")} (kg CO2-eq)
				</TableHeaderCell>
			</TableHeader>
			<TableRow>
				<TableCell className="text-green-700">
					{t("evoZeroCarbonSavingsOfProduct")}
				</TableCell>
				<TableCell className="h-1/2">
					<div className="text-right">
						<TextKpiMedium className="text-primary">
							{formatNumber(
								certificate?.totalSavings.quantity ?? 0,
							)}
						</TextKpiMedium>
					</div>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<div className="ml-6">
						<TextMedium>{t("carbonRemoval")}</TextMedium>
					</div>
				</TableCell>
				<TableCell className="text-end">{0}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<div className="ml-6">
						<TextMedium>{t("carbonReduction")}</TextMedium>
					</div>
				</TableCell>
				<TableCell className="text-end">
					{formatNumber(certificate?.fossil.quantity ?? 0)}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<TextMedium>{t("totalDeliveredProduct")}</TextMedium>
				</TableCell>
				<TableCell className="text-end">
					{formatNumber(certificate?.gwpTotal.quantity ?? 0)}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<TextMedium>{t("eveZeroCarbonCaptured")}</TextMedium>
				</TableCell>
				<TableCell className="text-end">
					{formatNumber(certificate?.gwpCarbonCaptured.quantity ?? 0)}
				</TableCell>
			</TableRow>
		</TableBase>
	)
}
