import { Button } from "@sustainability/fundamental"
import React from "react"
import { useTranslation } from "react-i18next"

interface PageHeaderButtonsProps {
	isValid: boolean
}
export const PageHeaderButtons: React.FC<PageHeaderButtonsProps> = ({
	isValid,
}) => {
	const { t } = useTranslation("customer", {
		keyPrefix: "customer",
	})
	return (
		<div
			className="flex gap-3 items-center"
			data-cy="customer-page-buttons"
		>
			<Button variant="secondary" data-cy="customer-button-cancel">
				{t("cancel")}
			</Button>
			<Button
				variant="primary"
				disabled={!isValid}
				type="submit"
				data-cy="customer-button-save"
			>
				{t("saveChanges")}
			</Button>
		</div>
	)
}
