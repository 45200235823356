export const waitResolve = <T>(resolveValue: T, time = 250) => {
	return new Promise<T>(res => setTimeout(() => res(resolveValue), time))
}

export function generateGetUrl(
	baseUrl: string,
	params?: { [key: string]: unknown },
) {
	const paramsString = params
		? new URLSearchParams(
				Object.entries(params).map(([k, v]) => [k, v + ""]),
			).toString()
		: ""

	return `${baseUrl}${paramsString ? `?${paramsString}` : ""}`
}
