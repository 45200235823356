import { ContainerBox, LoadingPage, NoDataFound } from "@carbonbank/components"
import { useTransactionStore } from "@carbonbank/store"
import { transactionIdFormatter } from "@carbonbank/utils"
import {
	Button,
	Grid,
	H3,
	PageHeader,
	icon,
	useModal,
} from "@sustainability/fundamental"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { getTransactionsOverviewUrl } from "../transactionsRoutes"
import {
	InformationModal,
	TransactionBundledProduct,
	TransactionCarbonSavings,
	TransactionDeclaredProduct,
	TransactionDetailsStats,
	VerificationModal,
} from "./components"
import { TransactionHistoryCardList } from "./components"
import { TransactionDnvAssurance } from "./components/TransactionDnvAssurance"

export const TransactionDetails: React.FC = () => {
	const navigate = useNavigate()
	const [contentKey, setContentKey] = useState<
		"" | "accountability" | "traceability"
	>("")
	const [openInformationModal, setInformationModal] = useState(false)
	const { isOpen, closeModal, openModal } = useModal(false)

	const { t } = useTranslation("transactions", {
		keyPrefix: "transactionDetails",
	})
	const [certificate, fetchTransactionDetails, isLoading, error] =
		useTransactionStore(state => [
			state.certificate,
			state.fetchTransactionDetails,
			state.isLoading,
			state.error,
		])
	const { id } = useParams()
	const formattedId = id ? transactionIdFormatter(id) : ""
	useEffect(() => {
		if (formattedId !== undefined) {
			fetchTransactionDetails(formattedId)
		}
	}, [fetchTransactionDetails, formattedId])
	if (isLoading) {
		return (
			<LoadingPage
				backToPageUrlOnClick={() =>
					navigate(getTransactionsOverviewUrl())
				}
				backToPageLabel={t("backToTransactionOverView")}
			/>
		)
	}
	if (error) {
		return (
			<NoDataFound
				title={t("transactionNotFound")}
				description={t("transactionNotFoundDecription")}
				buttonTtitle={t("transactionNotFoundButton")}
				backToPageUrl={getTransactionsOverviewUrl()}
			/>
		)
	}

	return (
		<ContainerBox>
			<PageHeader
				backToPageUrlOnClick={() =>
					navigate(getTransactionsOverviewUrl())
				}
				backToPageLabel={t("backToTransactionOverView")}
				aside={
					<Button
						variant="secondary"
						onClick={openModal}
						data-cy="verification-button"
					>
						{t("verificationButton")}
					</Button>
				}
				data-cy="transaction-details-page-header"
			>
				{t("title")}
			</PageHeader>
			<TransactionDetailsStats
				className="mb-24px"
				date={certificate?.transaction.timestamp}
				transactionNumber={certificate?.transaction.proof.identifier}
				carbonSavings={certificate?.totalSavings.quantity}
			/>
			<div
				className="flex flex-col gap-6 mb-4"
				data-cy="transaction-details-page"
			>
				<Grid
					size={1}
					lgSize={2}
					className="px-0px"
					data-cy="transaction-details-data"
				>
					<TransactionDeclaredProduct
						data-cy="contract-details-declaredproduct"
						certificate={certificate}
					/>
					<TransactionBundledProduct
						data-cy="contract-details-bundled"
						certificate={certificate}
					/>
				</Grid>
				<TransactionCarbonSavings certificate={certificate} />
				<TransactionDnvAssurance certificate={certificate} />
				<div className="flex justify-between px-6">
					<H3
						data-cy="transaction-history-title"
						className="flex items-center"
					>
						{t("carbonBankTransactionHistory")}
					</H3>
					<div className="flex gap-3">
						<Button
							variant="tertiary"
							icon={icon.mdiInformationOutline}
							data-cy="ensuring-accountability-button"
							onClick={() => {
								setContentKey("accountability")
								setInformationModal(true)
							}}
						>
							{t("ensuringAccountability")}
						</Button>
						<Button
							variant="tertiary"
							icon={icon.mdiInformationOutline}
							data-cy="traceability-button"
							onClick={() => {
								setContentKey("traceability")
								setInformationModal(true)
							}}
						>
							{t("traceability")}
						</Button>
					</div>
				</div>
				<TransactionHistoryCardList
					emissions={certificate?.emissions ?? []}
				/>
				<VerificationModal isOpen={isOpen} onClose={closeModal} />
				<InformationModal
					openModal={openInformationModal}
					setOpenModal={setInformationModal}
					contentKey={contentKey}
				/>
			</div>
		</ContainerBox>
	)
}
