import { formatNumber } from "@carbonbank/utils"
import {
	ModalContent,
	TextRegular,
	Kpi,
	Input,
	Button,
	ModalFooter,
	CardSubHeader,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

interface StepFillProps {
	bufferBalance: number
	onCancel: () => void
	onConfirm: () => void
}

export const StepFill: React.FC<StepFillProps> = ({
	bufferBalance,
	onCancel,
	onConfirm,
}: StepFillProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureBalanceModal.formShared",
	})
	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})
	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const { dirty, isValid } = useFormikContext()

	const balanceUnit = tUnits("kgCO2")

	return (
		<>
			<ModalContent>
				<div className="flex flex-col gap-y-6">
					<TextRegular
						data-cy="description-fill"
						className="text-foreground_faded"
					>
						{t("descriptionFill")}
					</TextRegular>
					<div>
						<CardSubHeader data-cy="buffer-title">
							{t("bufferTitle")}
						</CardSubHeader>
						<Kpi
							data-cy="buffer-balance"
							size="small"
							label={t("bufferBalanceLabel")}
							value={formatNumber(bufferBalance)}
							unit={balanceUnit}
							valueClassName={
								bufferBalance < 0
									? "text-negative"
									: "text-positive"
							}
						/>
					</div>
					<div>
						<CardSubHeader data-cy="documentation-title">
							{t("assuranceDocumentationTitle")}
						</CardSubHeader>
						<Input
							label={t("serialNumberLabel")}
							name="serialNumber"
							data-cy="serial-number"
							autoComplete="off"
						/>
					</div>
				</div>
			</ModalContent>
			<ModalFooter>
				<Button
					data-cy="cancel"
					type="button"
					variant="secondary"
					onClick={onCancel}
				>
					{tCommon("cancel")}
				</Button>
				<Button
					data-cy="submit"
					type="button"
					variant="primary"
					onClick={onConfirm}
					disabled={!dirty || !isValid}
				>
					{tCommon("review")}
				</Button>
			</ModalFooter>
		</>
	)
}
