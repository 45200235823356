﻿import { QrCodeVerificationSection } from "@carbonbank/pdf/certificates/transfer/components/QrCodeVerificationSection.tsx"
import { TransactionVerificationSection } from "@carbonbank/pdf/certificates/transfer/components/TransactionVerificationSection.tsx"
import { CertificateSectionHeader } from "@carbonbank/pdf/certificates/transfer/components/transferPdfCommons.tsx"
import { styles } from "@carbonbank/pdf/pdfStyles.ts"
import { NodeProps, Text, View } from "@react-pdf/renderer"
import React from "react"

export const CarbonSavingsVerificationSection = (
	props: {
		verificationNumber: string
		transactionLink: string
		certificateTransactionNumber: string
	} & NodeProps,
) => {
	return (
		<View style={{ ...styles.gap_10 }}>
			<CertificateSectionHeader content="Verify the carbon savings" />
			<Text
				style={{ ...styles.text_bold_big, ...styles.text_color_grey }}
			>
				Step 1: View the transaction
			</Text>
			<QrCodeVerificationSection
				transactionLink={props.transactionLink}
				certificateTransactionNumber={
					props.certificateTransactionNumber
				}
			/>
			<Text
				style={{ ...styles.text_bold_big, ...styles.text_color_grey }}
			>
				Step 2: Verify the transaction and confirm ownership
			</Text>
			<TransactionVerificationSection
				verificationNumber={props.verificationNumber}
			/>
		</View>
	)
}
