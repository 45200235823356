export const downloadCsv = (fileName: string, data: unknown[][]) => {
	const fileData = data.map(r => r.join(";")).join("\n")

	const blob = new Blob([fileData], { type: "text/plain" })
	const url = URL.createObjectURL(blob)

	const link = document.createElement("a")
	link.download = fileName
	link.href = url
	link.click()
}
