import {
	Comment,
	CreateCommentRequest,
	commentsApi,
	GetCommentsRequest,
	CommentStatus,
	CommentType,
} from "@carbonbank/api"
import {
	convertFormattedTimestampToDate,
	formatTimestamp,
} from "@carbonbank/utils"
import {
	commentPillTextToCommentStatus,
	commentStatusToPillPropsMap,
} from "@carbonbank/utils/commentUtils"
import { CommentProps, spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	comments: CommentProps[]
	relatedEntityId: string
	type: CommentType
}
type Actions = {
	fetchComments: (params: GetCommentsRequest) => Promise<void>
	submitComment: (comment: CreateCommentRequest) => Promise<void>
	updateComment: (comment: CommentProps, content: string) => Promise<void>
	deleteComment: (id: number) => Promise<void>
	defineCommentsType: (commentType: CommentType) => void
}

export const useCommentsStore = create<State & Actions>()(
	immer((set, get) => ({
		comments: [],
		relatedEntityId: "",
		type: CommentType.None,
		fetchComments: async (params: GetCommentsRequest) => {
			try {
				const response = await commentsApi.getComments(params)

				const comments =
					response
						.sort((x: Comment, y: Comment) => {
							return (
								new Date(y.createdAt).getTime() -
								new Date(x.createdAt).getTime()
							)
						})
						.map((item): CommentProps => {
							const pillDefinition =
								commentStatusToPillPropsMap[item.status]

							return {
								...item,
								name: item.username,
								date: formatTimestamp(item.createdAt),
								pill: pillDefinition && {
									type: pillDefinition.type,
									text: i18next.t(pillDefinition.text),
								},
							}
						}) || []

				set(state => {
					state.comments = comments
					state.relatedEntityId = params.relatedEntityId
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch comments")
			}
		},
		submitComment: async (comment: CreateCommentRequest) => {
			try {
				let oldComment: Comment | undefined
				const oldCommentProps = get().comments.find(
					x =>
						x.pill?.text &&
						commentPillTextToCommentStatus[x.pill.text] ==
							CommentStatus.New,
				)

				if (oldCommentProps) {
					oldComment = {
						id: oldCommentProps.id,
						relatedEntityId: get().relatedEntityId,
						username: oldCommentProps.name,
						createdAt: convertFormattedTimestampToDate(
							oldCommentProps.date,
						).toISOString(),
						content: oldCommentProps.content,
						type: get().type,
						status: CommentStatus.None,
					}
				}

				await Promise.all([
					commentsApi.createComment(comment),
					oldComment
						? commentsApi.updateComment(oldComment)
						: Promise.resolve(),
				])
			} catch (error) {
				spawnErrorToast("Failed to submit comment")
			}
		},
		updateComment: async (commentProps: CommentProps, content: string) => {
			try {
				const comment: Comment = {
					id: commentProps.id,
					relatedEntityId: get().relatedEntityId,
					content: content,
					username: commentProps.name,
					createdAt: convertFormattedTimestampToDate(
						commentProps.date,
					).toISOString(),
					status: commentProps.pill?.text
						? commentPillTextToCommentStatus[
								commentProps.pill?.text
							]
						: CommentStatus.None,
					type: get().type,
				}

				await commentsApi.updateComment(comment)

				const comments = get().comments
				const updatedComments = comments?.map(item =>
					item.id === comment.id
						? { ...item, content: content }
						: item,
				)

				set(state => {
					state.comments = updatedComments
				})
			} catch (error) {
				spawnErrorToast("Failed to edit comment")
			}
		},
		deleteComment: async (id: number) => {
			try {
				await commentsApi.deleteComment(id)

				set(state => {
					state.comments = state.comments.filter(x => x.id !== id)
				})
			} catch (error) {
				spawnErrorToast("Failed to delete comment")
			}
		},
		defineCommentsType: (commentType: CommentType) => {
			set(status => {
				status.type = commentType
			})
		},
	})),
)
