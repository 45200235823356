import { ProductFilterData } from "../../types"

const productFilterDataMock: ProductFilterData[] = [
	{
		productId: "1",
		productName: "evoZero",
	},
	{
		productId: "2",
		productName: "Product 1",
	},
]

export default productFilterDataMock
