import { initChartJs } from "@carbonbank/initChartJs"
import { useAuthStore } from "@carbonbank/store"
import {
	AnalyticsShell,
	NOT_AVAILABLE,
} from "@sustainability/analytics-frontend"
import {
	Navigation,
	NavigationLogo,
	LogoCarbonBank,
	NavigationTabsProps,
} from "@sustainability/fundamental"
import React, { useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { Profile } from "./UserProfile/Profile"

interface PageLayoutProps {
	productName: string
	navigation: NavigationTabsProps[]
	children?: React.ReactNode
}

export const PageLayout: React.FC<PageLayoutProps> = ({
	children,
	navigation,
	productName,
}) => {
	const navigate = useNavigate()
	useLayoutEffect(() => {
		initChartJs()
	}, [])

	const { i18n } = useTranslation()

	const [loginState] = useAuthStore(store => [store.loginState])
	const location = useLocation()

	const navigationTabs = navigation.map(t => ({
		...t,
		onClick: () => {
			navigate(t.url)
		},
		active: location.pathname === t.url,
	}))

	return (
		<div className="bg-page_background">
			<div className="w-full max-w-[1920px] min-h-screen mx-auto flex flex-col">
				<Navigation
					rightSideButtons={<Profile key="profile" />}
					rightSideButtonsMobile={<Profile key="profile" />}
					navigationTabs={navigationTabs}
					data-cy="navigation-component"
				>
					<Link to="/">
						<NavigationLogo
							image={LogoCarbonBank}
							alt={"Logo Carbon Bank"}
						>
							{productName}
						</NavigationLogo>
					</Link>
				</Navigation>
				<AnalyticsShell
					configuration={{
						appName: import.meta.env.VITE_APP_NAME,
						appEnvironment: import.meta.env.MODE,
						trackPageChanges: true,
						locationKey: location.pathname + location.search,
						allowTracking: true,
						anonymizeSensitiveData: true,
						connectionString: import.meta.env
							.VITE_ANALYTICS_CONNECTION_STRING,
						includeEventTimestamp: true,
						maxBatchInterval: 10000,
					}}
					eventProperties={{
						product: import.meta.env.VITE_APP_NAME,
						userId: loginState.loggedIn
							? loginState.decodedToken?.user_id
							: NOT_AVAILABLE,
						appVersion: import.meta.env.VITE_APP_VERSION,
						resolvedLanguage:
							i18n.resolvedLanguage ?? NOT_AVAILABLE,
					}}
				>
					{children}
				</AnalyticsShell>
			</div>
		</div>
	)
}
