import { FormikSubmitCallback } from "@carbonbank/types"
import { Yup } from "@carbonbank/yup"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	Textarea,
	icon,
} from "@sustainability/fundamental"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"

interface AssureBalanceModalProps {
	flagScenario: boolean
	isOpen: boolean
	onClose: () => void
	onFormSubmit: FormikSubmitCallback<{ comment: string }>
}

export const FlagAllocationModal: React.FC<AssureBalanceModalProps> = ({
	flagScenario,
	isOpen,
	onClose,
	onFormSubmit,
}: AssureBalanceModalProps) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "productAllocationDetails.components.flagAllocationModal",
	})

	const { t: tCommon } = useTranslation("cb", { keyPrefix: "common" })

	const validationSchema = Yup.object().shape({
		comment: Yup.string().required(tCommon("validation.required")),
	})

	const submitIcon = flagScenario ? icon.mdiFlagOutline : icon.mdiCheck
	const submitLabel = flagScenario ? t("submitFlag") : t("flagResolved")

	return (
		<Modal data-cy="flag-allocation-modal" open={isOpen} onClose={onClose}>
			<ModalTitle data-cy="title">{t("title") as string}</ModalTitle>
			<Formik
				initialValues={{ comment: "" }}
				validationSchema={flagScenario && validationSchema}
				onSubmit={onFormSubmit}
			>
				{({ isSubmitting, isValid, dirty }) => (
					<Form data-cy="form">
						<ModalContent>
							<TextRegular
								data-cy="description"
								className="block mb-24px text-foreground_faded"
							>
								{t(
									flagScenario
										? "descriptionFlag"
										: "descriptionResolved",
								)}
							</TextRegular>
							<Textarea
								name="comment"
								data-cy="comment"
								label={t("labelExplanation")}
							/>
						</ModalContent>
						<ModalFooter>
							<Button
								data-cy="action-close"
								variant="secondary"
								onClick={onClose}
							>
								{tCommon("texts.cancel")}
							</Button>
							<Button
								data-cy="action-submit"
								icon={submitIcon}
								disabled={
									isSubmitting ||
									(flagScenario && (!isValid || !dirty))
								}
							>
								{submitLabel}
							</Button>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
